// CAROUSELS BASIC
.carousel-control:hover {
    @include transition-duration($duration: 400ms);
}

.carousel-fade .carousel-inner {
    .carousel-item {
        opacity: 0;
        transition-property: opacity;
    }
    .active {
        opacity: 1;
    }
    .active.left,
    .active.right {
        left: 0;
        opacity: 0;
        z-index: 1;
    }
    .next.left,
    .prev.right {
        opacity: 1;
    }
}

.carousel-fade .carousel-control {
    z-index: 2;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .carousel-item.next,
    .carousel-fade .carousel-inner > .carousel-item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .carousel-item.prev,
    .carousel-fade .carousel-inner > .carousel-item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .carousel-item.next.left,
    .carousel-fade .carousel-inner > .carousel-item.prev.right,
    .carousel-fade .carousel-inner > .carousel-item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.carousel {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 36px;
        height: 36px;
    }
    .carousel-control-prev-icon {
        background-image:  url(../../../assets/img/svg/arrow_left.svg);
    }
    .carousel-control-next-icon {
        background-image:  url(../../../assets/img/svg/arrow_right.svg);
    }
    .carousel-indicators {
        li {
            max-width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
    .video-fluid {
        height: 100%;
    }
    &.no-flex {
        .carousel-item.active,
        .carousel-item-next,
        .carousel-item-prev {
            display: block;
        }
    }
}
