.group-tile-container{
  display: grid;
  //grid-gap: 15px;
  margin-top: 2rem;
  max-height: 350px;

  @media only screen and (min-width: 0) {
    grid-template-columns: repeat(auto-fit, 100%);
  }
}

.two-tile-row{
  @media only screen and (min-width: $mobile-breakpoint-width) {
    grid-template-columns: repeat(auto-fit, 50%);
  }
}

.three-tile-row{
  @media only screen and (min-width: $mobile-breakpoint-width) {
    grid-template-columns: repeat(auto-fit, 50%);
  }
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, 33%);
  }
}

.small-tile-row{
  @media only screen and (min-width: $mobile-breakpoint-width) {
    grid-template-columns: repeat(auto-fit, 50%);
  }
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, 25%);
  }
  padding: 0.5rem;
  margin-top: 0!important;
}
