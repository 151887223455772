/*
Status cards

Add div with class 'status-card-line' and position line with:
'line-t': line top
'line-b': line bottom
'line-r': line right
'line-l': line left
Add dot to line with 'dot-start' and 'dot-end'
To show only dot add class 'no-line'

Add class 'action-btn' to main div with class 'add' (line div must exist and have class 'dot-start') or 'remove' to add either plus button in line or remove button to card.
Add class 'no-action' to line to not show action in that line (when you have multiple lines for example)
*/

$border-radius: 5px;
$border-color: #becad4;
$card-offset: 25px;

.status-card {
  width:100%;
  border:1px solid $border-color;
  border-radius:$border-radius;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  background-color:#FFF;
  position: relative;
  .statusbar {
    width:100%;
    height:5px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color:#6ca9cc;
  }
  .inner {
    width:100%;
    height:auto;
    padding:5px;
    word-break: break-all;
    .image {
      margin-right:5px;
    }
    .image.highlight {
      padding:5px;
      background:#FFF;
      border-radius:5px;
      box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    }
    .image.text{
      font-size:30px;
      text-align:center;
      color:#FFF;
      background-color:#999999;
      border-radius:50%;
      line-height:30px;
      font-weight: 300;
      margin:5px 20px 5px 5px;
      box-shadow: 0 0 5px 0 rgba(0,0,0,0.6);
      width:90px;
      height:90px;
    }
    .info {
      font-size:11px;
      color:#4b4b4b;
      padding-right:5px;
      h6 {
        font-size:12px;
        font-weight:bold;
        margin:0;
        color:#4b4b4b;
      }
    }
  }
  .status-card-line{
    position:absolute;
    width:calc($card-offset + 1px);
    height:1px;
    background:$border-color;
  }
  .status-card-line.no-line{
    background:none;
  }
  .status-card-line.line-r{
    right:0;
    top:50%;
    margin-right:- calc($card-offset + 1px);
  }
  .status-card-line.line-l{
    transform: rotate(180deg);
    left:0;
    margin-left:- calc($card-offset + 1px);
    top:50%;
  }
  .status-card-line.line-t{
    transform: rotate(270deg);
    margin-left:- calc(($card-offset + 1px) / 2);
    margin-top:- calc(($card-offset + 1px) / 2);
    left:50%;
    top:0;
  }
  .status-card-line.line-b{
    transform: rotate(90deg);
    margin-left:- calc(($card-offset + 1px) / 2);
    margin-bottom:- calc(($card-offset + 1px) / 2);
    left:50%;
    bottom:0;
  }
  .status-card-line.dot-start::before, .status-card-line.dot-end::after{
    width:15px;
    height:15px;
    border-radius:8px;
    border:1px solid $border-color;
    background:#FFF;
    position: absolute;
    content: "";
    margin-top:-8px;
    font-size:18px;
  }
  .status-card-line.dot-start::before{
    margin-left:-8px;
  }
  .status-card-line.dot-end::after{
    right:0;
    margin-right:-8px;
  }
}
.status-card.action-btn.add:hover .status-card-line.dot-start:not(.no-action)::before{
  content: "+";
  color:#4b4b4b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-card.action-btn.remove:hover .statusbar span::after{
  width:15px;
  height:15px;
  border-radius:8px;
  border:1px solid $border-color;
  background:#FFF;
  position: absolute;
  content: "+";
  transform: rotate(45deg);
  margin-top:-6px;
  margin-right:-6px;
  font-size:16px;
  right:0;
  color:#4b4b4b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Different status colors */
.status-card{
  .statusbar[class*="-blue"]{
    background-color:#0080ff;
  }
  .statusbar[class*="light-blue"]{
    background-color:#91c6ff;
  }
  .statusbar[class*="-orange"]{
    background-color:#f9ae64;
  }
  .statusbar[class*="-grey"], .status-card .statusbar[class*="-gray"]{
    background-color:#999999;
  }
  .statusbar[class*="-purple"]{
    background-color:#b973ff;
  }
  .statusbar[class*="-red"]{
    background-color:#f24651;
  }
  .statusbar[class*="-black"]{
    background-color:#424251;
  }
  .statusbar.state-low, .inner .image.text.state-low{
    background-color:#c8c8c8;
  }
  .statusbar.state-default, .inner .image.text.state-default{
    background-color:#6db8dc;
  }
  .statusbar.state-warning, .inner .image.text.state-warning{
    background-color:#fca343;
  }
  .statusbar.state-error, .inner .image.text.state-error{
    background-color:#a92d2c;
  }
}