.small-tile{
  cursor: pointer;
  padding: 0.5rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);;
  margin: 0.5rem;
  border-radius: 0.5rem;
  &:hover{
    box-shadow: 0px 2px 5px 2px rgb(0 0 0 / 30%);
  }

  &.selected {
    border: 2px solid $color_light-blue;
    margin: calc(0.5rem - 2px);
  }
}

.small-tile-top-part{
  width: 100%;
  display: flex;
  h5{
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 2px;
  }
}

.small-tile-bottom-part{
  display:flex;
  height: 40px;
  p{
    width: 100%;
    text-align: center;
  }
}

.small-tile-title{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 60%;
}

