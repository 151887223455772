.password-generate-container {
  background-color: white;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
  padding: 5px;
  width: 350px;
  position: absolute;
  border-radius: 5px;
  z-index: 10;
  max-width: 350px;

  .result-container {
    background-color: rgba(222, 222, 222, 1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 12px 10px;
    height: 50px;
    width: 100%;
  }

  .result-container #result {
    word-wrap: break-word;
    max-width: calc(100% - 40px);
  }

  .result-container .btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .settings {
    align-items: center;
    margin: 15px 0;
    input[type=checkbox]{
      visibility: visible;
      position: absolute;
      left: auto;
      right: 2rem;
    }
  }
}
