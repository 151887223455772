$icon-size-normal:24px;

.material-icons {
  font-family: 'Material_Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $icon-size-normal;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  align-self: center;
  padding: 0.5rem;

  &.mirror{
    transform: scale(-1, 1);
  }
}

/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

//Larger than intended by google
.material-icons.md-80 { font-size: 80px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.empty-icon{
  width:16px;
}

.import-icon{
  font-size: 28px!important;
  color: $color_blue-darker;
  padding-left: 0px;
  padding-bottom: 0px;
}
