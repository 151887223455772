.img-fluid-full-width {
  @extend .img-fluid;
  // force full width, so also for images smaller than the container
  width: 100%;
}

.tips{
  border: 1px solid black;
  padding: 1rem;
  background-color: #dae2f3;
}

.manual-image{
  margin: 2em 0 2em 0;
  &.bottom{
    margin: auto auto 0;
  }
  &.half-width{
    width: 50%;
  }
  &.full-width{
    width: 100%;
  }
  &.quarter-width{
    width: 25%;
  }
}

.map-option-label-maptype{
  ul{
    li{
      //margin-bottom: 0.5rem;
      .align-right{
        display: inline-block;
        position: absolute;
        right: 5%;
      }
    }
  }
}

.keyboard-icon{
  display: inline-block;
  text-align: center;
  //width: 50px;
  padding-left:0.25rem;
  padding-right:0.25rem;
  font-weight: 500;
  border: 2px solid $color_grey-input-border;
  border-radius: 3px;
  background-color: $color_super-light-grey;
  color: $color_darker-grey;
  line-height: 1;
}
