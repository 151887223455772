.dropdown-item {
  @extend .dropdown-item; //extend the existing .dropdown-item class (MDB/Bootstrap)
  &.active,
  &:active {
    background-color: $selection-gray;
  }
}

.nav-flex-icons {
  .dropdown-menu {
    left: auto;
    right: 0;
    @include media-breakpoint-down(lg) {
      left: auto;
      right: -50px;
    }
  }
}

.menu-dropdown-header{
  @extend .dropdown-header;
  font-weight: 500;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
 }

.menu-dropdown-icon {
  @extend .material-icons, .md-dark;
  padding: 0;
  padding-right: 0.3rem;
  font-size: large;
  color: rgba(0, 0, 0, 0.54) !important;
}

 //Stupid hax0r fix for dropdown css override
.navbar-nav .dropdown-menu .selected-searchitem{
  background-color: $selection-gray;
}

.luminizer-main-menu-drop-down-item {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  font-weight: 200;
  background: inherit;
  @extend .dropdown-item; // this the luminizer .dropdown-item

  &:hover,
  &:active,
  &.active {
    background-color: $selection-gray !important;
    color: $elegant-color-dark !important;
    box-shadow: none !important;
  }
}

.luminizer-main-menu-drop-down-item-no-results {
  padding-left: 1rem !important;
  padding-right: 2rem !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  font-weight: 200;
  background: inherit;
  tab-index:-1;
  @extend .dropdown-item; // this the luminizer .dropdown-item

  &:hover,
  &:active {
    background-color: inherit !important;
    color: $elegant-color-dark !important;
    box-shadow: none !important;
    cursor:default !important;
  }

  cursor:default;
}

.luminizer-main-menu-drop-down-item-container {
  width: 100%;
}

.navbar-nav .dropdown-menu {
  .areaal-dropdown-item {
   @extend .luminizer-main-menu-drop-down-item;
  }
}
//Is dit specief genoeg voor je!!? :(:(
.navbar.navbar-dark {
  .navbar-nav {
    .nav-item {
      .dropdown-menu {
        .luminizer-main-menu-drop-down-item {
           &:hover,
           &:active {
             color: $elegant-color-dark !important;
           }
        }
      }
    }
  }
}

.navbar.navbar-dark {
  .navbar-nav {
    .nav-item {
      .dropdown-menu {
        .luminizer-main-menu-drop-down-item-no-results {
          &:hover,
          &:active {
            color: $elegant-color-dark !important;
            cursor: default !important;
          }
        }
      }
    }
  }
}

.multi-select-drop-down-container{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: $color_grey-input-border;
  min-height: calc(2.25rem + 2px);

  &.read-only{
    border-style: none;
  }

  &.disabled{
    color: gray;
  }
}

.multi-select-drop-down-container-single-line{
  @extend .multi-select-drop-down-container;
  max-height: 2.5rem;
  .dropdown-items-container {
    overflow-x: auto;
  }
}

.multi-select-option-block{
    font-size: 12px;
    border-radius: 3px;
    border-style: solid;
    border-width: 2px;
    white-space: nowrap;
    background-color: $color_grey-body-background;
    border-color: $color_dimming-scheme-border;
    padding-top:0.1rem;
    padding-bottom: 0.1rem;
    padding-right: 0.1rem;
}

.multi-select-option-list{
    overflow-y: auto; //moet aan voor lange lijsten in de dropdown. Stond dit specifiek uit voor een situatie?
    max-height: 250px;
    position: absolute;
    border-style: solid;
    border-color: $color_grey-input-border;
    border-width: 1px;
    z-index: 10;
    background-color: white;

    //Fix for focus on filterinput turning the first dropdownlabel to blue
    label{
      color:$color_text_darkgray !important;
    }

  input {
    flex-shrink: 0;
  }
  label{
    flex-shrink: 0;
  }
}
.multi-select-option-list-large{
  @extend .multi-select-option-list;
    max-height: 400px;
}

.form-select-arrow-container{
  font-size: 18px;
  background-color: $color_lighter-grey;
  width:14px;
  justify-content: center;
}

.color-picker-option-list{
  //overflow-y: scroll;
  max-height: 250px;
  position: absolute;
  border-style: solid;
  border-color: $color_grey-input-border;
  border-width: 1px;
  z-index: 10;
  background-color: $color_grey-body-background;
}

.color-picker-color {
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  &.read-only{
    margin-left:0!important;
    margin-right:0!important;
    height: 21px;
    border-style: none;
  }
}

.color-picker-color-container{
  height: 100%;
  background-color: $color_grey-body-background;
  width: 35px;
  height: 30px;

  &.read-only{
    background-color: white;
  }
}

lumi-select .multi-select-option-list {
  width: auto;
  min-width: 100%;
  //Remove this line if you want to make it stretch to fit the width of the content
  max-width:200%; // prevent the dropdown from becoming too wide
  label{
    &.highlight-selected{
      background: $color_light-blue;
    }
    &:hover{
      background-color: $color_super-light-grey;
    }
    &.highlighted{
      background: $selection-gray;
    }
    &.option-disabled {
      opacity: 80%;
    }
  }
}

.show-dropdown{
  display: block;
}

.sub-dropdown{
  left: 14.75rem;
  top: 0;
}
