.complex-list-item {
  width: 100%;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  background-color: $color_super-light-grey;
  border-radius: 2px;
  position: relative;

  .cli-header {
    height: fit-content;
    min-height: 2.5rem;
    display: flex;
    border-top-left-radius: 0.9rem;
    width: 100%;

    .cli-icon {
      display: flex;
      align-items: center;

      i {
        @extend .material-icons, .md-dark;
        background-color: white;
        border-radius: 50%;
        padding: 3px;
        margin: 3px;
        font-size: x-large !important;
        color: rgba(0, 0, 0, 0.54) !important;
      }
    }

    .cli-title {
      display: flex;
      width: 100%;
      padding-left: 0.3rem;
      align-items: center;

      span {
        font-weight: bold;
        word-wrap: anywhere;
      }
    }

    .cli-subtitle {
      display: flex;
      width: 13rem;
      min-width: 5rem;
      margin-left: auto;
      margin-right: 0.2rem;
      font-size: 0.8rem;
      align-items: center;

      span {
        text-align: right;
        padding-right: 0.2rem;
        word-break: break-word;
      }
    }

    .cli-buttons {
      display: flex;
      margin-left: auto;
      align-items: center;

      .cli-button {
        .lumi-button-input {
          &:hover,
          &:active,
          &:focus {
            background-color: $color_super-light-grey !important;
          }
        }
      }

      .lumi-button-input {
        &:hover,
        &:active,
        &:focus {
          background-color: $color_super-light-grey !important;
        }
      }
    }
  }

  .cli-content {
    font-size: small;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
}
