$loading-filler-height:35%;

// perhaps these styles can be move to _progress..?

.loading-page {
  background: url('../../assets/img/login.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  height:100%;
}

.loading-form-container {
  margin: 0 auto;
}

.loading-form {
  max-width: 25rem;
  padding: 1rem;
  margin: 0 auto;
  pointer-events: auto;
  background-color: white;
  border-radius: $border_radius_large;
  @extend .light-font;
  @extend .z-depth-5;
}

.loading-form-small {
  max-width: 10rem;
  display: flex;
  justify-content: center;
  @extend .loading-form;
}

.loading-filler {
  height:$loading-filler-height;
}

.loader {
  background-image:url('../../assets/img/loading2022.gif');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width:100%;
  max-width:300px;
  height:0;
  padding-top: 5.8%; /* (img-height / img-width * container-width (100%)) */
}

.loader-small {
  float: right;
  @extend .loader
}

.loader-smaller{
  width:50%;
  @extend .loader
}

.tree-node-component-wrapper .loader{
  width:80%;
}

.loading-block-absolute{
  position: absolute;
  z-index:11;
  left:0;
  top:0;
  width: 100%;
  height:100%;
  background: rgba(255,255,255,.9);
  border-radius:5px;
  img{
    max-width:100px;
  }
}
