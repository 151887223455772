.cdk-overlay-container {
  z-index: 9999;
}
.mdc-tooltip{
  background:rgba(0,0,0,.8);
  font-size:12px;
  padding:4px 8px !important;
  color:#FFF;
  border-radius:3px;
}
.ck-editor__editable {
  min-height: 500px;
}
.tox-tinymce-aux{
  z-index:10000 !important;
}

.gm-style-iw:not([aria-label]) { //Only target popovers that do not have an aria-label since the poi popovers do have one an our custom one, does not.
  flex-direction: row;
  .gm-style-iw-chr{ //Close icon
    position: absolute;
    top: 9px;
    right: 12px;
    z-index: 10;
    button{
      width:auto !important;
      height:auto !important;
      span{
        margin:0 !important;
      }
    }
  }
  .gm-style-iw-d{ //Content
    .map-infowindow-container{
      padding-right:40px;
    }
  }
}
