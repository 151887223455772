$font-family-sans-serif: 'Raleway', sans-serif;

// Define the Luminext basic colors
$luminext-orange:         #f29C40 !default;
$luminext-purple:           #2e296a !default;

// Reassign color vars to semantic color scheme
$brand-primary:             $luminext-purple !default;
$brand-secondary:           $luminext-orange !default;
$brand-success:             $green !default;
$brand-info:                $teal !default;
$brand-warning:             $orange !default;
$brand-danger:              $red !default;
$brand-inverse:             $gray-dark !default;

// button, selections color for hover/active
$selection-gray: #ececec !default;
$selection-blue:  #1256cf;

$color_white:  #FFFFFF;
$color_black:  #000000;

$color_widget-grey: #939393;
$color_super-light-grey:  #f9f9f9;
$color_lighter-grey:  #f2f2f2;
$color_grey-body-background:  #f5f5f5;
$color_dimming-scheme-border:  #e9e9e9;
$color_manual-border:  #e5e5e5;
$color_grey-divider:  #f5f5f3;
$color_light-grey:  #dddddd;
$color_grey:  #CCCCCC;
$color_grey-input-border: #d9d9d9;
$color_darker-grey: #999fa3;
$color_grey-border-light:  #e0e0e0;
$color_grey-module-section:  #9f9f9f;
$color_grey-tile:  #585858;
$color_very-dark-grey:  #292b2c;
$color_dark-grey:  #878787;
$color_grey-default-label:  #808080;
$color_grey-icon-dark: #555555;

$color_text_white:  $color_white;
$color_text_brokenwhite: $color_super-light-grey;
$color_text_darkgray:  #4e4e4e;
$color_text_darkest-gray:  #323232;
$color_text_lightgray:  #4b5257;

$color_blue-darker: #365778;
$color_blue-dark: #0057a2;
$color_blue:  #508ec9;
$color_lightest-blue:  #daedff;
$color_light-blue:  #8bbeef;
$color_lighter-blue:  #819eba;
$blue-loading: #3498db;
$blue-areaal-button: #4377aa;

$color_secondary-blue: #0275d8;
$color_secondary-blue-hover: #4285f4;

$color_status-done: #5ed183;

$color_error: #ee5f5b;

$color_streetsmart-green: #98c23c;
$color_streetsmart-dark-green: #98c23c;
$color_streetsmart-darkest-grey: #333333;
$color_map-overlay-text-grey: #6b6b6b;

$color_time-tracking-yellow: #ffc463;
$color_time-tracking-red: #ff7b63;

$border_radius_smallest: 2px; //Default
$border_radius_small: 3px;
$border_radius_large: 6px;

//AANGEPAST IN BOOTSTRAP CSS:
/*$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px,
        xxxl: 1900px
) !default;*/

.yellow-shadow {
  box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2);
}

.purple-shadow {
  box-shadow: 0 12px 20px -10px rgba(149, 0, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(149, 0, 255, 0.2);
}

.green-shadow {
  box-shadow: 0 12px 20px -10px rgba(0, 178, 59 , 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 178, 59 , 0.2);
}

.red-shadow {
  box-shadow: 0 12px 20px -10px rgba(255, 0, 43, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 0, 43, 0.2);
}

.black-shadow {
  box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2);
}

.yellow-background {
  //background: linear-gradient(60deg, #ffa726, #ffa726, #fb8c00);
  //background: linear-gradient(60deg, #ffc463, #ffc463, #ffc463);
  background: $color_time-tracking-yellow;
}

.purple-background {
  //background: linear-gradient(60deg, #cb79cb, #cb79cb, #cb79cb);
  background: #cb79cb;
}

.green-background {
  //background: linear-gradient(60deg, #66bb6a, #66bb6a, #43a047);
  //background: linear-gradient(60deg, #5ed183, #5ed183, #5ed183);
  background: #5ed183;
}

.red-background {
  //background: linear-gradient(60deg, #ef5350, #ef5350, #e53935);
  //background: linear-gradient(60deg, #ff7b63, #ff7b63, #ff7b63);
  background: $color_time-tracking-red;
}

.blue-background {
  //background: linear-gradient(60deg, #ef5350, #ef5350, #e53935);
  //background: linear-gradient(60deg, #508ec9, #508ec9, #508ec9);
  background: $color_blue;
}

.black-background {
  //background: linear-gradient(60deg, #ef5350, #ef5350, #e53935);
  //background: linear-gradient(60deg, #508ec9, #508ec9, #508ec9);
  background: black;
}

.grey-background {
  //background: linear-gradient(60deg, #ef5350, #ef5350, #e53935);
  //background: linear-gradient(60deg, #508ec9, #508ec9, #508ec9);
  background: $color_grey-module-section;
}

.lightgrey-background {
  background-color: $color_super-light-grey !important;
}

.blue-areaal-button-background {
  background-color: $blue-areaal-button !important;
}

.grey-input-background {
  background-color: $color_grey-input-border !important;
}

.text-color-darkgrey {
  color: $color_text_darkgray !important;
}

.text-color-widget-grey {
  color: $color_widget-grey !important;
}

.text-color-secondary-blue {
  color: $color_secondary-blue !important;
}

.text-color-white {
  color: $color_white !important;
}

.border-color-grey-input {
  border-color: $color_grey-input-border !important;
}



$prefix: "--"; // Prefix string for custom CSS properties

// Merges a variable name with $prefix
@function custom-property-name($name) {
  @return $prefix + $name;
}

// Defines a custom property
@mixin define-custom-property($name, $value) {
  #{custom-property-name($name)}: $value;
}

body {
  // Append SASS variables which are desired to be accesible:
  @include define-custom-property('color_light_grey', $color_light_grey);
  @include define-custom-property('color_light-blue', $color_light-blue);
  @include define-custom-property('selection-blue', $selection-blue);
  @include define-custom-property('selection-gray', $selection-gray);
  @include define-custom-property('color_text_darkgray', $color_text_darkgray);
  @include define-custom-property('color_dark-grey', $color_dark-grey);
  @include define-custom-property('color_darker-grey', $color_darker-grey);
  @include define-custom-property('color_grey-default-label', $color_grey-default-label);
}
