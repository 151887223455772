.help-icon-tooltip{
  background-color: $color_widget-grey;
  color: $color_white;

  text-align: left;
  font-size: 0.8rem;

  margin-top:-0.5rem;

  &:before {
    border-bottom-color: $color_widget-grey !important;
    border-top-color: $color_widget-grey !important;
    margin-top:-0.5rem;
  }

  p{
    font-size: 0.8rem;
  }
}

.help-icon-tooltip.tooltip-inner {
  max-width: 350px;
  width: 350px;
}

.bs-tether-out-of-bounds-bottom{
  display: none;
}

.bs-tether-out-of-bounds-top{
  display: none;
}

.simple-tooltip {
  position: relative;
  display: inline-block;
}

.simple-tooltip .simple-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: $color_widget-grey;
  color: $color_white;
  text-align: center;
  border-radius: 2px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 90%;
  left: 50%;
  margin-left: -100px;

  font-size: small;

}

.simple-tooltip .simple-tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $color_widget-grey transparent;
}

.simple-tooltip:hover .simple-tooltiptext {
  visibility: visible;
}
