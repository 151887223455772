.table-row-selected{
  cursor: pointer;
  background: $color_light-blue;

  &:hover,
  &.active {
    background-color: $color_lightest-blue;
  }
}

.table-header{
  cursor: pointer; //Pointer for sorting
  @include unselectable-content;
}

.table-row {
  cursor: default;
  &:hover,
  &.active {
    background-color: $selection-gray
  }
}

.table-row-selectable {
  @extend .table-row;
  cursor: pointer;
}

form table-component .table-no-data-container{
  background-color: $color_super-light-grey;
}

.table_icon {
    vertical-align: middle;
    padding: 0;
    //padding-bottom: 3px;
  display:inherit;
  line-height: 0.7;
}

.table-component-info-container {
  position: absolute;
  padding-left:2rem;
  padding-right: 2rem;
  left: 0;
  right: 0;
  top:35%;
  pointer-events:none;
}

.table-no-data-container{

  //line-height: 300px;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;

  display: table;
  width: 100%;

  margin-top:auto;
  /*  height: $chart-widget-height;
    display: flex;
    justify-content: center; !* align horizontal *!
    align-items: center; !* align vertical *!*/
}

.table-component-container{
  max-height: 300px;
  overflow-y: auto;
  color: $color_very-dark-grey;

    height: 300px;
    background-color: $color_super-light-grey;
}

.table-limit-warning{
    font-size: 14px;
    background: $color_lightest-blue;
    cursor: pointer;
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
}

.auth-table-header{
  font-size:12px;
  font-weight:normal;
  white-space: nowrap;

  &.tabbed-row{
    padding-left:1rem;
    padding-top:0 !important;
    padding-bottom:0 !important;
    vertical-align: middle;
  }

  &.main-row{
    font-weight: bold;
    text-transform: uppercase;
    background-color: $color_lighter-grey; //TODO: standariseer
  }
}

.table-row-delete{
  &:hover{
    background-color: $color_grey-divider;
  }
}

.table{
  &.auth-table {
    td, th{
      padding: 0.25rem;
    }
    &.checkboxes{
      td, th{
        padding-left: 1rem;
      }
    }
  }

/*  .empty-cell{
    background-color: white;
    border:none;
  }*/
}

.table:not(.auth-table) {
  thead tr:nth-child(1) th{
    background: $color_widget-grey;
    position: sticky;
    top: -1px;
    z-index: 10;
  }
  &.clear-header{
    thead tr:nth-child(1) th{
      background: $color_white!important;
      color: black!important;
    }
  }
  th {
    @media screen and (min-width: $mobile-breakpoint-width) {
      max-width: 0;
      min-width: 5rem;
    }
  }
}

.auth-table thead{

  background-color: white;

  .auth-table-header {
    max-width: 10px;
    height: 175px;
    max-height: 175px;
    white-space: nowrap;
    transform: translate(20px, 0px) rotate(300deg);
    transform-origin: bottom left;
  }
}

.auth-table-component-container{

  max-height: 100%;
  height:auto;
  margin-right:auto;
  margin-left:auto;
  //overflow: scroll;

  .auth-show-action{
    background: $color_lighter-grey;
    .checkmark-div{
      background-color: white;
      &.div-checked{
        background-color: $color_secondary-blue;
      }
    }
  }

  tr td:last-child{
    padding-right:1rem;
  }

  .expand-button{
    color: $color_grey-icon-dark;
    vertical-align: middle;
    font-size: 16px;
    cursor:pointer;
  }

  .inactive{
    opacity: 20%;
  }

}

form-auth-table {
  //overflow-x: scroll;
}

.table{
  &.auth-table{

  //width: auto;
  //max-width: none;
  }
}

.auth-table-header-basic{
  font-size:12px;
  background-color: $color_secondary-blue;
  color: white;
}

//Werkt alleen voor IE 10 en 11 als het goed is
.auth-field-table-checkbox-IE-fix{
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: flex;
  }
}

.filter-close-button{
    display: flex;
  background-color: $color_grey-body-background;
  //height: 38px;
    //border-radius: 5px 0px 0px 5px;
    i{
      color: $color_grey-icon-dark;
    }

    height: 100%;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    margin-left: -0.5rem;
    //margin-top: -1.25rem;
    //margin-bottom: -1rem;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: 1px solid $color_dimming-scheme-border;
}
