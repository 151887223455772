.tab-bar{
  padding: 0;
  margin: 0;
  color: $color_black;
  //background: aliceblue;
  box-shadow: none;
  background-color: $color_white;
  transition: none;
}

//Solves this problem: https://css-tricks.com/flexbox-truncated-text/
.tab-bar li a{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-bar li {
  min-width: 0;
  flex-grow: 1;
  text-align: center;
  overflow: hidden;
}

.tab-bar .active {
  flex-shrink: 0;
}

.tab-bar .nav-item .tab-bar-tab {
  &.active {
    color: $color_text_darkgray;
    background-color: $color_white;
    transition: none;
    pointer-events: none; //Block hover ect. for active tabs
  }
}

.tab-bar-collapsed{
    display: block;
    transform: rotate(90deg);
    width: 400px; //TODO: Geen duidelijke reden voor 400
    transform-origin: 20px center 0; //20px is het midden van de height van 40px
/*    border-color: $color_widget_grey;
    border-style: solid;
    border-width: 0 1px 0 0;*/

  .nav-tabs .nav-item .tab-bar-empty-tab{
    background-color: white;
  }
}

//Specific selector to override the MDB styling
.tab-bar .nav-item .tab-bar-empty-tab {
  height: 100%;
  background-color: $color_white; //$color_widget_grey;
  pointer-events: none; //Block hover ect.
  border-radius: 0;
}

.tab-bar .nav-item .tab-bar-tab {
  background-color: $color_widget-grey;
  color: $color_text_white;
  border-radius: 0px;
  transition: none;
  text-overflow: ellipsis;

  border-color: $selection-gray;
  border-style: solid;
  border-width: 0 0 0 1px;

  font-size:15px;

  &:hover{
    background-color: $color_dark-grey; // $color_text_lightgray;
    color:$color_text_white;
  }
}


.new-tabs{
  margin-top:13px;
  border-bottom:1px solid #dee2e6;
  padding-bottom:1px;
  overflow:auto;

  .tab{
    color:#292b2c;
    border-bottom:2px solid transparent;
    margin-bottom:-1px;
    padding: 5px 10px;
    max-width:100%;
    white-space: nowrap;


    &:hover:not(.active){
      cursor:pointer;
    }
    &:hover, &.active{
      border-color:#365778;
    }
  }
}
