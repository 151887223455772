.dimming-exception{
  background-color: $color_super-light-grey;
  position:relative;
  border-style: solid;
  border-width: 1px;
  border-color: $selection-gray;

  i{
    color: $color_grey-icon-dark;
  }
}

.dimming-exception-title{
  border-style: none;
  border-width: 1px;
  border-color: $selection-gray;
  border-bottom-style: solid;
  font-size: 1rem;
}

.dimming-scheme-selector{
  background-color: $color_super-light-grey;

  i{
    color: $color_grey-icon-dark;
  }
}

.dimming-scheme-moments-list
{
  border-width: 1px;
  border-color: $color_grey-input-border;

  @include media-breakpoint-up(lg) {
    height: 300px;
    min-height: 300px;
  }

  @include media-breakpoint-down(md) {
    height: auto;
    max-height: 300px;
  }
}

.dimming-scheme-moments-list-container{
  background-color: white;
  overflow: auto;
}

.dimming-scheme-moments-list-block{
  font-size: 12px;
  border-width: 1px;
  white-space: nowrap;
  background-color: $color_grey-body-background;
  border-color: $color_dimming-scheme-border;
  padding-top:0.1rem;
  padding-bottom: 0.1rem;
  position: relative;
  border-radius:5px;

  &.selected-moment{
    border-color: $color_text_darkgray;
  }
}

.dimming-scheme-selector-day-block{
  width:100%;
  height:30px;
  margin-right:0.25rem;

  //border-style: solid;
  //border-width: 2px;
  background-color: $selection-gray;
  overflow: hidden;

  display:flex;
  justify-content: center;
  align-items: center;
  border-radius:2px;
  font-size: 14px;
  //font-weight: bold;

  &:last-child{
    margin-right:0;
  }

  &.selected-day{
    background: $color_light-blue;
  }
}

/*global-popup-edit-scheme-component .form-graph-no-data{
  //background: white;
  //color:white;
}*/

.moment-time-block{
  font-size: 1.5rem;
}

.moment-time-part{
  font-size: 1.5rem;
  outline: none;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: $color_dimming-scheme-border;

  &:focus{
    border-color: $color_light-blue;
  }
}

.mobile-moment-time-block{
  display: block;
  font-size: 1.5rem;
}

.mobile-moment-time-part{
  display: block;
  font-size: 1.5rem;
  outline: none;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: $color_dimming-scheme-border;

  &:focus{
    border-color: $color_light-blue;
  }
}

.percentage{
  margin-left: 50px;
}

.dimgroup-override-selection-block{
  background: $color_super-light-grey;
  border-radius:5px;
}

.dimgroup-override-tab{
  background:$selection-gray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight:400;
  cursor: pointer;

  &.active{
    background: $color_super-light-grey;
    font-weight:400;
  }
}

.dimming-channel-block{
  cursor: pointer;
  background-color: $selection-gray;
  //border: 1px solid $selection-gray;
  border-radius: 2px;

  &.selected-block{
    background-color: $color_light-blue; //$selection-gray;
    //color: white;
  }

}
