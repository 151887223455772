.sp-subStatus-container {
  display: grid;
  width: fit-content;
  width: -moz-fit-content;
  margin-bottom: 1rem;

  .sp-subStatus {
    display: inline-flex;
    margin: 0;
    margin-top: 0.1rem;
    width: 100%;
    min-height: 35px;

    .sp-subStatus-icon-holder {
      position: relative;
      border: 2px solid;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 4px;
      width: 35px;
      min-width: 35px;
      background: $color_super-light-grey;

      .sp-subStatus-icon {
        position: absolute;
        padding-top: 4px;
        width: 80%;
        left: 50%;

        transform: translateX(-50%);

        i {
          @extend .material-icons;
          padding: 0;
          font-size: x-large;
          cursor: default;
        }
      }
    }
    .sp-subStatus-description {
      width: 100%;
      border: 2px solid $color_light-grey;
      border-left: unset;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: small;
      background: $color_super-light-grey;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}
