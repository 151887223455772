/*!
 * Material Design for Bootstrap 4
 * Version: MDB Pro 4.3.2
 *
 *
 * Copyright: Material Design for Bootstrap
 * http://mdbootstrap.com/
 *
 * Read the license: http://mdbootstrap.com/license/
 *
 *
 * Documentation: http://mdbootstrap.com/
 *
 * Getting started: http://mdbootstrap.com/getting-started/
 *
 * Tutorials: http://mdbootstrap.com/bootstrap-tutorial/
 *
 * Templates: http://mdbootstrap.com/templates/
 *
 * Support: http://mdbootstrap.com/forums/forum/support/
 *
 * Contact: office@mdbootstrap.com 
 *
 * Atribution: Animate CSS, Twitter Bootstrap, Materialize CSS, Normalize CSS, Waves JS, WOW JS, Toastr, Chart.js , Hammer.js
 *
 */

@charset "UTF-8";

// MDB Framework //

// Mixins
@import "free/data/prefixer";
@import "free/data/mixins";

// Variables
@import "free/data/colors";
@import "free/data/variables-b4";
@import "free/data/variables";

//Global
@import "free/global";

// MDB Free
//@import "free/roboto"; // Domo aregato; we already got this one covered by the tool-chain
@import "free/typography";
@import "free/animations";
@import "free/waves";
@import "free/helpers";
@import "free/buttons";
@import "free/forms-basic";
@import "free/cards-basic";
@import "free/navbars";
@import "free/hover-effects";
@import "free/footer";
@import "free/carousels-basic";
@import "free/modals";

// MDB Premium
@import "pro/forms-pro";
@import "pro/tabs";
@import "pro/msc";
@import "pro/progress";
@import "pro/accordion";
@import "pro/buttons-pro";
@import "pro/social-buttons";
@import "pro/cards-pro";
@import "pro/dropdowns-pro";
@import "pro/light-box";
@import "pro/side-nav";
@import "pro/navbars-pro";
@import "pro/carousels-pro";
@import "pro/modals-pro";
@import "pro/toasts";
@import "pro/scrollspy";
@import "pro/charts-pro";
@import "pro/animations-pro";
@import "pro/scrollbar";
@import "pro/ecommerce";
@import "pro/skins";
@import "pro/chips";
@import "pro/parallax";
@import "pro/stepper";

// DATE & TIME PICKERS 
@import "pro/date_picker/default";
@import "pro/date_picker/default.date";
@import "pro/date_picker/default.time";

// SECTIONS
@import "pro/sections/blog";
@import "pro/sections/magazine";
@import "pro/sections/pricing";
@import "pro/sections/testimonials";
@import "pro/sections/features";
@import "pro/sections/team";
@import "pro/sections/templates";
@import "pro/sections/social";