$chart-widget-height: 300px;

.widget-panel {
  //margin-top: 2rem;
  //margin-bottom: 1rem;
  //padding-bottom:1rem;
  text-align: left;
  height: 100%;
}

.intro-widget-row{
  padding-bottom: 1rem;
}

.static-widget-info-block{
  p{
  //font-size:1rem;

  }
}

//Override bootstrap color
    .nav-item {
      .dropdown-menu {
        .luminizer-main-menu-drop-down-item {
          &:hover,
          &:active {
            color: $elegant-color-dark !important;
          }
        }
      }
    }

widget-component .dropdown-toggle:after {
    font-size: 16px;

}

.static-widget-title{
    border-top: solid $color_grey;
    border-top-width: 1px;
    margin-left: 1rem;
    margin-right: 1rem;
}

.static-widget-panel{
  background: white;
}

.static-widget-map{
  min-height:250px;
  text-align:left;
}

.selected-widget-option{
  background-color:$selection-gray !important;
}

.widget-title-container{
  margin-right:1rem;
  margin-left:1rem;
  margin-top:-1rem;
  border-radius: $border_radius_small;
}

.table-widget-container{
  margin-right: 2rem;
  //padding-right: 2rem;
  margin-left: 2rem;
  overflow-y: auto;
  max-height: $chart-widget-height; //Fix voor tabel die te groot wordt. geen perfecte oplossing. Als er iets beters is (javascript oplossing?) kan dit er weer uit

  min-height: $chart-widget-height; //Als ze geen data hebben, en er staan verder geen grotere widgets op het scherm, moeten ze wel een hoogte hebben
  height: 100%;
  padding-bottom: 1rem;

  margin-top:auto;

  thead th{
    border-top: 0px;
  }

}

.feed-widget-container-container{
  margin-right: 0rem;
  //padding-right: 2rem;
  margin-left: 0rem;
  overflow-y: auto;
  max-height: $chart-widget-height; //Fix voor tabel die te groot wordt. geen perfecte oplossing. Als er iets beters is (javascript oplossing?) kan dit er weer uit

  min-height: $chart-widget-height; //Als ze geen data hebben, en er staan verder geen grotere widgets op het scherm, moeten ze wel een hoogte hebben
  height: 100%;
  padding-bottom: 1rem;

  margin-top:auto;

  th{
    border-top: 0px;
  }

}

.table-widget-table{
  /*margin-left: 2rem;
  margin-right: 2rem;
  width: auto;*/

  width:100%;
  font-size: 14px;

  position: relative;
  z-index: 0;

  tbody{
    //Voor import aangezet. Kan eventueel uit voor de rest.
    white-space:pre; //Allow whitespace, but not for header
  }
}
.chart-widget-no-data-container{
    //line-height: 300px;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;

  display: table;
  width: 100%;

  margin-top:auto;
/*  height: $chart-widget-height;
  display: flex;
  justify-content: center; !* align horizontal *!
  align-items: center; !* align vertical *!*/
}

.chart-widget-no-data{
    display: table-cell;
    vertical-align: middle;
    height: 250px;
  padding-left: 1rem;
  padding-right: 1rem;

  margin-bottom:auto;
  margin-top:auto;
}

.chart-widget-filler {
  height: calc(50% - (#{$chart-widget-height} / 2)); //Werkt niet op IE 10
}

.small-widget-title-container{
  margin-right:auto;
  margin-left:1rem;
  margin-top:-0.5rem;
  border-radius: $border_radius_small;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  line-height: 1;
  width: 3rem; //IE and safari fix
}

.widget-title{
  color: $color_grey-tile; // $color_text_brokenwhite; //$color_text_white; //$color_text_lightgray;
  margin-top: 1rem;
  margin-bottom:0rem;
  margin-left:1rem;
  margin-right:1rem;
  font-weight: 300;
  text-align: left;
  font-size: 18px;

  border-bottom: solid $color_grey;
  border-bottom-width: 1px;
  padding-bottom: 0.5rem;
  &.form-settings{
    color: $color_grey-tile;
    margin: 0;
    text-align: right;
    border: none;
    padding: 0;
  }
}

.widget-chart{
  //height:100%; IE11 fix
  padding-left:1rem;
  padding-right:1rem;
  padding-bottom:1rem;

  margin-top:auto;
  //margin-bottom:auto;
}

.small-widget-text{
  //color: $color_text_white; //$color_text_lightgray;
  //margin-top: 0.5rem;
  //margin-bottom:0.5rem;
  margin-left:1rem;
  margin-right:1rem;
  font-weight: 400;
  text-align: right;
  font-size: 2.5rem;
  //height:100%
  //font-size: calc(1.75vw - 10px);
  white-space: pre-wrap;
}

.small-widget-resizeable-text{
  @extend .small-widget-text;
  //font-size: calc(1.80vw - 10px);
  font-size: 1.5rem;
}

/*.widget-panel-stretch{
  @extend .widget-panel;
!*  height: -moz-calc(100% - 2rem);
  height: -webkit-calc(100% - 2rem);
  height: calc(100% - 2rem);*!
}*/

.widget-component-container{
  margin-bottom: 2rem;
}

.widget-row{
  //margin-bottom: 2rem;
  //margin-top:2rem;
}

/*.small-widget-text-sm{
  @extend .small-widget-text;
  font-size: 4vw;
}*/

.widget-icon{
  padding: 0;
}

.widget-subtext-icon{
  float:left;
  padding-left: 0;
  padding-bottom: 0;
}

.widget-subtext-container{
  border-top: solid $color_grey;
  border-top-width: 1px;
  font-size: 14px;
  font-weight: 200;
  text-align: left;
  margin-right: 1rem;
  margin-left: 1rem;

  margin-top:auto;
}

.map-widget-subtext-container{
  @extend .widget-subtext-container;
  margin-top:0;
}

.widget-subtext{
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

.small-widget-title{
  text-align: right;
  margin-right:1rem;
  margin-left:5rem;
  margin-top: -2.0rem;
  font-size: 18px;
  color: $color_grey-tile;
  font-weight: 300;
}

.map-widget-title{
  text-align: right;
  margin-right:1rem;
  margin-left:5rem;
  margin-top: -1.75rem;
  //padding-bottom:1rem;
  font-size: 18px;
  color: $color_grey-tile;
  font-weight: 300;
}

.widget-map-container{
  margin: 1rem;
  //margin-top: 2rem;
  height: $chart-widget-height;
  margin-bottom: 0;
}

.table-widget-no-data{
  text-align: center;
  font-weight: 400;
  font-size: 1.5rem;
}

.table-widget-no-data-filler{
  height: 49%; //Dit kan mooier, het is 50%- de teksthoogte
}

.feed-widget-container{
  //padding-top:1rem;
  padding-left:1rem;
  padding-right:1rem;
  padding-bottom:0rem;
}

.feed-widget-activity{
  //margin-bottom:1rem;
  font-size: 14px;
  padding:0.5rem;
  box-shadow: 0 0 0 0;
}

.feed-widget-activity-link{
  color: $color_blue;
  cursor: pointer;
}

.feed-widget-message{
  background: #f4f9ff;
  padding: 0.5rem;
}

.feed-widget-message-btn-expand{
  text-align: right;
  display:block;
  padding: 0;
  cursor: pointer;
}

static-widget-component .intro-widget-row  {
  .material-icons{
    cursor: pointer;
    padding: 0;
    @include unselectable-content;
  }

  p b{
    cursor: pointer;
    @include unselectable-content;
  }
}

.dashboardV2{
  .widget-panel{
    max-width:93vw;
    border:1px solid #e3e3e3;
    box-shadow: none;
  }

  .is-v2-map-widget{
    border:0;
    margin:0;
    padding:0 !important;
    .static-widget-map{
      padding:0 !important;
      min-height:500px;
    }
  }
}

