.global-alert-container{
  position: absolute;
  right:0;
  padding: 0rem;
  margin: 1rem;
  padding-left: 2rem;
  z-index:9999;
}

.global-alert{
  @extend .light-font;
  background: white;
  color: $color_text_darkgray;
  padding: 0.75rem;
  padding-left: 1.5rem;
}

.global-alert form-component{
  font-weight:400;
}

.global-alert-button-min-width{
  min-width:50px
}

.global-popup{
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);

  //overflow-y:scroll; //Voor popups die groter zijn dan het scherm, bv bij mobile

  .global-alert-close-icon{
    margin-right: -.75rem;
  }

  form-component form-group-component form-group-component:last-child div{
    margin-bottom:0;
  }
}

//Collapse animation
.global-alert-removed{
  animation-name: collapse;
  animation-duration: 0.1s;
}

@keyframes collapse {
  0%   {max-height: 40rem}
  100%  {max-height: 0rem; display:none; padding-top: 0; padding-bottom:0; margin:0; opacity: 0;}
}


global-popup-basic-component{
  ul{
    list-style: disc;
    padding-left: 1rem;
    padding-top: 0.5rem;
    li {
      list-style: disc;
    }
  }
}

.global-alert-title-container{
  //clear:left;
  //margin-bottom: 0.5rem;
}

.global-alert-title{
  color: $color_text_darkgray;
  font-size: 2rem;
  //float: left;
  margin: 0;
}

.global-alert-sub-title{
  font-size: 16px;
  padding-bottom: 0.25rem;
  //line-height: 2rem;
  font-weight: 400;
  //white-space: pre; //Spaties bewaren
}

.global-alert-type-icon{
  padding: 0;
  //margin-top:-1rem;
  margin-left:-1rem;
  margin-right: 0.5rem;
  font-size: 70px;
  padding-top: 0.2rem;
}

.global-alert-type-icon_container{
  vertical-align: baseline;

}

.global-alert-text{
  //padding-top: 0.5rem;
  //float: left;
  //max-width: 30rem;
  margin-left:2rem;
}

.global-alert-label{
  font-weight: 100;
}

.global-alert-close-icon{
  padding:0;
  font-weight: 200;
  float: right;
  margin-left:2rem;
}

.alert div .text-gray-dark{
  @extend .text-gray-dark;
}

global-popup-create-exception-component form-component #dim_group_exception{
  margin-bottom: 0;
}

default-popup-wrapper-component{
  .small-mode{
    margin-left:auto;
    margin-right:auto;
    max-width: 420px;
  }
  .large-mode{
    margin-left:auto;
    @media only screen and (min-width: $mobile-breakpoint-width) {
      min-width: 975px;
    }
  }
}

// Display elements side to side
global-popup-create-generic-report-component #reportStedinForm > .w-100 > form-group-component > .form-group > .w-100
{
  @include media-breakpoint-up(md) {
    // Tijdelijke IE 11 fix
    display: -ms-grid !important;
    -ms-grid-columns: 1fr 1fr;

    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;

    // IE FIX
    form-group-component {
      display: block;

      &:nth-of-type(1) {
        -ms-grid-column: 1;
      }

      &:nth-of-type(2) {
        -ms-grid-column: 2;
      }
    }

    form-location {
      // IE FIX
      -ms-grid-column-span: 2;
      -ms-grid-row: 2;
      display: block;

      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

global-popup-map-option-component .switch
{
  label{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:0;
  }
}

global-popup-browser-warning-component {
  .browser-container {
    padding-top: 2rem;
    border-radius: 2px;
    border: 2px solid $color_grey-border-light;
    height: auto;
    padding-left: 0.5rem;
    grid-column: 2;
    -ms-grid-column: 2;
    &.ie-11 {
      grid-column: 1;
      -ms-grid-column: 1;
      .images {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        margin-bottom: 3rem;
        .img {
          margin-bottom: -15px;
          img{
            justify-self: center;
          }
        }
      }
    }
  }

  .grid-container {
    display: -ms-grid; // Tijdelijke IE 11 fix
    -ms-grid-columns: 1fr 1fr;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    &.ended{
      -ms-grid-columns: 0fr 1fr;
      grid-template-columns: 0fr 1fr;
      width: 75%;
      margin: 0 auto;
    }
  }

  .ie-warning-terms{
    background-color: $color_super-light-grey;
    border: 1px solid $selection-gray;;
    border-radius: 2px;
  }

  .images {
    display: flex;
    margin-bottom: 0rem;

    .img {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;

      img {
        height: 75px;
        width: 60px;
      }

      p {
        font-weight: 700;
        text-align: center;
      }

      a {
        place-self: center;
        cursor: pointer;
      }

      i {
        &.clear {
          font-size: 5rem;
          margin-top: -85px;
          margin-left: 8px;
          color: red;
        }

        &.done {
          color: $color_status-done;
          margin-top: -35px;
          margin-left: 40px;
          font-size: 3rem;
          //z-index: -1; Dit werkt niet op IE11
        }
      }
    }
  }
}

input[type=range] {
  height: 25px;
  width: 100%;
}

input[type=range]::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  margin-top: -6px;
}

input[type=range]::-moz-range-thumb{
  height: 16px;
  width: 16px;
  margin-top: -6px;
}

input[type=range]::-ms-thumb{
  height: 16px;
  width: 16px;
  margin-top: -6px
}

.first-login-icon{
  margin-right: 0.5rem;
  border-radius: 3px;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  width: 2rem;
  height: 2rem;
  background: $color_secondary-blue;
}

.device-mapping-intro-text span{
  color:#0057a2;
  font-weight: bold;
}
