$tree-node-line-height: 2;

.tree-node-icon{
  padding: 0;
  font-size: 16px; //Eigenlijk officieel geen lekkere size voor material icon
  cursor: pointer;

  //padding-top: 0.35rem;
  line-height: 1.6;
  vertical-align: middle;
}

.tree-collapse-button-container{
  //position: absolute;
  bottom:0;
  right:0;
  width: 100%;
  background-color: white;
  margin-top:auto;
}

.tree-collapse-button{
  float:right;
  cursor: pointer;
}

.tree-node-icon-single{
  @extend .tree-node-icon;
  color: $selection-blue;
}

.tree-node-icon-action{
  @extend .tree-node-icon;
  color: $color_widget-grey;
  &:hover {
    font-size: large !important;
  }
}

/*.tree-node-icon-arrows{
  @extend .tree-node-icon;
  color: $color_text_darkgray;
}*/

.tree-node-label{
  margin:0;
  cursor:pointer;
  &.tree-node-label-highlighted{
    //background:yellow;
  }
}

tree-node-component{
  color: $color_text_darkgray;
  //overflow: scroll;
  //height:100%; //disabled for Chrome fix

  display:flex;
  flex-direction: column;
}

tree-node-component label{
  //TODO: nu wordt het selecteren van text in de tree altijd gestopt. je kan het ook preventen vanaf het moment dat shift wordt ingedrukt, tot weer los
  //Prevent text selection for shift+click
  //@include unselectable-content;
}

.tree-node-component-wrapper{
  overflow: auto;
  white-space: nowrap;
  height:100%;
}

.tree-node-icon-container{
  float: left;
  //padding-top: 0.25rem;
}

.tree-component {
  //padding:1rem;
  height:100%;
}

.tree-component-root{
  //padding: 1rem;
  //padding: 1rem 0.5rem 1rem 0.5rem;

  display: inline-table;
  width:100%;

  @include unselectable-content;
  //padding-right:1rem;
}

.tree-node-component-selected {
  background: $color_light-blue;
  line-height: $tree-node-line-height;

  &:hover,
  &.active {
    background-color: $color_lightest-blue //TODO: weer een nieuwe kleur??
  }
}

.tree-node-component{
  //padding-right:2rem;
  line-height: $tree-node-line-height;
  //display:inline-block;
  width: 100%;

  &:hover,
  &.active {
    background-color: $selection-gray
  }
  &.hidden-tree-node{
    display: none !important;
  }
}

.tree-node-color{
  width: 20px;
  height: 20px;
  border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: $color_light-grey;
}

.tree-node-button
{
  border-radius: 3px;
  background-color: $color_white;
  display: flex;
  padding: 0.1rem;
  @extend .z-depth-1;

  &.enabled{
    cursor: pointer;
  }

  &.disabled{
    color:$selection-gray;
  }

  .material-icons{
    font-size: 18px;
  }
}
