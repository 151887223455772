.menu-navbar{
  background: $color_blue-darker;
  z-index: 1080; //give it a high z-index, so most cases where there can be an overlap are covered (increased because of bootstrap tooltip)
  // if needed increase this value
  border-radius:0;

  flex-direction: row;

  box-shadow: none;
}

.menu-logo-link{
  margin: 0;
  display:flex;
}

.menu-icon{
  float:left;
  padding: 0;
}

.menu-flat-loader-container{
  //Als je deze weglaat is de loader onzichtbaar tot hij actief wordt
  //background: $blue-darker; //No-loader-color
  height: 4px;
  border-radius:0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index:1042;
}

right-side-menu-component .nav-item dynamic-menu-item-component{
  display: flex;
  flex-direction: column;
}

.menu-left-container{
  display: flex;


  //IE 11 fix, without it the menu buttons will be too close to the logo
  //flex-direction: row;
  margin-right: 1rem;

  @include media-breakpoint-up(xl) {
    max-width: 10%;
  }
}

.menu-back-button{
  padding: 0;
  /* align-self: center; */
  /* padding-top: 0.5rem; */
  color: white;
  font-size: 32px;
  padding-right: 1rem;
  cursor: pointer;

  @include unselectable-content;
}

.menu-icon-container{
  display: flex;
  align-items: center;
  float: left
}

.nav-flex-icons .dropdown .dropdown-toggle{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-flat-loader{
  z-index: 100; //Fix for not being visible with absolute placed modules
  border-radius:0;
  background-color:$blue-loading; //Main color of loader
  margin: 0;
}

//IE 11 fix, without it the menu buttons will be too close to the logo
.menu-navbar-collapse{
  width: auto !important;
  flex-grow: 1;
  .navbar-nav{
    display: inline-block;
  }
}

.nav-flex-icons{
  //display: inline-block;
  white-space: nowrap;
}

.menu-collapse-button{
  z-index: 1040;
  top:10px;
  padding-right:0;
}

.menu-flat-loader-inside{
  background-color: $color_blue-darker !important; //Second color of loader
}

.menu-areaal-dropdown{
  padding-top: 0.5rem;
}

.menu-areaal-search-container {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.menu-areaal-search {
  margin-bottom: 0.5rem;
  padding-top: 0;
  //margin-left: 0.5rem;
  //margin-right: 0.5rem;
}

.menu-search-dropdown{
  padding-top: 0.5rem;
}

//Safari fix voor zoekveld dat afgeknipt werd
menu-search-component .md-form .menu-search{
  line-height:1;
}

.menu-search {
  margin-bottom: 0.5rem;
  padding-top: 0;
  //margin-left: 0.5rem;
  //margin-right: 0.5rem;
  font-size: 15px !important;

  width:150px !important;
  @include media-breakpoint-down(lg) {
    width:100px !important;
  }
  /*@include media-breakpoint-down(md) {
    width:50px !important;
  }*/
}

.menu-areaal-list-container{
  overflow-y: auto;
  //Fix for IE 11. If not implemented the areaaldropdown will be infinite long when a search query produces no results
  //overflow-y: scroll; //max-height: 5.75rem; //15.5rem
  //Kutzooi dit zorgt weer voor problemen als er 0 items zijn. De oplossing voor nu: altijd 1 item tonen en overflow op auto
  //Dus iets van 'geen resulaten' weergeven als item zodat er altijd content is
  max-height: 9.25rem; //15.5rem;
}

/*.selected-searchitem{
  background-color: $selection-gray;
}*/

.navbar-collapse .menu_icon{
  //padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navbar-nav .nav-link {
  padding-left: 0.5rem;
}

menu-search-component .menu-search {
  &:focus{
    border-bottom:1px solid $blue-loading !important;
    box-shadow: 0 1px 0 0 $blue-loading !important;
  }
}

menu-areaal-search-component .menu-areaal-search {
  &:focus{
    border-bottom:1px solid $blue-loading !important;
    box-shadow: 0 1px 0 0 $blue-loading !important;
  }
}

.show-submenu .menu-submenu{
  display: block;
}

.menu-submenu{
  position: absolute;
  left: 100%;
  top: -0.5rem;
  margin-left: 0.7rem;
  width: 100%;
}

.navbar.navbar-dark.menu-navbar dynamic-menu-item-component {

  dynamic-menu-item-component .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .3em solid transparent;
    border-left: .3em solid $color_very-dark-grey;
    border-bottom: 0.3em solid transparent;
  }

  .dropdown a.nav-item.nav-link.disabled {
    color:grey;

    &:hover{
      color:grey !important;
      background-color: transparent !important;
    }
  }
}

.dropdown-toggle-right {
  display: flex;
  width: 0;
  height: 0;
  margin-left: .3em;
  vertical-align: middle;
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-left: .3em solid transparent;
  transform: rotate(-90deg);
  right: 0.25rem;
  position: absolute;
  top: 50%;
}


/* Change navbar slidein position to left side */
@include media-breakpoint-down(lg){
  .navbar-collapse {
    position: fixed;
    top: 0;
    left: -100%;
    padding:12px 15px 110% 15px;
    width:100% !important;
    height: 100%;
    background:rgba(54, 87, 120, .98);
    overflow-y:scroll;
    z-index:1040;
  }
  .navbar-toggler .navbar-close-icon{
    font-size:2.3rem;
    margin-top:-6px;
  }
  .navbar-collapse .mobile-menu-close-button{
    top:4px;
  }
  .navbar-collapse.show {
    left: 0;
    transition: left 300ms ease-in-out;
  }
  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 300ms ease-in-out;
  }
  .navbar-collapse #areaalHighlightId{
    margin-top:20px;
    border-bottom:1px solid #FFF;
    width:100%;
    z-index:1010;
  }
  .navbar-collapse #areaalHighlightId a{
    margin-bottom: 10px;
    width:100%;
  }
  .navbar-collapse #areaalHighlightId .menu-areaal-dropdown{
    width:100%;
    border-radius:0;
  }
  .navbar-collapse #itemsHighlightId{
    margin-top:20px;
  }
  .navbar-collapse #itemsHighlightId .dropdown-toggle::after{
    content:none;
  }
  .navbar-collapse #itemsHighlightId .dropdown .dropdown-menu{
    position:relative !important;
    display:block !important;
    background:none;
    box-shadow:none;
    padding:0;
    margin:0;
  }
  .navbar-collapse #itemsHighlightId .dropdown .dropdown-menu a{
    color:#FFF;
    width:auto;
  }
  .navbar-collapse #itemsHighlightId li.nav-item{
    margin-bottom:20px;
  }
  .navbar-collapse #itemsHighlightId li.nav-item a div{
    font-size:20px !important;
  }
  .navbar-collapse #itemsHighlightId li.nav-item a.active{
    background:none;
  }
  .navbar-collapse #itemsHighlightId li.nav-item .dropdown-menu li.dropdown{
    margin-bottom:0;
    padding-left:10px;
  }
  .navbar-collapse #itemsHighlightId li.nav-item .dropdown-menu li.dropdown a.selected-searchitem, .navbar-collapse #itemsHighlightId li.nav-item .dropdown-menu li.dropdown a.active{
    color:#f29c40 !important;
    background:none !important;
  }
  .navbar-collapse #itemsHighlightId .nav-item .dropdown-menu .dropdown .nav-item div{
    font-size:15px !important;
  }
  .navbar-collapse .menu-icon-container{
    margin-top:10px;
    padding-top:10px;
    border-top:1px solid #FFF;
    width:100%;
  }
  .navbar-collapse .menu-icon-container .dropdown-menu{
    left:0;
    right:auto;
  }
  .navbar-collapse .menu-icon-container #userHighlightId .dropdown-menu{
    margin-left:-35px;
  }
  .menu-content-container .d-flex, #navbarNavAltMarkup .d-flex #itemsHighlightId .nav-item{
    display: block !important;
  }
  .mobile-search-dropdown .menu-search-dropdown{
    z-index:1011;
  }
  #searchHighlightIdMobile{
    margin-right:70px;
    margin-top:3px;
  }
  #areaalHighlightId .blue-areaal-button-background::after{
    content: "\25be";
    text-align: right;
    float:right;
  }
}
@media (max-width: 767px) {
  #searchHighlightIdMobile{
    margin-right:30px;
  }
}

.mobile-flex-direction-column .history-container .dropdown-menu{
  margin-right:-80px;
  max-width:335px;
}
