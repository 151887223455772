// TODO: check if the Safari fixes are still needed


$mobile-navigation-height: 40px;

@include media-breakpoint-down(sm) {
  .module-wrapper {
    position: absolute;
    top: 55px;
    width: 100%;
    bottom: 0;
  }

  .tree-map-form-navigation{
    z-index:999;
    position: fixed;
    bottom:0;
    background:$color_blue-darker;
    color:#FFF;
    border-top: 1px solid rgba(0,0,0,0.3);
    height:$mobile-navigation-height;
    box-shadow: 0 2px 5px 5px rgba(0,0,0,0.3);

    .material-icons{
      &.rotate{
        rotate: 180deg;
      }
      &.active{
        background:#FFF;
        border-radius:15px;
        padding:5px !important;
        margin-top:-1px !important;
        font-size:16px;
        color:$color_blue-darker;
      }
    }
  }

  .tree-map-form-component {
    overflow:hidden;
    .is-tab {
      width:100%;
      position: absolute;
      transition: all ease-in-out 0.4s;
      z-index:10;
      padding-bottom:$mobile-navigation-height !important;
      &.module-tree-container{
        margin-left:-100%;
        &.is-active-tab {
          margin-left:0;
        }
      }
      &.module-map-container{
        z-index:9;
      }
      &.module-form-container{
        margin-left:100%;
        .no-form-data{
          position: relative;
          text-align:center;
          top:-50%;
          display: none;
        }
        &.is-active-tab {
          margin-left:0;
          .no-form-data{
            display: block;
          }
        }
      }
    }

  }
}

  @include media-breakpoint-up(md) {
    .module-wrapper {
      position: absolute;
      top: 55px;
      width: 100%;
      //bottom: 32px; //45px;
      bottom: 0px;
    }
  }

  .module-tree-container {
    //padding-left: 0;
    padding-top: 0rem;
    height:100%; //Safari fixje
  }

  .module-collapsed-tree-container{
    width: 40px;
    margin-right: 1rem;
  }

  .module-map-container-stretched{
    flex-grow: 1;
    width: 10px;
  }

  @include media-breakpoint-down(sm) {
    .module-map-container {
      height: 100%; //Safari fixje
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0;
    }
  }

  @include media-breakpoint-up(md) {
    .module-map-container {
      height: 100%; //Safari fixje
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .module-form-container {
      //padding-right: 0;
      height: 100%; //Safari fixje
      padding-top: 0;
      padding-bottom: 3rem;
    }
  }

  @include media-breakpoint-up(md) {
    .module-form-container {
      //padding-right: 0;
      height: 100%; //Safari fixje
      padding-top: 0rem;
    }
  }

  .module-section-form-root {
    padding: 0;
    width: 100%;
    margin: 0;
  }

  .module-section-item{
    padding:0px;
  }

  .module-text-section-item{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    font-size:14px;
  }

  .module-text-section-item-child{
    padding-left: 4em;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 12px;
  }

  .module-section-container {
    .list-group {
      // this list group is taken out of the document flow (absolute position)
      // we set the z-index to a high number so that the list group is clickable wholly
      // TODO maybe: improve row/columns structure?
      //z-index: 1000; //Even uitgezet, is dit nog wel nodig? zorgt nu voor problemen met de footer in mobile mode
      //Kan ook opgelost worden door de footer z-index hoger te zetten

      padding-top:80px;
      width: 40px; // force an acceptable width
      border: 0;
      border-radius: 0;
      box-shadow: none;

      position: absolute;
      top: 0;
      //left: 14px;
      height: 100%;
      background-color: $color_super-light-grey;
      color: $color_grey-module-section;
    }

    .module-section-item {
      border: none;
      border-radius: 0;
      color: $color_grey-icon-dark;
      background-color: transparent;
      &:active,
      &.active
      {
        background-color: $selection-gray;
        border-color: $color_grey-module-section;
        color: black !important; // override, because list group items are already overridden with !important
      }
      &:hover {
        background-color: $selection-gray;
      }
    }
    .form.dynamic-form{
      padding-left:30px;
    }
  }
