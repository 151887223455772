#manual-print-container{
  position:absolute;
  top:0;
  left:0;
  padding:10px;
  width:100%;
  height:auto;
  background:#FFFFFF;
  z-index:999999;
  overflow:hidden;

  .first-page{
    text-align:center;
    margin-top:200px;
    img{
      margin-bottom:30px;
    }
    p{
      font-style: italic;
    }
  }

  .chapter {
    page-break-before: always;
  }

  img{
    width:auto;
    max-width:100%;
    height:auto;
    max-height:840px;
  }

  video{
    display:none;
  }

  ul li::before {
    vertical-align: middle;
    content: "• ";
    color: #f29C40;
    padding-right: 0.5rem;
  }
}
