.fa-dropzone {
  padding: 0.5rem;
  border: 2px dashed $selection-gray;
  width: 100%;
  text-align: center;

  .fa-dropzone-icon {
    @extend .material-icons;
    color: lightgray;
    margin: 0;
    font-size: 32px;
  }

  &.fileover {
    border: 2px dashed $color_secondary-blue-hover;
  }
}

.fa-dropzone-disabled {
  @extend .fa-dropzone;
  cursor: default;
  pointer-events: none;

  &.fileover {
  }
}
