.tn-menu-dropdown {
  position: absolute;
  top: 1.5rem;
  left:auto;
  right: 0;
  z-index: 10;

  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

  text-transform: none;
  font-weight: 300;
  font-size: 0.9375rem;
  color: $color_very-dark-grey;

  .tn-dropdown-option-container {
    width: 100%;
    padding: 0.5rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    .tn-dropdown-option {
      display: flex;
      width: 100%;
      cursor: pointer;

      i {
        @extend .material-icons, .md-dark;
        font-size: small;
        padding: 0;
        padding-left: 0.5rem;
      }

      span {
        text-align: left;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      &:hover, &:focus {
        background-color: $selection-gray;
      }
    }
  }
}
