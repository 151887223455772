.form-log-container {
  font-size: 0.8rem;

  .no-logs{
    background-color: $color_super-light-grey;
    //padding-left: 0.5rem;
    font-style: italic;
    //padding-right: 0.5rem;
    //padding-top: 0.25rem;
    //padding-bottom: 0.25rem;
  }

  .form-log {
    background-color: $color_super-light-grey;
    border-radius: 2px;
    position: relative;

    .form-attachment-loader{
        height: 4px;
        border-radius: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      .indeterminate{
        background-color: $color_light-blue;
      }
    }

    .form-attachment-title-container{
      border-bottom: 1px solid $selection-gray;
    }

    .form-attachment-title{
      width: 100%;
      font-weight: bold;

      //Auto truncate
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .form-new-attachment-title{
      border-bottom: 1px solid $selection-gray;
      width: 100%;
      font-weight: bold;
    }

    .form-new-attachment-preview{
      max-width: 100%;
      height:auto;
      background-color: white;
      display: flex;
      border: 1px solid $selection-gray;
    }

    .form-new-attachment-no-preview{
      color: $color_widget-grey;
      width: 100%;
      min-width:100px;
      height: 100px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $selection-gray;
      div{
        font-size:20px;
      }
    }

    .form-attachment-preview{
      width: 100px;
      min-width:100px;
      height: 100px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $selection-gray;
      div{
        font-size:20px;
      }
    }

    .form-attachment-data{
      span{
        font-style: italic;
      }
    }

    .form-log-name {
      font-weight: bold;
    }

    .form-log-date {
      font-style: italic;
    }

    .form-log-message {
      white-space: pre-line;
      border-top: 1px solid $selection-gray;
      &.no-message{
        font-style:italic;
      }
    }

    .form-new-message-input{
      border-top: 1px solid $selection-gray;
      textarea{
        height: 5rem;
        resize: none;
        border: none;
        background-color: white;
        &:focus {
          outline:none;
        }
      }
    }

    .form-new-message-button {
      @extend .btn;
      background-color: $color_secondary-blue-hover;
      color: white;
      border-radius: 2px;
      text-transform: unset !important;

      &.disabled {
        background-color: $color_darker-grey;;
      }
    }

    .form-new-message-counter{
      font-size: 10px;
    }

    .close-icon{
      font-size: 14px;
      margin-top: -0.5rem;
    }

    &.form-message-deleted{
      color:$selection-gray;
    }
  }
}

.colored-status {
  height: 25px;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  color:white;
  text-align: center;
  padding-top:0.1rem;
}
