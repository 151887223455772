.reporting-tiles {
  padding: 1rem 2rem 0 2rem;
  h3 {
    color: #365778;
  }
}
.group-tile-container{
  .tile{
    position: relative;
    background:#8bb7e2;
    .tile-top-part{
      position:absolute;
      border-radius:8px 8px 0 0;
      background:rgba(255,255,255,.8);
    }
    .tile-bottom-part{
      height:auto;
      padding-top:50px;
      .manual-image {
        margin:0 0 10px 0 !important;
        img{
          max-height:none;
        }
      }
    }
    .widget-subtext-container{
      padding-right:1rem;
      padding-left:1rem;
      margin:0;
      background:rgba(255,255,255,.8);
      border-radius:0 0 8px 8px;
      border-top:0;
    }
  }
}
.no-reports-container {
  position: absolute;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  width: 20rem;
  height: 5.5rem;
  top: calc(50% - 2.75rem);
  left: calc(50% - 10rem)
}
