.menu-dropdown {
  @extend .ml-auto, .dropdown, .nav-item, .btn-group, .nav-item, .p-0, .pr-2, .lumi-button-input;

  height: 2.2rem;
  width: 3.2rem;
  padding-left: 5px!important;
  margin-right: 0.2rem;
  padding-right:2px!important;

  .md-button {
    @extend .px-0, .py-1, .nav-link, .dropdown-toggle, .waves-effect, .waves-light, .menu_icon;

    i {
      @extend .material-icons, .md-dark, .menu-icon;
      color: $color_text_darkgray;
    }
  }

  .md-menu {
    @extend .dropdown-menu, .dropdown-secondary;

    z-index: 99;

    .md-header {
      @extend .menu-dropdown-header;
    }

    .md-item {
      @extend .luminizer-main-menu-drop-down-item, .waves-effect, .waves-light, .d-flex, .md-focusable;

      i {
        @extend .menu-dropdown-icon;
      }
    }
  }
}

.menu-dropdown-small {
  @extend .menu-dropdown;
  width: 2.5rem;

  .md-button {
    i {
      font-size: large;
    }
  }
}

.md-focusable {
  &.focussed {
    background-color: $selection-gray !important;
    color: $elegant-color-dark !important;
    box-shadow: none !important;
  }
}

.min-width-max-content {
  min-width: max-content!important;
}
