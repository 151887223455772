.map {
  background-color: red;
  height: 100px;
  width: 100px;
  font-family: $font-family-sans-serif;
  font-weight: 200;
  font-style: normal;
}

body {
  #map {
    width: 100% !important;
  }
}

.cluster-icon{
  span{
    color: $color_white;
  }
}
