$sidebar-width: 28%;

/* Notification component */
.notifications-container{
  .status-card{
    margin:20px 0;
    .statusbar{
      height:14px;
    }
    .notify-badge{
      background:#a81715;
      border:3px solid #FFF;
      border-radius:50%;
      position:absolute;
      top:0;
      left:0;
      margin-top:-5px;
      margin-left:-8px;
      width:25px;
      height:25px;
    }
    .inner{
      word-break: normal;
      .info{
        padding-top:10px;
        h6{
          font-size:25px;
          font-weight:300;
          cursor: pointer;
        }
        h6[aria-expanded="true"] {
          cursor:default;
        }
        .date{
          text-transform: capitalize;
          padding:3px 0 10px 0;
        }
        .notification-content{
          padding-bottom:10px;
          img{
            width:auto;
            height:auto;
            max-width:100%;
          }
          img.no-max-width{
            max-width:none;
          }
          li{
            font-size:14px;
            font-weight:300;
            list-style:disc;
            margin-left:15px;
          }
        }
      }
      .arrow .material-icons{
        font-size:40px;
        color:rgba(0,0,0,.5);
        padding:0;
        cursor: pointer;
      }
      .arrow[aria-expanded="true"] .material-icons{
        display:none;
      }
    }
  }
}

/* Sidebar */
#notificationHighlightId{
  padding:8px;
}
.notifications-backdrop{
  position: fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  z-index:8;
}
.notifications-bell.has-notifications .notify-badge, .notifications-sidebar .sidebar-content .status-card .notify-badge{
  background:#a81715;
  border:1px solid #FFF;
  border-radius:50%;
  position:absolute;
  margin-left:15px;
  width:10px;
  height:10px;
  z-index:1;
}
.notifications-bell.has-notifications .notify-badge, .notifications-sidebar .no-notifications{
  color:#FFF;
  font-style: italic;
}
.notifications-bell.has-notifications .material-icons{
  animation: shakeNotification 4s infinite linear;
  animation-delay: 3s;
}
@include media-breakpoint-down(lg) { .notifications-bell{position:absolute;right:60px;top:17px;} }

.notifications-sidebar{
  position: fixed;
  padding:10px;
  top:0;
  right:-$sidebar-width;
  width:$sidebar-width;
  height:100%;
  z-index:9;
  background:rgba(54, 87, 120, 0.98);
  overflow-y:auto;
  transition: right .4s;
  box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.3);
  .sidebar-header{
    margin-bottom:30px;
    h3{
      padding:0;
      margin:0;
      color:#FFF;
    }
    .sidebar-title{
      a{
        font-size:14px;
        transition:none !important;
      }
      .material-icons{
        padding:0;
        margin:0;
        vertical-align:middle;
      }
    }
    .sidebar-close span{
      color:#FFF;
      font-size:30px;
      line-height:20px;
      cursor: pointer;
    }
  }
  .sidebar-content{
    padding:0 5px;
    .status-card{
      margin:20px 0 20px 0;
      .notify-badge{
        margin:-5px 0 0 -5px;
        width:18px;
        height:18px;
        border-width: 2px;
      }
      .inner{
        .image.text{
          width:60px;
          height:60px;
          font-size:20px;
          line-height:20px;
        }
        .info{
          width:100%;
          h6{
            font-size:18px;
            font-weight:300;
            min-height:70%;
            white-space: normal;
            word-break: normal;
            a{
              color:#4b4b4b;
            }
          }
          .info-datelink{
            bottom:0;
            border-top:1px solid #e6e6e6;
            padding-top:5px;
            font-size:11px;
            .date{
              text-transform: capitalize;
            }
            a{
              color:#2e77d7;
            }
          }
        }
      }
    }
  }
}
.notifications-sidebar.visible{
  right:-1px;
}
body.env-warn .notifications-sidebar .sidebar-header{
  padding-top:20px;
}
@include media-breakpoint-up(xl) { .notifications-sidebar{right:-$sidebar-width;width:$sidebar-width;} }
@include media-breakpoint-down(md) { .status-card .inner .image.text{width:60px;height:60px;font-size:20px;line-height:20px;}.carousel-indicators{margin-left:25%;} }

/* Modal */
.notifications-modal {
  margin-bottom:20px;
  .notification-modal-item{
    border-top:1px solid #CCCCCC;
    margin-top:20px;
    padding-top:20px;
    &.first-item{
      border-top:0;
      margin-top:0;
      padding-top:0;
    }
    .date{
      font-size:12px;
      text-transform: capitalize;
      margin-bottom:20px;
      font-weight: 300;
    }
  }
}

/* Shake animation */
@keyframes shakeNotification {
  0% { transform: rotate(0deg); }
  3% { transform: rotate(25deg); }
  6% { transform: rotate(0deg); }
  9% { transform: rotate(-25deg); }
  12% { transform: rotate(0deg); }
  15% { transform: rotate(25deg); }
  18% { transform: rotate(0deg); }
}
