.asi-checkmark-div {
  position: relative;
  display: flex;
  cursor: pointer;
  //margin: 0.1rem;
  height: 1.8rem !important;
  width: 1.8rem !important;
  background-color: $color_super-light-grey;
  border: $color_light-grey solid 4px;
  border-radius: 50%;
  transition: border-color 0.25s linear;

  &:hover, &:focus {
    outline: unset;
    box-shadow: 1px 2px 2px 0 rgb(0 0 0 / 20%), 1px 2px 20px 0 rgb(0 0 0 / 12%),
    inset 1px 2px 2px 0 rgb(0 0 0 / 20%); //, inset 1px 2px 20px 0 rgb(0 0 0 / 6%);
    transition: box-shadow 0.12s linear;
  }
}

.asi-checkmark-div-small {
  @extend .asi-checkmark-div;
  height: 1rem !important;
  width: 1rem !important;
  border: $color_light-grey solid 2px;
}

.asi-checkmark-div-disabled {
  @extend .asi-checkmark-div;
  cursor: default;
  pointer-events: none;

  &:hover {
    box-shadow: unset;
  }
}

.asi-checkmark-div-small-disabled {
  @extend .asi-checkmark-div-small, .asi-checkmark-div-disabled;
}

.asi-check-icon {
  transform: rotate(45deg);
  height: 1.8rem;
  width: 1rem;
  border-bottom: 6px solid $color_status-done;
  border-right: 6px solid $color_status-done;
  margin-top: -9px;
  margin-left: 7px;
  opacity: 1 !important;
  z-index: 9 !important;
}

.asi-check-icon-small {
  @extend .asi-check-icon;
  height: 0.9rem;
  width: 0.5rem;
  border-bottom: 3px solid $color_status-done;
  border-right: 3px solid $color_status-done;
  margin-top: -4.5px;
  margin-left: 3.5px;
}

.asi-checkmark-todo {
  width: calc(1.8rem + 6px);
  height: calc(1.8rem + 6px);
  border: $color_super-light-grey solid 3px;
  border-radius: 50%;

  .div-checked {
    border-color: lightgreen;

    .check {
      @extend .asi-check-icon
    }
  }
}

.asi-checkmark-todo-small {
  width: calc(1rem + 4px);
  height: calc(1rem + 4px);
  border: $color_super-light-grey solid 2px;
  border-radius: 50%;

  .div-checked {
    border-color: lightgreen;

    .check {
      @extend .asi-check-icon-small;
    }
  }
}

.asi-checkmark-attention {
  width: calc(1.8rem + 6px);
  height: calc(1.8rem + 6px);
  border: $color_super-light-grey solid 3px;
  border-radius: 50%;

  .asi-checkmark-div {
    border-color: orange;
  }

  .div-checked {
    .check {
      @extend .asi-check-icon;
      border-bottom-color: darkorange;
      border-right-color: darkorange;
    }
  }
}

.asi-checkmark-attention-small {
  width: calc(1rem + 4px);
  height: calc(1rem + 4px);
  border: $color_super-light-grey solid 2px;
  border-radius: 50%;

  .asi-checkmark-div-small {
    border-color: orange;
  }

  .div-checked {
    .check {
      @extend .asi-check-icon-small;
      border-bottom-color: darkorange;
      border-right-color: darkorange;
    }
  }
}

.asi-checkmark-disabled {
  width: calc(1.8rem + 6px);
  height: calc(1.8rem + 6px);
  border: $color_super-light-grey solid 3px;
  border-radius: 50%;

  .asi-checkmark-div {
    border-color: $brand-danger;
    transition: border-color 0.1s linear;
  }
}

.asi-checkmark-disabled-small {
  width: calc(1rem + 4px);
  height: calc(1rem + 4px);
  border: $color_super-light-grey solid 2px;
  border-radius: 50%;

  .asi-checkmark-div-small {
    border-color: $brand-danger;
    transition: border-color 0.1s linear;
  }
}
