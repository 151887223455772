.status-progress {
  margin-bottom: 1rem;

  .sp-container {
    overflow-x: auto;
    display: flex;
    padding-top: 0.45rem;
    margin-bottom: 0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .sp-line {
      height: 1px;
      min-width: 2rem;
      width: 100%;
      border: 0;
      border-top: 4px solid $color_light-grey;
      border-radius: 1rem;
      margin: 1em 0;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    .sp-line-done {
      @extend .sp-line;
      border-color: $color_secondary-blue-hover;
    }

    .sp-line-done-disabled {
      @extend .sp-line;
      border-color: $color_darker-grey;
    }

    .sp-item {
      margin-left: 0.8rem;
      margin-right: 0.8rem;
      width: 100%;
      min-width: 5rem;

      &:hover {
        .sp-icon {
          border-color: $color_lighter-blue;
        }
      }

      .sp-subIcon-wrapper {
        position: relative;

        .sp-subIcon-row {
          position: absolute;
          top: -0.4rem;
          margin-left: 53%;
          display: inline-flex;
          overflow: hidden;

          .sp-subIcon {
            border: 2px solid;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 4px;
            height: 17px;
            width: 17px;
            min-width: 17px;
            background: $color_super-light-grey;
            position: relative;
            //width: 2rem;
            //margin-right: -0.5rem;
            i {
              @extend .material-icons, .md-dark;
              position: absolute;
              padding: 0;
              pointer-events: none;
              cursor: default;
              font-size: small !important;
              width: 0 !important;
              height: 0 !important;
            }
            span {
              font-size: x-small;
              position: absolute;
              left: 50%;
              top: -5%;
              transform: translateX(-50%);
            }
          }
        }
      }

      .sp-icon {
        margin: auto;
        height: 2rem;
        width: 2rem;
        padding: 2px;
        border: $color_light-grey solid 4px;
        border-radius: 50%;
        cursor: pointer;

        &:hover, &:focus, &:active {
          outline: unset;
          box-shadow: 1px 2px 2px 0 rgb(0 0 0 / 20%), 1px 2px 20px 0 rgb(0 0 0 / 12%),
          inset 1px 2px 2px 0 rgb(0 0 0 / 20%); //, inset 1px 2px 20px 0 rgb(0 0 0 / 6%);
          transition: box-shadow 0.12s linear;
        }

        .sp-icon-mark {
          display: none;
          height: 100%;
          width: 100%;
          background-color: $color_light-grey;
          border-radius: 50%;
        }
      }

      .sp-icon-active {
        @extend .sp-icon;

        .sp-icon-mark {
          display: block;
          background-color: $color_secondary-blue-hover;
        }
      }

      .sp-icon-finished {
        @extend .sp-icon;
        border-color: $color_secondary-blue-hover;

        .sp-icon-mark {
          display: block;
        }
      }

      .sp-icon-finished-active {
        @extend .sp-icon-finished;

        .sp-icon-mark {
          background-color: $color_secondary-blue-hover;
        }
      }

      .sp-icon-disabled {
        @extend .sp-icon;
      }

      .sp-icon-active-disabled {
        @extend .sp-icon;

        .sp-icon-mark {
          display: block;
          background-color: $color_darker-grey;
        }
      }

      .sp-icon-finished-disabled {
        @extend .sp-icon;
        border-color: $color_darker-grey;

        .sp-icon-mark {
          display: block;
        }
      }

      .sp-icon-finished-active-disabled {
        @extend .sp-icon-finished-disabled;

        .sp-icon-mark {
          background-color: $color_darker-grey;
        }
      }

      .sp-label {
        font-size: small;
        text-align: center;
      }

      .sp-info-wrapper {
        pointer-events: none;
        position: absolute;
        z-index: 99;

        .sp-info-window {
          display: block;
          background-color: white;
          border-radius: 2px;
          border: $color_light-grey solid 1px;
          box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

          .sp-info-row {
            display: flex;
            font-size: small;
            width: 100%;
            padding-left: 2%;
            padding-right: 2%;
          }
        }

        .sp-info-window-first {
          @extend .sp-info-window;
          margin-left: -15rem;
        }

        .sp-info-window-last {
          @extend .sp-info-window;
          margin-left: -15rem;
        }
      }
    }

    .sp-subIcon-small {
      @extend .material-icons;
      padding: 0;
      font-size: small;
      cursor: default;
      width: 0 !important;
      height: 0 !important;
    }
  }
}

