.map-table-component{
  height: 100%;
}

.map-table-container{
  display: flex;
  height: 100%;
  overflow: auto; // auto: only show scroll bar if content does not fit in the containing element
  // this is essentially the same as overflow-x: auto AND overflow-y: auto
}

.map-table-view-container{
  height: 100%;
  @include media-breakpoint-down(sm) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  padding:1rem;
  padding-top: 0;
  padding-bottom: 0;
}

.map-table-view-toggle{
  flex-direction: row;
  color: $color_text_darkgray;

  .nav-item .active{
    color:$selection-blue;
  }
}

.map-table-container .table-header{
  background: $color_widget-grey;
  color: $color_text_white;
}

.map-table-table-container{
    overflow-y: auto;
    height: 100%;
    margin-top: auto;
    //margin-right: 1rem;
    //@include unselectable-content;
}

map-table-component .map-table-view-container .top-bar-container .quick-filter{
  max-width: 200px;
  background-color: $color_super-light-grey;
  box-shadow: none;
  border-bottom: 0;
  padding-left:0.5rem;

  &.active{
      background-color: $color_lightest-blue;
  }
}

.map-table-active-selection{
  font-size: 0.8rem;
  text-align: right;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 0.25rem;
  align-self: center;
}

.map-table-item-count{
  font-size: 0.8rem;
  text-align: right;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.top-bar-container{
  min-height: 60px; // min height for when the table/mix/map buttons are not visible (hideMap mode)
  display:flex;
  //align-items: center
}

.map-table-filter-container{
  //width:100%;
  display: flex;
  align-items:center;
  flex-grow: 1; //IE 11 fix for input width pushing icons off
}

/*.map-table-table-container tr {
  cursor: pointer;
  &:hover,
  &.active {
    background-color: $selection-gray
  }
}*/

.map-table-view-toggle-icon{
  &:hover {
    background:$selection-gray;
  }
}

.default-dropdown {

  .dropdown-menu{
    width: 15rem;
  }

  .dropdown .dropdown-menu div:not(.auto-refresh-checkmark):not(.sub-dropdown):not(.dropdown-toggle-right):not(.dropdown-item-with-icon):not(.dropdown-item-container):not(.dropdown-item-options):not(.dropdown-item-label) {
    font-size: .9375rem;
    font-weight: 300;
    cursor: pointer;
    //padding: 10px;

    padding-left: 1rem !important;
    padding-right: 2rem !important;
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;

    &:hover {
      background-color: $selection-gray !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }

  .dropdown .dropdown-menu .dropdown-item-with-icon:not(.auto-refresh-checkmark):not(.sub-dropdown):not(.dropdown-toggle-right) {
    font-size: .9375rem;
    font-weight: 300;
    cursor: pointer;
    //padding: 10px;

    padding-left: 1rem !important;
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;

    &:hover {
      background-color: $selection-gray !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
}

.public-portal-info{
    position: absolute;
    z-index: 1;
    bottom: 1.65rem;
    left: 1.65rem;
    padding: 1rem;
    margin-right: 4.5rem; //Spacing + zoombuttons

    ul{
      list-style-type: disc;
      li{
        list-style-type: disc;
      }
    }
}

.report-generic-info{
  position: absolute;
  z-index: 1;
  bottom: 2.75rem;
  left: 5rem;
  padding: 1rem;
  ul{
    list-style-type: disc;
    li{
      list-style-type: disc;
    }
  }
}

.no-content-popup{
  position: absolute;
  display: flex;
  align-self: center;
  left:0;
  right:0;
}

.auto-refresh-checkmark{
  transform: rotate(45deg);
  height: 14px;
  width: 8px;
  border-bottom: 3px solid $color_text_darkgray;
  border-right: 3px solid $color_text_darkgray;
  position: absolute;
  right: 1rem;
  bottom: 1.1rem;
}

.auto-refreshing{
  transform-origin: center center;
  color: $selection-blue;
  -webkit-animation:spin 1s linear infinite;
  -moz-animation:spin 1s linear infinite;
  animation:spin 1s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
