.map-component{
  width: 100%;
  height: 100%;
  position: absolute;
}

.map-component-container{
    position: relative;
    height: 100%;

    /* text-align: center; */
    display: flex;
    justify-content: center;
    //align-items: center;
}

.map-search-box {
  //z-index: 99;
  position: absolute;
  /* left: 0px; */
  top: 0px;
  right: 0;
  width: 155px;
  @include media-breakpoint-up(lg) {
    width: 250px;
  }
  background-color: white;
  margin-right: 100%;
  padding: 0.25rem;
  height: 39px; //100%; IE-11 fix, fixed height
  transition: 0.25s ease-out;

  &.hidden-search{
    width:0px;
    right: -10px;
  }
}

.map-component-info-container {
  position: absolute;
  margin-left:2rem;
  margin-right: 2rem;
  left: 0;
  right: 0;
  top:35%;
  pointer-events:none;
}

.map-component-option-form{
    padding: 1rem;
    margin: 0 auto;

    background-color: white;
    border-radius: $border_radius_large;

    //left: -50%;
    position: relative;

    @extend .light-font;
    @extend .z-depth-5;
}

.map-component-option-form .form-inline{
  width:100%;
  //flex-flow:row;
  //padding-right:1rem;
}

.map-component-option-form hr{
  margin-bottom: 0;
  margin-top: 0.25rem;
}

.map-option-row {
  padding-top: 0.75rem;
  justify-content: space-between;
  margin-left: 0.5rem;
}

.map-option-row-maptype {
  padding-top: 0.5rem;
  padding-left: 1rem;
}

.map-options-header{
    display: flex;
    justify-content: space-between;
    color:$color_text_darkgray;
}

.map-streetview-icon{
    line-height: 1.2;
    padding: 0;
    padding-right: 0.5rem;
  font-size: 20px;
}

.map-streetview-container{
  width: 100%;
}

.map-component-option-container {
  z-index: 10;
  position: absolute;
  margin-left:2rem;
  margin-right: 2rem;
  //left: 50%;
  //right: 0;
  //margin-top:15%;
  font-size: 14px;
  font-weight: 300;

  display: flex;
  //align-self: center;
  align-items: center;
  right: 0;
  left: 0;
  justify-content: center;
  height: 100%;
}

.map-infowindow-code-button{
  display: flex;
  align-items: center;
  cursor: pointer;
  //padding-bottom: 3px;

  img{
    margin-right:3px;
  }

  &.no-select{
    text-decoration: none;
    color: black;
    cursor: default;
  }
}

.map-infowindow-code-button-selected{
  text-decoration: underline;
}

.map-infowindow-streetview-button{
  cursor: pointer;
  color: $color_map-overlay-text-grey;
}

.map-infowindow-table{
  padding-top:0.25rem;
  margin-top:0.25rem;
  border-top: 1px solid $selection-gray;
}

.map-infowindow-container{
  margin-top:0.5rem;
  display: flex;
  align-items: center;
  font-size: 12px;

  @include unselectable-content;
}

.map-infowindow-baseobject-container{
  color: $color_secondary-blue;
}

.map-option-label{
  margin-right:1rem;
}

.map-option-dropdown{
  min-width:180px;
  .multi-select-option-list label{
    justify-content:left;
  }
}

.map-option-label-maptype{
    margin-left: 1rem;
    margin-top: 0.75rem;
}

.map-option-row-maptype .form-group{
    margin-bottom: 0;
}

.map-option-close-btn{
    float: right;
    margin-top: -1rem;
    margin-right: -0.75rem;

    color:$color_map-overlay-text-grey;

    cursor: pointer;

    @include unselectable-content;

    &:hover{
      color:$color_text_darkest-gray;
    }
}

//Overrides default color for switches from mdb
default-popup-wrapper-component .switch label input[type=checkbox]:checked + .lever {
  background-color: desaturate(lighten($primary-color, 25%), 25%);
}

default-popup-wrapper-component .switch label input[type=checkbox]:checked + .lever:after {
  background-color: $primary-color;
}

map-options-component{
  margin-left:0.5rem;
  margin-right:0.5rem;
}

map-legend-component{
  margin-left:0.5rem;
  margin-right:0.5rem;
}

.map-popup-background{
  background-color: rgba(0, 0, 0, 0.5)
}

.map-component-options-container {
  position: absolute;
  //margin-left:1rem;
  //margin-right: 1rem;
  left: 0;
  //right: 0;
  //top:35%;

  right: auto;
  top: 0;
  /* background: white; */
  margin-top: 1rem;
  color:$color_map-overlay-text-grey;

  cursor: pointer;

  @include unselectable-content;
}

.map-component-location-container {
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
  margin-top: 1rem;
  color:$color_map-overlay-text-grey;
  cursor: pointer;
  @include unselectable-content;
}

.map-option-label-maptype ul li{
  list-style-type:disc;
}

.map-component-legend-container {
  position: absolute;
  //margin-left:1rem;
  //margin-right: 1rem;
  left: 0;
  //right: 0;
  //top:35%;

  right: auto;
  bottom: 0;
  /* background: white; */
  margin-bottom: 1rem;
  color:$color_map-overlay-text-grey;

  cursor: pointer;

  @include unselectable-content;
}

.map-component-options-button{
    &:hover{
    color:$color_text_darkest-gray;
  }
}



.map-component-no-marker{
  text-align: center;
}

.map-component-labels {
  width: 0;
  color: white;
  font-size: 14px;
  font-weight: 200;
  text-align: center;
  white-space: nowrap;
  overflow: visible !important; //overriding inline styles with !important
  margin-left: -20px !important;
  font-family: 'Roboto';

  //Kan je gebruiken om te checken hoe breed het label is onder een marker
  //background-color: black;

  //Later text-stroke gebruiken alleen die wordt pas in edge ergens gesupport...
  text-shadow:  $color_black 0px 0px 2px,   $color_black 0px 0px 2px,   $color_black 0px 0px 2px;
}

.map-legend-row{
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $selection-gray;
}

.map-button{
  margin:10px;
}

.right-click-menu {
  background-color: #f9f9f9;
  border-radius: 5px;
  font-size: 0.7rem;
  ul {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    li {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      &:hover {
        cursor: pointer;
        background-color: #ececec;
      }
    }
  }
}

.clipboard-success {
  position: sticky;
  width: fit-content;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem;
  background-color: #323232;
  color: white;
}