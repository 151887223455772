.key-value {
  display: flex;
  font-size: small;
  .kv-key {
    flex: 40%;
  }

  .kv-value {
    display: flex;
    align-items: center;
    flex: 60%;
    padding-left: 0.2rem;
  }
}
