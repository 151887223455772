.map-item-importer {
  .mii-input {
    display: flex;

    input.ng-invalid.ng-touched:not(:focus):not(.ng-pristine) {
      border-bottom: $color_error solid 2px;
    }
  }

  .mii-add-row-button {
    @extend .p-0, .pr-2, .mr-4, .mt-3, .btn, .d-flex, .align-items-center;

    background-color: $color_lighter-grey;

    height: 1.6rem;
    color: $color_very-dark-grey !important;
    margin-top: 0.5rem !important;
    display: inline-flex !important;
    text-transform: unset !important;
    margin-left: auto;
    margin-right: 0;

    i {
      @extend .material-icons, .md-dark;
      padding-left: 0.3rem;
      font-size: medium;
    }
  }

  .mii-map-container {
    @extend .card;

    .mii-map {
      @extend .widget-map-container;
      margin-bottom: 1rem;
    }
  }
}
