.btn {
  border-radius: $border_radius_smallest;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  cursor:pointer;
}

.btn-primary {
  &:active {
    background-color: $color_blue !important;
  }
  &.active {
    background-color: $color_blue !important;
    @extend .z-depth-1-half;
  }
}

.dynamic-icon-size{

  @include media-breakpoint-up(lg) {
    font-size: 24px !important;
  }
  @include media-breakpoint-down(lg) {
    font-size: 18px !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 24px !important;
  }
}

.disabled-add-button{
  color:$color_grey;
  cursor: $cursor-disabled !important;
}

input[type="range"]:-moz-focusring {
  outline-offset: 0px!important;
}

.select-all-boxes{
  font-size: 0.6rem;
  padding: 0.75rem;
  background-color: $color_very-dark-grey;
}
