.full-height-wrapper {
  position: absolute;
  top: 55px;
  width: 100%;
  //bottom: 30px;
  bottom:0px;
}

.full-height {
  height: 100% !important;
}