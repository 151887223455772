.fm-folder-container {
  //margin-bottom: 0.5rem;

  .fm-folder {
    display: flex;
    position: relative;
    width: 100%;
    background: $color_super-light-grey;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0.2rem;
    padding-top: 0;
    cursor: pointer;

    .fm-folder-icon {
      @extend .material-icons, .md-dark;
      padding: 0;
    }

    .fm-folder-title {
      @extend .text-truncate;
      overflow: hidden;
      width: 100%;
      align-self: center;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-top: 0.2rem;
    }

    .fm-folder-count {
      position: absolute;
      border-radius: 50%;
      bottom: 3px;
      left: 2rem;
      width: 15px;
      height: 15px;
      background-color: $color_darker-grey;
      border: 1px solid $color_white;
      span {
        position: absolute;
        text-align: center;
        width: 12px;
        top: -1px;
        color: white;
        font-size: 10px;
        font-weight: bold;
      }
    }
  }

  .fm-folder-content {
    display: block;
    padding-left: 0.5rem;

    .fm-file-list-container {
      display: flex;

      .fm-file-list {
        width: 100%;
        overflow: hidden;
        display: block;
        font-size: small;
        font-style: italic;

        .fm-file {
          @include unselectable-content;
          display: flex;
          cursor: pointer;

          &:hover, &:focus {
            background-color: $selection-gray;
          }

          i {
            @extend .material-icons, .md-dark;
            font-size: small;
          }

          span {
            @extend .text-truncate;
            align-self: center;
            padding-right: 0.2rem;
          }
        }

        .fm-file-active {
          @extend .fm-file;
          background-color: $color_light-blue;
        }
      }

      .fm-file-info {
        width: 100%;
        height: inherit;
        background: $color_super-light-grey;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        font-size: x-small;
        padding: 0.2rem;
        padding-top: 0;
        position: relative;

        .fm-file-name {
          font-size: small;
          font-weight: bold;
          word-break: break-all;
        }

        .fm-multi-file {
          font-size: small;
          font-weight: bold;
        }

        .fm-file-button-row {
          display: flex;
          border-bottom: $color_light-grey solid 1px;
          padding-bottom: 0.2rem;
          padding-right: 1.5rem;
        }


        .fm-file-data {
          padding-top: 0.3rem;

          .fm-file-info-row {
            display: flex;

            .fm-file-info-label {
              width: 100%;
              font-style: italic;
            }

            .fm-file-info-value {
              width: 100%;
              margin-left: 0.2rem;
              word-break: normal;
            }
          }
        }
      }
    }

    .fm-file-selector-container {
      margin: 0.5rem;
      position: relative;
    }
  }

  .fm-close-button {
    @extend .btn, .align-items-center;

    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    left: calc(100% - 1.6rem);
    background-color: $color_super-light-grey;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: none;
      i {
        font-size: large;
      }
    }

    i {
      @extend .material-icons, .md-dark;
      font-size: medium;
      padding: 0;
      padding-top: 0.2rem;
    }
  }

  .fm-menu-button-container {
    position: relative;

    .fm-menu-dropdown {
      z-index: 10;
      width: fit-content;
      width: -moz-fit-content;
      position: absolute;
      right: 0;
      padding-left: 0.1rem;
      padding-right: 0.4rem;
      background-color: white;
      border-radius: 2px;
      border: $color_light-grey solid 1px;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

      button {
        &:not(:hover) {
          box-shadow: unset;
        }
      }
    }
  }

}
