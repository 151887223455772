.check-activity-cell-child {
  display: inline-flex;
  width: 100%;

  .cacc-icon {
    position: absolute;
    .asi-checkmark-div {
      background-color: white;
    }
  }

  span {
    margin-left: 1.5rem;
  };
}
