$timeline-color:#365778;

.settings-container{
  padding-bottom:0;
  .reporting-details{
    overflow-y:auto;
    .report-container{
      position: absolute;
      width:100%;
      height:89%;
      bottom:0;
      @media (max-height:1160px) {
        height:85%;
      }
      @media (max-height:990px) {
        height:80%;
      }
      @media (max-height:750px) {
        height:70%;
      }
    }
  }
  @media (max-width:560px) {
    .report-details-container{
      padding:0 10px;
    }
  }
  .timeline-container{
    width: 100%;
    overflow:auto;
    direction: rtl;
    .timeline-steps{
      white-space: nowrap;
      text-align:right;
      margin-top:10px;
      direction: ltr;
      min-width: fit-content;
      .timeline-step{
        width:160px;
        margin:0 10px;
        display:inline-block;
        text-align: center;
        cursor: pointer;
        .inner-circle{
          width:20px;
          height:20px;
          border:2px solid $timeline-color;
          background:#FFF;
          border-radius:10px;
          margin:0 auto;
          position: relative;
          &:after {
            content: "";
            display: block;
            width: 180px;
            height: 2px;
            background: $timeline-color;
            top: 50%;
            position: absolute;
            z-index:-1;
          }
        }
        .inner-title{
          margin-top:10px;
          white-space: normal;
          overflow:auto;
          text-transform: capitalize;
        }
        &.active, &:hover{
          .inner-circle{
            background:$timeline-color;
          }
        }
        &:last-child{
          .inner-circle:after{
            width:0;
          }
        }
      }
    }
  }
  .report-details{
    padding-top:12px;
    h3{
      color:#365778;
    }
    .button{
      margin-right:5px;
      background:#365778;
      color:#FFF;
      cursor: pointer;
      &:hover{
        background:rgba(54, 87, 120, .8);
      }
    }
  }
}