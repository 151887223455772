// Sidenav background variants
.sn-bg-1 {
    background-image: url(http://mdbootstrap.com/img/Photos/Others/sidenav1.jpg);
}

.sn-bg-2 {
    background-image: url(http://mdbootstrap.com/img/Photos/Others/sidenav2.jpg);
}

.sn-bg-3 {
    background-image: url(http://mdbootstrap.com/img/Photos/Others/sidenav3.jpg);
}

.sn-bg-4 {
    background-image: url(http://mdbootstrap.com/img/Photos/Others/sidenav4.jpg);
}

// Deep Purple skin
$deep-purple-skin-primary-color: #d0637c;
$deep-purple-skin-navbar: #4b415a;
$deep-purple-skin-accent: #d0637c;
$deep-purple-skin-flat: #383048;
// Gradients
$deep-purple-skin-gradient-start: #282233;
$deep-purple-skin-gradient-end: #776598;
// Masks
$deep-purple-skin-mask-slight: rgba(22, 5, 34, 0.5);
$deep-purple-skin-mask-light: rgba(22, 5, 34, 0.65);
$deep-purple-skin-mask-strong: rgba(22, 5, 34, 0.8);
// Buttons
$deep-purple-skin-btn-primary: #d0637c;
$deep-purple-skin-btn-secondary: #5b477b;
$deep-purple-skin-btn-default: #372e5f;
$elegant-dark-skin-text: #fff;
.deep-purple-skin {
    .gradient {
        background: $deep-purple-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $deep-purple-skin-gradient-start 0%, $deep-purple-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $deep-purple-skin-gradient-start 0%, $deep-purple-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $deep-purple-skin-gradient-start 0%, $deep-purple-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $deep-purple-skin-primary-color!important;
    }
    .navbar {
        background-color: $deep-purple-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $deep-purple-skin-accent, 5%);
                    }
                }
            }
        }
    }
    .page-footer {
        background-color: $deep-purple-skin-navbar;
    }
    .side-nav {
        background-color: $deep-purple-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $deep-purple-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $deep-purple-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                color: #fff;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: $deep-purple-skin-accent;
                }
                &.active {
                    background-color: $deep-purple-skin-accent;
                }
            }
        }
        .fa {
            color: #fff;
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $deep-purple-skin-mask-strong;
            }
            &.mask-light:after {
                background: $deep-purple-skin-mask-light;
            }
            &.mask-slight:after {
                background: $deep-purple-skin-mask-slight;
            }
        }
    }
    .btn-primary {
        background: $deep-purple-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $deep-purple-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $deep-purple-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $deep-purple-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $deep-purple-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $deep-purple-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $deep-purple-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $deep-purple-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $deep-purple-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $deep-purple-skin-accent;
        box-shadow: 0 1px 0 0 $deep-purple-skin-accent;
        &+label {
            color: $deep-purple-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $deep-purple-skin-accent;
                border-bottom: 2px solid $deep-purple-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $deep-purple-skin-accent;
                border-color: $deep-purple-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $deep-purple-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $deep-purple-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $deep-purple-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $deep-purple-skin-accent;
        }
    }
    // Form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $deep-purple-skin-accent, 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $deep-purple-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $deep-purple-skin-primary-color;
            border-color: $deep-purple-skin-primary-color;
        }
        .page-link {
            color: $deep-purple-skin-primary-color;
        }
    }
}

// Navy Blue skin
$navy-blue-skin-primary-color: #309e9e;
$navy-blue-skin-navbar: #353b50;
$navy-blue-skin-accent: #309e9e;
$navy-blue-skin-flat: #383048;
// Gradients
$navy-blue-skin-gradient-start: #222735;
$navy-blue-skin-gradient-end: #63739c;
// Masks
$navy-blue-skin-mask-slight: rgba(14, 15, 32, 0.5);
$navy-blue-skin-mask-light: rgba(14, 15, 32, 0.65);
$navy-blue-skin-mask-strong: rgba(14, 15, 32, 0.8);
// Buttons
$navy-blue-skin-btn-primary: #309e9e;
$navy-blue-skin-btn-secondary: #324f7b;
$navy-blue-skin-btn-default: #293756;
.navy-blue-skin {
    .gradient {
        background: $navy-blue-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $navy-blue-skin-gradient-start 0%, $navy-blue-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $navy-blue-skin-gradient-start 0%, $navy-blue-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $navy-blue-skin-gradient-start 0%, $navy-blue-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $navy-blue-skin-primary-color!important;
    }
    .navbar {
        background-color: $navy-blue-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $navy-blue-skin-accent, 5%);
                    }
                }
            }
        }
    }
    .page-footer {
        background-color: $navy-blue-skin-navbar;
    }
    .side-nav {
        background-color: $navy-blue-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $navy-blue-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $navy-blue-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                color: #fff;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: $navy-blue-skin-accent;
                }
                &.active {
                    background-color: $navy-blue-skin-accent;
                }
            }
        }
        .fa {
            color: #fff;
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $navy-blue-skin-mask-strong;
            }
            &.mask-light:after {
                background: $navy-blue-skin-mask-light;
            }
            &.mask-slight:after {
                background: $navy-blue-skin-mask-slight;
            }
        }
    }
    .btn-primary {
        background: $navy-blue-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $navy-blue-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $navy-blue-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $navy-blue-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $navy-blue-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $navy-blue-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $navy-blue-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $navy-blue-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $navy-blue-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $navy-blue-skin-accent;
        box-shadow: 0 1px 0 0 $navy-blue-skin-accent;
        &+label {
            color: $navy-blue-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $navy-blue-skin-accent;
                border-bottom: 2px solid $navy-blue-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $navy-blue-skin-accent;
                border-color: $navy-blue-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $navy-blue-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $navy-blue-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $navy-blue-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $navy-blue-skin-accent;
        }
    }
    // Tags, form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $navy-blue-skin-accent, 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $navy-blue-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $navy-blue-skin-primary-color;
            border-color: $navy-blue-skin-primary-color;
        }
        .page-link {
            color: $navy-blue-skin-primary-color;
        }
    }
}

// Cyan skin
$cyan-skin-primary-color: #4fada7;
$cyan-skin-navbar: #21403f;
$cyan-skin-accent: #4fada7;
$cyan-skin-flat: #1e5252;
// Gradients
$cyan-skin-gradient-start: #153535;
$cyan-skin-gradient-end: #4b9292;
// Masks
$cyan-skin-mask-slight: rgba(7, 45, 42, 0.5);
$cyan-skin-mask-light: rgba(7, 45, 42, 0.65);
$cyan-skin-mask-strong: rgba(7, 45, 42, 0.8);
// Buttons
$cyan-skin-btn-primary: #4fada7;
$cyan-skin-btn-secondary: #145b5f;
$cyan-skin-btn-default: #053638;
.cyan-skin {
    .gradient {
        background: $cyan-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $cyan-skin-gradient-start 0%, $cyan-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $cyan-skin-gradient-start 0%, $cyan-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $cyan-skin-gradient-start 0%, $cyan-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $cyan-skin-primary-color!important;
    }
    .navbar {
        background-color: $cyan-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $cyan-skin-accent, 5%);
                    }
                }
            }
        }
    }
    .page-footer {
        background-color: $cyan-skin-navbar;
    }
    .side-nav {
        background-color: $cyan-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $cyan-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $cyan-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                color: #fff;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: $cyan-skin-accent;
                }
                &.active {
                    background-color: $cyan-skin-accent;
                }
            }
        }
        .fa {
            color: #fff;
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $cyan-skin-mask-strong;
            }
            &.mask-light:after {
                background: $cyan-skin-mask-light;
            }
            &.mask-slight:after {
                background: $cyan-skin-mask-slight;
            }
        }
    }
    .btn-primary {
        background: $cyan-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $cyan-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $cyan-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $cyan-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $cyan-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $cyan-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $cyan-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $cyan-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $cyan-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $cyan-skin-accent;
        box-shadow: 0 1px 0 0 $cyan-skin-accent;
        &+label {
            color: $cyan-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $cyan-skin-accent;
                border-bottom: 2px solid $cyan-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $cyan-skin-accent;
                border-color: $cyan-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $cyan-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $cyan-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $cyan-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $cyan-skin-accent;
        }
    }
    // Tags, form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $cyan-skin-accent, 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $cyan-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $cyan-skin-primary-color;
            border-color: $cyan-skin-primary-color;
        }
        .page-link {
            color: $cyan-skin-primary-color;
        }
    }
}

// Pink skin
$pink-skin-primary-color: #bd5d70;
$pink-skin-navbar: #532a3c;
$pink-skin-accent: #bd5d70;
$pink-skin-flat: #673348;
// Gradients
$pink-skin-gradient-start: #2d0c1a;
$pink-skin-gradient-end: #b16382;
// Masks
$pink-skin-mask-slight: rgba(72, 24, 43, 0.5);
$pink-skin-mask-light: rgba(72, 24, 43, 0.65);
$pink-skin-mask-strong: rgba(72, 24, 43, 0.8);
// Buttons
$pink-skin-btn-primary: #bd5d70;
$pink-skin-btn-secondary: #3f4f7d;
$pink-skin-btn-default: #532a3c;
.pink-skin {
    .gradient {
        background: $pink-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $pink-skin-gradient-start 0%, $pink-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $pink-skin-gradient-start 0%, $pink-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $pink-skin-gradient-start 0%, $pink-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $pink-skin-primary-color!important;
    }
    .navbar {
        background-color: $pink-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $pink-skin-accent, 5%);
                    }
                }
            }
        }
    }
    .page-footer {
        background-color: $pink-skin-navbar;
    }
    .side-nav {
        background-color: $pink-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $pink-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $pink-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                color: #fff;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: $pink-skin-accent;
                }
                &.active {
                    background-color: $pink-skin-accent;
                }
            }
        }
        .fa {
            color: #fff;
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $pink-skin-mask-strong;
            }
            &.mask-light:after {
                background: $pink-skin-mask-light;
            }
            &.mask-slight:after {
                background: $pink-skin-mask-slight;
            }
        }
    }
    .btn-primary {
        background: $pink-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $pink-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $pink-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $pink-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $pink-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $pink-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $pink-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $pink-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $pink-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $pink-skin-accent;
        box-shadow: 0 1px 0 0 $pink-skin-accent;
        &+label {
            color: $pink-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $pink-skin-accent;
                border-bottom: 2px solid $pink-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $pink-skin-accent;
                border-color: $pink-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $pink-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $pink-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $pink-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $pink-skin-accent;
        }
    }
    // Tags, form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $pink-skin-accent, 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $pink-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $pink-skin-primary-color;
            border-color: $pink-skin-primary-color;
        }
        .page-link {
            color: $pink-skin-primary-color;
        }
    }
}

// Indigo skin
$indigo-skin-primary-color: #7f82c5;
$indigo-skin-navbar: #3d466d;
$indigo-skin-accent: #7f82c5;
$indigo-skin-flat: #26294e;
// Gradients
$indigo-skin-gradient-start: #181a31;
$indigo-skin-gradient-end: #6f74b7;
// Masks
$indigo-skin-mask-slight: rgba(31, 38, 76, 0.5);
$indigo-skin-mask-light: rgba(31, 38, 76, 0.65);
$indigo-skin-mask-strong: rgba(31, 38, 76, 0.8);
// Buttons
$indigo-skin-btn-primary: #7f82c5;
$indigo-skin-btn-secondary: #313f67;
$indigo-skin-btn-default: #13204a;
.indigo-skin {
    .gradient {
        background: $indigo-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $indigo-skin-gradient-start 0%, $indigo-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $indigo-skin-gradient-start 0%, $indigo-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $indigo-skin-gradient-start 0%, $indigo-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $indigo-skin-primary-color!important;
    }
    .navbar {
        background-color: $indigo-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $indigo-skin-accent, 5%);
                    }
                }
            }
        }
    }
    .page-footer {
        background-color: $indigo-skin-navbar;
    }
    .side-nav {
        background-color: $indigo-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $indigo-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $indigo-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                color: #fff;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: $indigo-skin-accent;
                }
                &.active {
                    background-color: $indigo-skin-accent;
                }
            }
        }
        .fa {
            color: #fff;
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $indigo-skin-mask-strong;
            }
            &.mask-light:after {
                background: $indigo-skin-mask-light;
            }
            &.mask-slight:after {
                background: $indigo-skin-mask-slight;
            }
        }
    }
    .btn-primary {
        background: $indigo-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $indigo-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $indigo-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $indigo-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $indigo-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $indigo-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $indigo-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $indigo-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $indigo-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $indigo-skin-accent;
        box-shadow: 0 1px 0 0 $indigo-skin-accent;
        &+label {
            color: $indigo-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $indigo-skin-accent;
                border-bottom: 2px solid $indigo-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $indigo-skin-accent;
                border-color: $indigo-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $indigo-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $indigo-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $indigo-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $indigo-skin-accent;
        }
    }
    // Tags, form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $indigo-skin-accent, 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $indigo-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $indigo-skin-primary-color;
            border-color: $indigo-skin-primary-color;
        }
        .page-link {
            color: $indigo-skin-primary-color;
        }
    }
}

// Light blue skin
$light-blue-skin-primary-color: #3d799c;
$light-blue-skin-navbar: #5da4b5;
$light-blue-skin-accent: rgba(21, 73, 103, 0.73);
$light-blue-skin-flat: #318594;
// Gradients
$light-blue-skin-gradient-start: #295a67;
$light-blue-skin-gradient-end: #56abb9;
// Masks
$light-blue-skin-mask-slight: rgba(47, 115, 132, 0.5);
$light-blue-skin-mask-light: rgba(47, 115, 132, 0.65);
$light-blue-skin-mask-strong: rgba(47, 115, 132, 0.8);
// Buttons
$light-blue-skin-btn-primary: #3d799c;
$light-blue-skin-btn-secondary: #51aabf;
$light-blue-skin-btn-default: #1f567d;
.light-blue-skin {
    .gradient {
        background: $light-blue-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $light-blue-skin-gradient-start 0%, $light-blue-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $light-blue-skin-gradient-start 0%, $light-blue-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $light-blue-skin-gradient-start 0%, $light-blue-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $light-blue-skin-primary-color!important;
    }
    .navbar {
        background-color: $light-blue-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $light-blue-skin-accent, 5%);
                    }
                }
            }
        }
    }
    .page-footer {
        background-color: $light-blue-skin-navbar;
    }
    .side-nav {
        background-color: $light-blue-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $light-blue-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $light-blue-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                color: #fff;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: $light-blue-skin-accent;
                }
                &.active {
                    background-color: $light-blue-skin-accent;
                }
            }
        }
        .fa {
            color: #fff;
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $light-blue-skin-mask-strong;
            }
            &.mask-light:after {
                background: $light-blue-skin-mask-light;
            }
            &.mask-slight:after {
                background: $light-blue-skin-mask-slight;
            }
        }
    }
    .btn-primary {
        background: $light-blue-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $light-blue-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $light-blue-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $light-blue-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $light-blue-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $light-blue-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $light-blue-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $light-blue-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $light-blue-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $light-blue-skin-accent;
        box-shadow: 0 1px 0 0 $light-blue-skin-accent;
        &+label {
            color: $light-blue-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $light-blue-skin-accent;
                border-bottom: 2px solid $light-blue-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $light-blue-skin-accent;
                border-color: $light-blue-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $light-blue-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $light-blue-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $light-blue-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $light-blue-skin-accent;
        }
    }
    // Tags, form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $light-blue-skin-accent, 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $light-blue-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $light-blue-skin-primary-color;
            border-color: $light-blue-skin-primary-color;
        }
        .page-link {
            color: $light-blue-skin-primary-color;
        }
    }
}

// Grey skin
$grey-skin-primary-color: #ad495c;
$grey-skin-navbar: #7d8488;
$grey-skin-accent: rgba(187, 33, 77, 0.79);
$grey-skin-flat: #a5a4a4;
// Gradients
$grey-skin-gradient-start: #383838;
$grey-skin-gradient-end: #a5a4a4;
// Masks
$grey-skin-mask-slight: rgba(121, 121, 121, 0.5);
$grey-skin-mask-light: rgba(121, 121, 121, 0.65);
$grey-skin-mask-strong: rgba(121, 121, 121, 0.8);
// Buttons
$grey-skin-btn-primary: #ad495c;
$grey-skin-btn-secondary: #7d8488;
$grey-skin-btn-default: #393b44;
.grey-skin {
    .gradient {
        background: $grey-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $grey-skin-gradient-start 0%, $grey-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $grey-skin-gradient-start 0%, $grey-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $grey-skin-gradient-start 0%, $grey-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $grey-skin-primary-color!important;
    }
    .navbar {
        background-color: $grey-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $grey-skin-accent, 5%);
                    }
                }
            }
        }
    }
    .page-footer {
        background-color: $grey-skin-navbar;
    }
    .side-nav {
        background-color: $grey-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $grey-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $grey-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                color: #fff;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: $grey-skin-accent;
                }
                &.active {
                    background-color: $grey-skin-accent;
                }
            }
        }
        .fa {
            color: #fff;
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $grey-skin-mask-strong;
            }
            &.mask-light:after {
                background: $grey-skin-mask-light;
            }
            &.mask-slight:after {
                background: $grey-skin-mask-slight;
            }
        }
    }
    .navbar .navbar-nav .dropdown .dropdown-primary .dropdown-item:hover {
        background-color: darken( $grey-skin-accent, 5%);
    }
    .btn-primary {
        background: $grey-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $grey-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $grey-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $grey-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $grey-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $grey-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $grey-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $grey-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $grey-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $grey-skin-accent;
        box-shadow: 0 1px 0 0 $grey-skin-accent;
        &+label {
            color: $grey-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $grey-skin-accent;
                border-bottom: 2px solid $grey-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $grey-skin-accent;
                border-color: $grey-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $grey-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $grey-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $grey-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $grey-skin-accent;
        }
    }
    // Tags, form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $grey-skin-accent, 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $grey-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $grey-skin-primary-color;
            border-color: $grey-skin-primary-color;
        }
        .page-link {
            color: $grey-skin-primary-color;
        }
    }
}

// white skin
$white-skin-text-color: #424242;
$white-skin-primary-color: #4285F4;
$white-skin-navbar: #fff;
$white-skin-footer: #797979;
$white-skin-accent: #4285F4;
$white-skin-flat: #F9F9F9;
// Gradients
$white-skin-gradient-start: #ababab;
$white-skin-gradient-end: #fff;
// Masks
$white-skin-mask-slight: rgba(255, 255, 255, 0.5);
$white-skin-mask-light: rgba(255, 255, 255, 0.65);
$white-skin-mask-strong: rgba(255, 255, 255, 0.9);
// Buttons
$white-skin-btn-primary: #4285F4;
$white-skin-btn-secondary: #D25048;
$white-skin-btn-default: #0F0F0F;
.white-skin {
    .gradient {
        background: $white-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $white-skin-gradient-start 0%, $white-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $white-skin-gradient-start 0%, $white-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $white-skin-gradient-start 0%, $white-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $white-skin-primary-color!important;
    }
    .navbar {
        color: $white-skin-text-color;
        background-color: $white-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $white-skin-accent, 5%);
                    }
                }
            }
        }
        a:not(.dropdown-item) {
            color: $white-skin-text-color !important;
        }
        .form-control {
            border-bottom: 1px solid rgba(153, 153, 153, 0.298039);
            color: $white-skin-text-color;
            @include placeholder {
                color: rgba($white-skin-text-color, .5) !important;
            }
        }
    }
    .page-footer {
        background-color: $white-skin-footer;
    }
    .double-nav a {
        color: $white-skin-text-color;
    }
    .side-nav {
        background-color: $white-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $white-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $white-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .search-form {
            input[type="text"].form-control {
                border-bottom: 1px solid rgba(153, 153, 153, 0.298039);
                color: #424242;
                @include placeholder {
                    color: rgba($white-skin-text-color, .5) !important;
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                font-weight: 400;
                color: $white-skin-text-color;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: rgba(0, 0, 0, .05);
                }
                &.active {
                    font-weight: 500;
                    color: $white-skin-accent;
                    .fa {
                        color: $white-skin-accent;
                        @include transition (color 0.3s linear);
                    }
                }
            }
        }
        .fa {
            color: $white-skin-text-color;
            @include transition (color 0.3s linear);
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $white-skin-mask-strong;
            }
            &.mask-light:after {
                background: $white-skin-mask-light;
            }
            &.mask-slight:after {
                background: $white-skin-mask-slight;
            }
        }
    }
    .navbar .navbar-nav .dropdown .dropdown-primary .dropdown-item:hover {
        background-color: darken( $white-skin-accent, 5%);
    }
    .btn-primary {
        background: $white-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $white-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $white-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $white-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $white-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $white-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $white-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $white-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $white-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $white-skin-accent;
        box-shadow: 0 1px 0 0 $white-skin-accent;
        &+label {
            color: $white-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $white-skin-accent;
                border-bottom: 2px solid $white-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $white-skin-accent;
                border-color: $white-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $white-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $white-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $white-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $white-skin-accent;
        }
    }
    // Tags, form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $white-skin-accent, 2%);
    }
    .spinner-primary {
        border-color: $white-skin-primary-color;
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $white-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $white-skin-primary-color;
            border-color: $white-skin-primary-color;
        }
        .page-link {
            color: $white-skin-primary-color;
        }
    }
}

// black skin
$black-skin-primary-color: #2AD1A3;
$black-skin-navbar: #222;
$black-skin-accent: #2AD1A3;
$black-skin-flat: #222;
// Gradients
$black-skin-gradient-start: #000;
$black-skin-gradient-end: #616161;
// Masks
$black-skin-mask-slight: rgba(0, 0, 0, 0.5);
$black-skin-mask-light: rgba(0, 0, 0, 0.65);
$black-skin-mask-strong: rgba(0, 0, 0, 0.8);
// Buttons
$black-skin-btn-primary: #2AD1A3;
$black-skin-btn-secondary: #00684B;
$black-skin-btn-default: #0F0F0F;
.black-skin {
    .gradient {
        background: $black-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $black-skin-gradient-start 0%, $black-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $black-skin-gradient-start 0%, $black-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $black-skin-gradient-start 0%, $black-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $black-skin-primary-color!important;
    }
    .navbar {
        background-color: $black-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $black-skin-accent, 5%);
                    }
                }
            }
        }
    }
    .page-footer {
        background-color: $black-skin-navbar;
    }
    .side-nav {
        background-color: $black-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $black-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $black-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                color: #fff;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: $black-skin-accent;
                }
                &.active {
                    background-color: $black-skin-accent;
                }
            }
        }
        .fa {
            color: #fff;
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $black-skin-mask-strong;
            }
            &.mask-light:after {
                background: $black-skin-mask-light;
            }
            &.mask-slight:after {
                background: $black-skin-mask-slight;
            }
        }
    }
    .btn-primary {
        background: $black-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $black-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $black-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $black-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $black-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $black-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $black-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $black-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $black-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $black-skin-accent;
        box-shadow: 0 1px 0 0 $black-skin-accent;
        &+label {
            color: $black-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $black-skin-accent;
                border-bottom: 2px solid $black-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $black-skin-accent;
                border-color: $black-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $black-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $black-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $black-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $black-skin-accent;
        }
    }
    // Tags, form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $black-skin-accent, 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $black-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $black-skin-primary-color;
            border-color: $black-skin-primary-color;
        }
        .page-link {
            color: $black-skin-primary-color;
        }
    }
}

// mdb skin
$mdb-skin-primary-color: #33b5e5;
$mdb-skin-navbar: #293a48;
$mdb-skin-accent: #33b5e5;
$mdb-skin-flat: #293a48;
// Gradients
$mdb-skin-gradient-start: #3684b7;
$mdb-skin-gradient-end: #091827;
// Masks
$mdb-skin-mask-slight: rgba(9, 24, 39, 0.5);
$mdb-skin-mask-light: rgba(9, 24, 39, 0.65);
$mdb-skin-mask-strong: rgba(9, 24, 39, 0.8);
// Buttons
$mdb-skin-btn-primary: #33b5e5;
$mdb-skin-btn-secondary: #0e3d67;
$mdb-skin-btn-default: #1a6398;
.mdb-skin {
    .gradient {
        background: $mdb-skin-gradient-start;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, $mdb-skin-gradient-start 0%, $mdb-skin-gradient-end 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $mdb-skin-gradient-start 0%, $mdb-skin-gradient-end 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $mdb-skin-gradient-start 0%, $mdb-skin-gradient-end 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
        background-color: $mdb-skin-primary-color!important;
    }
    .navbar {
        background-color: $mdb-skin-navbar;
        .navbar-nav {
            .nav-item {
                .dropdown-menu a {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken( $mdb-skin-accent, 5%);
                    }
                }
            }
        }
    }
    .page-footer {
        background-color: $mdb-skin-navbar;
    }
    .side-nav {
        background-color: $mdb-skin-flat;
        .logo-wrapper {
            &> div {
                background-color: transparent!important;
            }
        }
        .sn-avatar-wrapper img {
            border: 3px solid darken( $mdb-skin-accent, 15%);
        }
        .social a {
            &:hover {
                .fa {
                    color: $mdb-skin-accent;
                    @include transition (all 0.3s linear);
                }
            }
        }
        .collapsible li {
            background-color: transparent;
            a {
                color: #fff;
                @include transition (all 0.3s linear);
                &:hover {
                    background-color: $mdb-skin-accent;
                }
                &.active {
                    background-color: $mdb-skin-accent;
                }
            }
        }
        .fa {
            color: #fff;
        }
        .sidenav-bg {
            &:after,
            &.mask-strong:after,
            {
                background: $mdb-skin-mask-strong;
            }
            &.mask-light:after {
                background: $mdb-skin-mask-light;
            }
            &.mask-slight:after {
                background: $mdb-skin-mask-slight;
            }
        }
    }
    .navbar .navbar-nav .dropdown .dropdown-primary .dropdown-item:hover {
        background-color: darken( $mdb-skin-accent, 5%);
    }
    .btn-primary {
        background: $mdb-skin-btn-primary;
        &:hover,
        &:focus {
            background-color: lighten( $mdb-skin-btn-primary, 5%)!important;
        }
        &.active {
            background-color: darken( $mdb-skin-btn-primary, 20%)!important;
        }
    }
    .btn-secondary {
        background: $mdb-skin-btn-secondary;
        &:hover,
        &:focus {
            background-color: lighten( $mdb-skin-btn-secondary, 5%)!important;
        }
        &.active {
            background-color: darken( $mdb-skin-btn-secondary, 20%)!important;
        }
    }
    .btn-default,
    .card .btn-action {
        background: $mdb-skin-btn-default;
        &:hover,
        &:focus {
            background-color: lighten( $mdb-skin-btn-default, 5%)!important;
        }
        &.active {
            background-color: darken( $mdb-skin-btn-default, 20%)!important;
        }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
        border-color: $mdb-skin-accent;
        box-shadow: 0 1px 0 0 $mdb-skin-accent;
        &+label {
            color: $mdb-skin-accent;
        }
    }
    input[type=checkbox]:checked {
        &+label {
            &:before {
                border-right: 2px solid $mdb-skin-accent;
                border-bottom: 2px solid $mdb-skin-accent;
            }
        }
    }
    input[type=checkbox].filled-in:checked {
        &+label {
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
            &:after {
                background-color: $mdb-skin-accent;
                border-color: $mdb-skin-accent;
            }
        }
    }
    .md-form {
        .prefix {
            &.active {
                color: $mdb-skin-accent;
            }
        }
    }
    /* Select colors */
    .dropdown-content {
        li:not(.disabled) {
            span {
                color: $mdb-skin-accent;
            }
        }
    }
    .top-nav-collapse {
        background-color: $mdb-skin-navbar;
    }
    .carousel-multi-item {
        .controls-top > a,
        .carousel-indicators li,
        .carousel-indicators li.active {
            background-color: $mdb-skin-accent;
        }
    }
    // Tags, form-header, card-header
    .form-header,
    .card-header {
        background-color: lighten( $mdb-skin-accent, 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
        border-color: $mdb-skin-primary-color;
    }
    .pagination-primary-color {
        .page-item.active .page-link,
        .page-item.active .page-link:focus,
        .page-item.active .page-link:hover {
            color: #fff;
            background-color: $mdb-skin-primary-color;
            border-color: $mdb-skin-primary-color;
        }
        .page-link {
            color: $mdb-skin-primary-color;
        }
    }
}
