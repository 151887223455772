@include media-breakpoint-down(sm) {
  .no-mobile-padding {
    padding: 0 !important;
  }
}

@include media-breakpoint-down(sm) {
  .no-mobile-padding-y {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@include media-breakpoint-down(sm) {
  .no-mobile-padding-x {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@include media-breakpoint-down(sm) {
  .no-mobile-side-margin {
    margin-right: 0;
    margin-left: 0;
  }
}

/*@include media-breakpoint-down(sm) {
  .mobile-visible {
    display:inline;
  }
}

@include media-breakpoint-up(md) {
  .mobile-visible {
    display:none;
  }
}*/

@include media-breakpoint-down(lg) {
  menu-component nav.navbar-inverse {
    flex-direction: column;
  }
}