.status-button {
  @extend .lumi-button-secondary, .d-flex;
  max-width: 12rem;
  min-width: 8rem;
  width: fit-content;
  position: relative;
  font-weight: bold;

  .sb-label {
    display: inline-flex;
    width: 100%;

    span {
      @extend .text-truncate;
      text-transform: uppercase;
      margin: auto;
      padding-left: 0.5rem;
    };

    i {
      @extend .lumi-button-icon, .dynamic-icon-size
    }
  }

  .sb-dropdown-wrapper {
    width: 100%;
    text-transform: none;
    font-weight: 300;
    font-size: 0.9375rem;
    color: $color_very-dark-grey;
    position: absolute;
    top: 100%;
    z-index: 99;

    .sb-dropdown {
      cursor: default;
      width: 100%;
      background-color: white;
      border: 2px solid $color_secondary-blue;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: block;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

      &:hover, &:focus {
        border-color: $color_secondary-blue-hover;
      }

      .sb-dropdown-divider {
        @extend .dropdown-divider;
        margin: 0.3rem;
      }

      .sb-dropdown-option {
        width: 100%;
        cursor: pointer;
        padding: 0.2rem;
        display: inline-flex;

        span {
          text-align: left;
          padding-left: 0.2rem;
          padding-right: 0.2rem;
        };

        i {
          @extend .material-icons, .md-dark;
          padding: 0;
          padding-left: 0.1rem;
          margin-left: auto;
          margin-right: 0;
          font-size: medium;
        }

        &:hover, &:focus {
          background-color: $selection-gray;
        }
      }

      .sb-dropdown-option-active {
        @extend .sb-dropdown-option;
        background-color: $color_light-blue;
      }

      .sb-dropdown-option-disabled {
        @extend .sb-dropdown-option;
        cursor: default;
        pointer-events: none;
        font-style: italic;
        color: $color_dark-grey;
      }

      .sb-dropdown-option-active-disabled {
        @extend .sb-dropdown-option;
        background-color: $color_light-blue;
        cursor: default;
        pointer-events: none;
        font-style: italic;
        color: $color_dark-grey;
      }

      .sb-dropdown-action {
        @extend .sb-dropdown-option;
        padding: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        i {
          font-size: large;
        }
      }
    }
  }
}

.status-button-disabled {
  @extend .status-button;

  pointer-events: none;
  cursor: default;
  box-shadow: unset;

  .sb-label {
    i {
      display: none;
    }
  }
}
