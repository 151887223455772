.list-group {
  a {
    &.active,
    &:active
    {
      background-color: $color_blue-darker;
      border-color: $color_blue-darker;
      color:white !important;
      //-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      //transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    }
  }
}

.manual {
  .list-group {
    min-width: 166px;
  }
}
