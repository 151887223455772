//.md-form {
//   &.input-group {
//     margin-bottom: auto;// override default
//   }
//}

.no-user-select{
  user-select: none;
}

.col.form-inline {
  .md-form.input-group {
    width: 100%
  }
}

import-form-component ul
{
  padding-left: 1rem;

  li{
    list-style-type: none;
  }
}

.no-spinners {
  -moz-appearance:textfield;
}

.form-group select{
  max-width: 100%;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control {
  padding-bottom: 0rem;
  border-radius: $border_radius_smallest;
}

.input-group .input-group-addon {
  background-color: $color_super-light-grey;
  color: $color_grey-icon-dark;
  font-size: 13px;
  cursor: default; // normal default system pointer
}

.form-content{
  margin-left:2rem;
  margin-right:2rem;
}

.required {
  &::after {
    content: '*';
    color: black;
  }
}

.dynamic-form {
  padding-bottom: 0rem;
}

.form-group {
  padding: 0;
  padding-bottom: 10px;
}

.form-group-label-container {
  margin-bottom: 0px; //20px;
  display: flex;
}

form-group-component {
  .tree-node-icon {
    background-color: $color_lighter-grey;
    margin-right: 1rem;
    color: $color_grey-icon-dark;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    border-radius: 3px;
  }

  //nested formgroup support
  form-group-component {
    form-group-component {
      .form-group {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .form-group-label {
        padding-top: 1.25rem;
        @extend .form-sub-title;
      }

      .form-group-label-container help-icon-component {
        padding-top: 1rem;
      }
    }
  }
}

.form-sub-title{
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    color: $color_text_darkgray;
  p{
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    color: $color_text_darkgray;
  }
}

.form-setting-option{
  font-size: .9375rem;
  font-weight: 300;
  cursor: pointer;
  padding-left: 1rem!important;
  padding-right: 2rem!important;
  padding-top: .25rem!important;
  padding-bottom: .25rem!important;
  width: 200px;
  &:hover{
    background-color: $selection-gray!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
  }
}

i.material-icons.form-label-icon {
  padding: 0 0.5rem 0 0.5rem; // top right bottom right
  font-size: 22px;
  vertical-align: middle; // this naturally aligns/centers it with the text combined with inline-block
  cursor: pointer;
  color: $color_text_darkgray;
}

.form-group-label {
  @extend h4;
  cursor: pointer;
  //margin-bottom: 20px;
}

textarea{
  &:disabled{
    background-color: $color_super_light_grey;
    cursor: not-allowed;
  }
}

form-input {
  .range-field {
    width: 100%;
  }

  input:disabled{
    cursor: not-allowed;
  }
}

input[type=range] {
  margin: 7px 0;
}

.range-value-feedback {
  font-size: 12px;
}

form-file {

  .progress {
    margin-top: 10px;
  }

  .hide-progress,
  .show-progress {
    //-webkit-transition: opacity 1s ease-in-out;
    //transition: opacity 1s ease-in-out;
  }

  .hide-progress {
    display: none;
  }
  .show-progress {
    display: block;
  }

  img {
    padding-bottom: 20px;
  }

  .disabled {
    color: gray;
    cursor: not-allowed !important; // for most users this is the regular old pointer
    text-decoration: none !important;
  }

  .icon-remove-file {
    margin: 0;
    position: relative;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
    //left: -22px;
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
    }
  }

  .no-left-padding {
    padding-left: 0;
  }

  .file-name {
    padding-top: 10px;
  }
}

.date-form-field {
  border: none;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 15px;
  &:disabled { // style specific for firefox disabled form field
    background-color: white;
  }

}

// Nicely style a form upload input
// @see https://stackoverflow.com/a/25825731
.custom-file-upload {

  cursor: pointer;
  width: 100%;

  //btn btn-primary btn-block waves-effect waves-light
  @extend .btn;
  @extend .btn-link;
  @extend .waves-effect;
  @extend .waves-light;

  // reset margin
  margin: 0;

  input[type=file] {
    display: none;
  }

}

.custom-file-upload-label + img {
  padding-top: 20px;
}

.form-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.form-graph-no-data{
  background-color: $color_super-light-grey;
  //line-height: 300px;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;

  display: table;
  width: 100%;

  margin-top:auto;
}

.collapsed-form{
  position: absolute;
  margin-left:-999999px;
}

.section-form-container{
  padding-bottom:40px;
}

.dynamic-form {

  select {
    display: block;
  }

  .btn-primary {
    background-color: $color_blue-darker;

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      cursor: $cursor-disabled;
      background-color: $gray-light;
      @include box-shadow(none);
      &:hover {
        background-color: $gray-light !important;
      }
    }

    &:focus,
    &:hover {
      background-color: $color_blue !important;
    }
  }

  .form-inline {
    label {
      align-items: left;
      justify-content: left;
    }
  }

  .dynamic-field {
    &.form-button {
      //margin-top: 35px;
    }
  }

  .button-bar-fixed-container{
    //background: red;

    padding-top: 4rem;
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: right;
  }

  .button-bar-fixed{
    position: absolute;
    display: flex;
    top: 0;
    z-index: 1000;
    padding: 0;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    right:0;
    background: white;
    padding-top: 1rem;
    left:0;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #dee2e6;
    padding-bottom: 1rem;
  }

  .form-label-part{
    align-self: center;
    width: 50%;

    @include media-breakpoint-down(lg) {
      width: 55%;
    }
    @include media-breakpoint-down(md) {
      width: 60%;
    }
  }

  .form-label-part-large{
    align-self: center;
    width: 55%;
  }

  .button-bar-fixed-button{
    width:auto;
    padding:0;
    margin:0;
  }

  inline-form-button{
    .btn{
      font-family: Roboto;
    }
  }

  .form-control {
    padding-bottom: 0;
    border: 0;
  }

  select.form-control {
    border: 1px solid $dropdown_border-color;
  }

  .custom-select {
    width: 100%;
  }

  .form-note {
    padding-bottom: 20px;
  }

  .alert {
    display: block;
    width: 100%;
  }

  .form-anchor {
    a {
      font-size: .7rem;
      text-align: left;
      padding: 0.4rem 1.6rem 0.4rem 10px;
      img {
        padding-right: 10px;
        //padding-left: 10px;
      }
      i {
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
        & + img {
          padding-right: 10px;
          padding-left: 0;
        }
      }
      span.anchor-span {
        vertical-align: middle;
      }
    }
    .btn-outline-form {
      @extend .btn-outline-primary;
      border: 2px solid $color_super-light-grey;
      &:hover {
        border-color: $color_grey;
        &.loading {
          border-color: $color_blue-dark;
        }
      }
      &:active,
      &.active {
        background-color: transparent;
        border-color: $color_blue-dark;
        &:hover {
          border-color: $color_blue-dark;
        }
      }
      &:focus {
        box-shadow: none;
      }
      i {
        color: $color_secondary-blue;
        font-size: 18px;
      }
    }
    .btn {
      box-shadow: none;
      border-radius: 0;
    }
    &.loading {

    }
  }
}

.user-profile-list-group-column {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.form-component-container,
.vertical-scroll-container {
  @extend .z-depth-1;

  overflow-x: hidden;
  overflow-y: auto;

  padding-top: 20px;

  background-color: white;
  border-radius: 3px;

}

.user-profile-form-component-container,
.user-profile-list-group-container
{
  // keep the repeated plain password field aligned with normal singular non repeated fields
  .form-group--plainPassword {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  // completely hide the plain password repeated field 'group' form group heading
  .form-group-label--plainPassword {
    display: none;
  }
}

.form-group--Nummer {
  margin-bottom: 0;
}

.user-profile-list-group-container,
.user-profile-form-component-container
{
  // center absolutely
  position: absolute;
  left: 5px;
  right: 5px;
  top: 20px;
  bottom: 20px;
}

.area-settings-form-component-container {
  padding-bottom: 20px;
}

.user-profile-list-group-container {
  bottom: auto; // automatic
}

.settings-container {
  padding-top: 20px;
  padding-bottom: 15px;
}

.centered-element-container.loading-form-container {

  .centered-element.loading-form {
    margin: 0;
    height: 50px;
    position: absolute;
    background-color: $color_white;
    border-radius: 2px;
    max-width: initial; // reset maximum width

    right: 25%;
    left: 33%;
    top: 35%;
    width: 33%;
  }
}

form-group-component #plainPassword{
  margin:0;
  padding: 0;
}

#mutationButtons form-button{
  padding-left:0.25rem;

  & i{
    //Bepaalt de grootte van de buttons
    padding:0.5rem;
  }
}

#mutationButtons .cancel-button-container{
  margin-right:auto;
  padding-left: 0;
  //position: absolute;
  //left: 0;
  .cancel-button{
    &:hover{
    }
  }
}

#mutationButtons .back-button-container {
  padding-right: 0.25rem
}

#mutationButtons .default-button-container:first-of-type{
    margin-left:auto;
}

default-form-component,
import-component,
export-component
{

  h3 {
    padding-bottom: 0.5rem;
  }

  h3,
  .form-group-label-container
  {
    width: 100%;
    color: $color_blue-darker;
  }

  h3,
  .dynamic-field,
  .form-note,
  .form-group
  {
    @extend .form-padding;
  }
}

device-management-component {
  .dynamic-form {
    .form-group,
    .dynamic-field
    {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

user-settings-component {
  .form-label-icon-container {
    display: inline; // override default div behavior
  }
  i.material-icons.form-label-icon {
    padding-left: 0;
  }
}

.form-section-collapse-button{
  bottom: 0;
  position: absolute;
  width: 40px;
  cursor: pointer;
  background-color: transparent;
  &:hover{
    background-color: transparent !important;
  }
}

.form-section-component-container{
  padding: 0;
  width: 40px;
  float: left;
}

.line-graph-date-row{
  display: flex;
  align-items: center;
  font-size: 0.8rem;

    //padding-top: 0.5rem;
   // padding-left: 0.5rem;
   //padding-right: 0.5rem;
}

.line-graph-date-column{
  display: block;
  font-size: 0.8rem;
}

.date-row-left{
  padding-left:0;
  padding-right:0.25rem;
}

.btn-blue-darker {
  background-color: $color_blue-darker;
}

.date-row-right{
  padding-left:0.25rem !important;
}

.date-row-right .mydp .selector{
  margin-left: -7.5rem;

  &:before{
    margin-left:11rem;
  }

  &:after{
    margin-left:11.05rem;
  }
}

.form-group-label--baseObjectList {
  font-size: 15px;
}

.form-group.form-group--baseObjectList {
  .form-group-label-container {
    margin-bottom: 0;
  }
}

.failed-validation {
  background: $color_widget-grey;
  color: white;
}

.validation-warning {
  background: $color_lighter-grey;margin-top: 1rem;padding: 0.5rem;text-align: left;max-width: 100%;
}

form-text-area {

  textarea {
    height: 5rem;
    resize: none;
    border: none;
    background-color: white;
    border: 1px solid $color_grey-input-border;
    font-size: 0.8rem;
    &:focus {
      outline:none;
    }
  }

  form-component-label{
    margin-bottom: auto;
  }

  .form-text-area-counter{
    font-size: 10px;
  }
}

.form-section-counter {
  //line-height: 16px; //Chrome on windows fix
  position: absolute;
  background-color: $color_darker-grey;
  border: 1px solid $color_white;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  bottom: 3px;
  right: 3px;
  font-size: 10px;
  text-align: center;
  color: $color_white;
  font-weight: bold;
}

.form-link-anchor{
  color: $color_secondary-blue;
  text-decoration: underline;
  cursor:pointer;
  &:hover{
    color:$color_blue-dark;
    text-decoration: underline;
    cursor:pointer;
  }
  &.disabled{
    cursor: not-allowed;
    color: grey;
  }
}

a:not([href]):not([tabindex]).form-link-anchor-force-color{
  color: $color_secondary-blue;
  &:hover{
    color:$color_blue-dark;
    text-decoration: underline;
    cursor:pointer;
  }
  &.disabled{
    cursor: not-allowed;
    color: grey;
  }
}

// Downloading class

.form-link-downloading{
  color: $color_black;
  text-decoration: none;
  font-style: italic;
  &:hover{
    color: $color_black;
    text-decoration: none;
    cursor: default;
  }
  &:focus{
    color: $color_black;
    cursor: default;
  }
}

.contentDiv{
  overflow: auto;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/*.mydp{
  .icon-mydpcalendar{
    &::before{
     content: '\e803';
      color:purple;
    }
  }
}*/

.form-read-only-label{
  font-size: 15px;
    padding-top: 0.35rem;
    padding-bottom: 0.25rem;
}

.ie11-date{
  position: absolute;
  right: -5.5px;
  top: 10%;
  z-index: 1;
  margin: 0;
  padding: 0;
  pointer-events: none;
  font-size: 22px!important;
  &.clearBtn{
    right: 22px;
    top: 14%;
    font-size: 18px!important;
  }
}

.position-relative{
  position: relative;
}

global-popup-table-options-component {
  h2{
    padding-top: 1rem;
  }
  .filter-block{
    border: 1px solid $color_grey-border-light;
    border-radius: 3px;
    padding: 0.5rem;
    padding-top: 0.05rem;
    padding-bottom: 0;
    background-color: $color_white;
  }

  .table-options-settings-container{
    background-color: $color_grey-body-background;
    margin-top: 1rem;
    padding: 1rem;
    //padding-bottom:0.75rem;
  }
}

.disableDiv{
  color: darkgray;
  a{
    cursor: not-allowed !important; // for most users this is the regular old pointer
    text-decoration: none !important;
  }
  &:hover{
    color: darkgray;
  }
}

.scanner-scanresult
{
  font-size: 2rem;
  background-color: $color_text_darkgray;
  color: white;
  text-align: center;
  word-break: break-all;
  padding-left:1rem;
  padding-right:1rem;
}

form-select{
  select {
    // Edge fix
    min-width: 1px;
  }
  .ie-truncate-fix{
    display: inline-flex !important;
  }
}

global-popup-scanner-component {
  zxing-scanner {
    display: flex !important;
    justify-content: center;
    background-color: $color_text_darkgray;

    video{
      // max-height fix for camera's in portrait mode who otherwise stretch the popupup up too much
      // Doesn't work perfectly in IE, but the scanner isn't enabled for IE anyway
      max-height: calc(100vh - 200px)!important;
      width:auto !important;
      max-width: 100%;
    }
  }
}

.multi-input{
  .form-label-part{
    width: 50%;
  }

  .input-container{
    .w-50{
      width: 48%!important;
    }
    width: 100%;
    display: inline-block;
    input{
      font-size: 14px;
      //text-align: center;
    }
    .form-read-only-label{
      color: rgba(0,0,0,.46);
      border-bottom: 1px dotted rgba(0,0,0,.46);
      background-color: transparent;
      font-size: 14px;
      padding-bottom: .4rem;
    }
  }
}

.time-picker{
  width: calc(50% - 6px)!important;
  padding-left: 6px!important;
  border: 1px solid $color_grey!important;
  border-radius: 4px!important;
  font-size: 14px!important;
  height: 34px!important;
  color: $color_grey-icon-dark !important;
  font-family: Arial,Helvetica,sans-serif!important;
  &.mobile{
    width: calc(100% - 6px)!important;
  }
}

.selected-graph{
  background-color: $color_light-blue!important;
}

.ng servetext-truncate{
  &.tabs{
    display: block;
    padding-top: 0.3rem;
  }
}

.checkmark-div{
  position: relative;
  display: flex;
  height: 18px;
  width: 18px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: $color_light-grey;
  border-radius: 5px;
  cursor: pointer;
}

.div-unchecked{
  background-color: $color_light-grey;
}

.div-checked{
  background-color: $color_secondary-blue;
  .check{
    transform: rotate(45deg);
    height: 12px;
    width: 6px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    margin-left: 6px;
    opacity: 1 !important;
    z-index: 10 !important;
    top: 2px;
  }
}

// TODO: kleuren kunnen vast efficienter en netter
.time-tracking{
  button{
    box-shadow: none;
    border-radius: 0;
    background-color: $color_time-tracking-yellow !important;

    &:disabled{
      background-color: $color_darker-grey !important;
    }
    &:hover{
      &:disabled{
        background-color: $color_darker-grey !important;
      }
      box-shadow: none;
      background-color: $color_time-tracking-yellow !important;
    }
  }
  :first-child{
    button{
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      background-color: $color_secondary-blue-hover !important;

      &:disabled{
        background-color: $color_darker-grey !important;
      }
      &:hover{
        box-shadow: none;
        background-color: $color_secondary-blue-hover !important;
        &:disabled{
          background-color: $color_darker-grey !important;
        }
      }
    }
  }
  :last-child{
    button{
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      background-color: $color_time-tracking-red !important;

      &:disabled{
        background-color: $color_darker-grey !important;
      }
      &:hover{
        &:disabled{
          background-color: $color_darker-grey !important;
        }
        background-color: $color_time-tracking-red !important;
      }
    }
  }
}

.two-factor-auth{
  margin: 0 0.2rem !important;
  text-align: center!important;
  line-height: 30px!important;
  font-size: 1.5rem !important;
  border: solid 1px $color_grey!important;
  box-shadow: 0 0 5px $color_grey inset!important;
  outline: none!important;
  width: 10%!important;
  transition: all .1s ease-in-out!important;
  border-radius: 3px!important;
  box-sizing: initial;
  &:focus {
    border-color: $color_secondary-blue-hover !important;
    box-shadow: 0 0 5px $color_secondary-blue-hover inset!important;
  }
  &::selection {
    background: transparent!important;
  }
}

.two-factor-auth-container div[data-lastpass-icon-root="true"] {
  opacity: 0;
}
.two-factor-auth-container div[data-lastpass-infield="true"] {
  opacity: 0;
}

.datetime-input.border-color-grey-input{
  border:1px solid;
}
  .datetime-input input{
    border:0;
    width:100%;
    height:100%;
  }
    .datetime-input input:disabled{
      background-color:#FFF;
      color:#757575;
    }
