.column-selector-table {
  @include unselectable-content;
  @extend .table-responsive;
  margin-top: 1rem;
  padding-bottom: 4rem;

  .cst-table {
    @extend .table;

    thead {
      background: $color_widget-grey;
      top: -1px;
    }

    .cst-header {
      color: white;
      th {
        @extend .text-truncate;
        @media (max-width: $mobile-breakpoint-width) {
          min-width: unset !important;
        }
        z-index: 5 !important;
      }
    }

    .cst-dragging {
      background: $color_grey-body-background;
      border: 1px solid $color_light-grey;
      z-index: 999;
    }

    .cst-row-placeholder {
      background-color: $color_super-light-grey;
      border: 2px dashed $color_grey;
    }

    .cst-row-normal {
      transition: background-color 0.6s linear;
      td {
        min-width: 0!important;
        vertical-align: middle;
        padding: 0.1rem;
        padding-left: 0.5rem;
      }

      .cst-bold-column {
        font-weight: bold;
      }
    }

    .cst-row-highlighted {
      @extend .cst-row-normal;
      background-color: $color_lightest-blue;
      transition: unset;
    }

    .cst-filter-block {
      border: 1px solid $color_grey-border-light;
      border-radius: 3px;
      padding: 0.5rem;
      padding-top: 0;
      padding-bottom: 0;
      background-color: $color_white;
    }

    .cst-rank-column {
      display: inline-flex;
    }

    .cst-drag-button {
      //touch-action: none;
      margin: auto;
      cursor: pointer;
      background-color: $color_lighter-grey;
      border: solid 1px $color_light-grey;
      width: 1rem;
      height: 3rem;
      display: inline-flex;

      i {
        font-size: 28px;
        margin-left: -7px;
        padding: 0 !important;
        //transform: scale(1,1.2);
        @extend .material-icons, .md-dark;
      }
    }

    .cst-up-down-buttons {
      margin: auto;
    }

    .cst-icon-button-small {
      @extend .p-0, .pr-2, .mr-4, .mt-3, .btn, .d-flex, .align-items-center;

      background-color: $color_lighter-grey;
      display: block;
      width: 2rem;
      height: 1rem;
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
      margin-right: 0.5rem !important;

      i {
        @extend .material-icons, .md-dark;
        color: rgba(0, 0, 0, 0.54);
        font-size: 20px;
        margin-left: -0.15rem;
      }
    }

    .cst-map-check-div-unchecked{
      display: flex;
      height: 18px;
      width: 18px;
      margin-left: 1rem;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      background-color: $color_light-grey;
      border-radius: 5px;
      cursor: pointer;
    }

    .cst-map-check-div-checked {
      @extend .cst-map-check-div-unchecked;
      background-color: $color_secondary-blue;

      .cst-map-check{
        position: relative;
        transform: rotate(45deg);
        height: 12px;
        width: 6px;
        border-bottom: 2px solid white;
        border-right: 2px solid white;
        margin-left: 6px;
        top: 2px;
      }
    }
  }

  .cst-unselectable {
    @include unselectable-content;
  }
}
