.map-drawing-manager{
  .controls-container{
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0,0,0,.3);
    text-align: center;
    padding:5px;
    button{
      color:#6b6b6b;
      cursor: pointer;
      font-family: $font-family-sans-serif;
      font-size:14px;
      border:0;
      border-right:1px solid #f0f0f0;
      background-color: #fff;
      &.map-drawing-button{
        padding:0 5px;
        &.main-item{
          background:#f0f0f0;
        }
        &.active{
          background:#91c6ff;
          color:$color_text_darkest-gray;
        }
        &:hover{
          color:$color_text_darkest-gray;
        }
        .nolink{
          .material-icons{
            padding-right:0;
          }
          .dropdown-arrow{
            padding:0 2px 0 0;
          }
        }
      }
      &.single-button{
        padding:8px 5px !important;
        border-radius:0 !important;
        box-shadow: none !important;
        &:hover, &:focus, &:active, &:focus-visible, &:focus-within, &.active{
          color:$color_text_darkest-gray !important;
          background-color:#91c6ff !important;
        }
      }
      &.last-child{
        border-right:0;
      }
      &:disabled{
        cursor: default;
        color:#dddddd !important;
      }
    }
    .dropdown-menu.drawing-manager{
      width:20rem;
      padding:0.5rem;
      button{
        border:0;
      }
      .dropdown-item-options button{
        padding:0;
        box-shadow:none;
        .material-icons{
          font-size:14px;
        }
      }
      .label{
        color:#6b6b6b;
        font-family: $font-family-sans-serif;
        font-size:14px;
        border-bottom:1px solid #f0f0f0;
        padding-bottom:10px;
      }
      .dropdown-item-container{
        padding:2px 5px;
        font-size: 14px;
        color:#6b6b6b;
        font-family: $font-family-sans-serif;
        &:hover {
          background-color: $selection-gray;
          button:not(.active){
            background-color: $selection-gray;
          }
        }
        button{
          margin:0 1px;
          border-radius:3px;
          transition: none;
          &.active{
            box-shadow:none;
            background-color:#91c6ff;
            color:#323232;
          }
          &:hover{
            box-shadow:none;
            color:#323232 !important;
          }
        }
      }
      .drawing-selection-container{
        position: absolute;
        display:none;
        height:100%;
        width:100%;
        overflow: auto;
        background:#FFF !important;
        .block{
          width:auto;
          display: inline-block;
          background:#ababab;
          border-radius: 3px;
          padding:2px 3px;
          margin:3px 1px;
          cursor: pointer;
          font-size:12px;
          &:hover{
            background:#dcdcdc;
          }
          &.label-item{
            cursor: default;
            background:#FFFFFF;
            padding:5px 0 0;
            border-radius:0;
            width:18.1rem;
            font-weight:bold;
            border-top:1px solid #ababab;
          }
        }
        &.active{
          display:block;
        }
      }
      &.drawing-manager-container{
        overflow:hidden;
      }
      .drawing-selection-controls{
        &:hover{
          background:#FFF !important;
        }
        .drawing-selection-container-selected-set-outer{
          width:49%;
          display:inline-block;
          padding:0;
          &:hover{
            background:#FFF !important;
          }
          .drawing-selection-container-selected-set{
            text-align: left;
            padding:0;
            &:hover{
              background:#FFF !important;
            }
            span{
              padding:2px 4px;
              background:#91c6ff;
              font-size:12px;
              border-radius:3px;
              cursor: pointer;
              &:hover{
                background:#cbe3fd;
              }
            }
          }
        }
        .drawing-selection-container-close{
          width:49%;
          display:inline-block;
          text-align: right;
          .material-icons{
            font-size:12px;
            cursor: pointer;
            padding:0;
            margin:0;
          }
          &:hover{
            background:#FFF !important;
          }
        }
      }

    }
  }
  .dropdown, .dropup, .info-item{
    display:inline-block;
  }
  .main-controls-container{
    margin-top: 10px;
  }
  .drawing-controls-container, .mapitem-edit-controls-container{
    margin-bottom: 20px;
    display: none;
    &.visible{
      display: block;
    }
    button{
      padding:0 10px 0 5px;
      &.active{
        box-shadow:none;
        background-color:#91c6ff;
        color:#323232;
      }
      &:last-child{
        padding-right:5px;
      }
      &:hover{
        color:$color_text_darkest-gray;
      }
    }
  }
  .rotate-90{
    rotate: 90deg;
  }
  .rotate-180{
    rotate: 180deg;
  }
  .info-item{
    border-right:1px solid #f0f0f0;
    padding:8px 5px !important;
    width:80px;
    height:40px;
    background:#f0f0f0;
    position: relative;
    vertical-align: bottom;
    .material-icons, .info-value{
      position: absolute;
      top:0;
      left:0;
    }
    .info-value-inner-newline-icon{
      margin-left:28px;
      margin-top:18px;
      padding:0;
      transform:scale(1,2);
    }
    .info-value{
      width:80px;
      text-align: center;
      margin-top:8px;
      .info-value-inner{
        outline:0;
      }
    }
    .remove-fixed-length{
      position: absolute;
      cursor: pointer;
      right:0;
      top:0;
      margin-right:25px;
      margin-top:-5px;
      z-index:2;
      display:none;
      .material-icons{
        font-size:16px;
      }
      &.visible{
        display: block;
      }
    }
    &.info-item-long{
      width:160px;
      .info-value{
        width:144px;
        margin-left:8px;
        &.text-left{
          text-align: left;
        }
      }
    }
  }
  .combined-icon{
    position: relative;
    .material-icons:nth-child(2){
      position:absolute;
      right:0;
      margin-right:-10px;
      font-size:16px;
    }
  }
  .material-icons.make-round{
    background-color:#0F0F0F;
    border-radius:20px;
    padding:4px;
    color:#FFF;
  }
  .hide-control{
    display:none !important;
  }
}
.zoom-prefix-container{
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
  border-radius: 2px;
  background-color: #FFFFFF;
  width: 40px;
  margin-right:10px;
  .prefix-zoom-button{
    padding:0 4px;
    .map-drawing-button{
      background: none;
      display: block;
      border: 0;
      margin: 0;
      padding: 5px 0 0;
      text-transform: none;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      width: 32px;
      height: 36px;
      top: 0;
      left: 0;
      border-top: 1px solid rgb(230, 230, 230);
      color:#888888;
      &:hover{
        color:#5b5b5b;
      }
    }
    &:nth-child(1){
      .map-drawing-button{
        border-top:0;
      }
    }
  }
}
//Hide close buton for annotations
.gm-style-iw[aria-label="mapitem-annotation"]{
  box-shadow:none;
  border-radius:3px;
  border:1px solid #8bbeef;
  margin-top:11px;
  button {
    display: none !important;
  }
}
.mapitem-annotation-container{
  .annotation-cable-item{
    &:hover{
      cursor: pointer;
      text-decoration: underline;
      color:#6b6b6b;
    }
    &.selected{
      font-weight: bold;
    }
  }
}
.gm-style-iw-t:has(.gm-style-iw[aria-label="mapitem-annotation"]){
  .gm-style-iw-tc{
    display:none !important;
  }
}