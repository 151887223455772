.reader-container {
  .form-component-container{
    max-width: 1024px;
  }
  .centered-element-container {
    display: table;
    .centered-element {
      max-width: 100%;
      margin: 0;
      height: 100%;
      position: absolute;
      background-color: $color_white;
      border-radius: 3px;

      // mobile first, this does not need include media breakpoints mixin
      top: 0px;
      bottom: 25%;
      width: 100%;

      // on large screens we change the positioning
      @include media-breakpoint-up(lg) {
        right: 25%;
        bottom: 25%;
        left: 25%;
        width: 50%;
      }
    }
  }
}

.manual{
  .reader-container {
    .centered-element-container {
      .centered-element {
        top: 1rem;
      }
    }
  }
}

.reader {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  background-color: white;
  padding: 1.5rem 1rem 1.5rem 1rem;
  margin-top: 66px;

  ul {
    list-style: none;
    padding-bottom: 10px;
    padding-left: 0.5rem;

    li {
      padding-left: 0.8em;
      text-indent: -1.2em;
      line-height: 1.9;
      font-weight: 300;

      &::before {
        vertical-align: middle;
        content: "• ";
        color: $luminext-orange;
        padding-right: 0.5rem;
      }

    }
  }

  p {
    font-size: 16px;
    margin-bottom: 15px;

    & + h3 {
      margin-top: 20px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: $color_blue-darker;
  }

  h1 {
    margin-bottom: 18px;
  }

  h6 {
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 18px; //make it slightly larger than the default font size for h6's
  }

  h6 + ul {
      margin-top: 10px;
  }

  a {
    color: $color_blue-darker;
  }

  .breadcrumb a {
    color: $color_blue-darker !important;
    text-decoration: none;
  }


}

.support-container {
  h1, p {
    text-align: center;
  }
}

release-notes-component .release-notes-container {
  ul li {
    text-indent: -1.05rem;
    line-height: 1.5rem;
    font-size: 14px;
    //padding-bottom: 10px;

    ul {
      padding-bottom: 0;
      padding-left: 1rem;
    }
  }

    a{
      text-decoration: underline;
      color: $color_secondary-blue;
      &:hover{
        color: $color_blue-dark;
      }
    }

    .release-notes-icon{
      font-size: 16px;
      top: 3px;
      position: relative;
    }
}

.manual-group{
  h5{
    margin-bottom: 0;
  }
  p{
    font-size: 14px;
    margin-bottom: 20px;
  }
  ul{
    padding-left: 40px;
    padding-bottom: 0px;
    li{
      font-size: 14px;
      line-height: normal;
    }
  }
  .manual-group-item{
    margin-bottom: 6em;
  }
}

.key-features{
  margin: 0 -16px;
  margin-bottom: 3rem;
  .content{
    background-color: $color_grey-body-background;
    margin-top: -7px;
    h1{
      text-align: center;
    }
    i{
      font-size: 3em;
      vertical-align: middle;
      padding-left: 0px;
    }
    .flip-y{
      transform: scaleY(-1);
    }
  }
}

.manual-connectors{
  table-layout: fixed;
  width: 75%;
  .row-header{
    text-align: left;
    font-weight: bold;
  }

  p{
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 0;
  }

  th{
    border: 1px solid black;
    text-align: center;
    padding: 0.25rem;
    font-style: italic;
  }

  td{
    border: 1px solid black;
    border-bottom: 1px solid $color_manual-border;
    text-align: center;
  }

  .material-icons{
    font-size: 12px;
  }
}

.manual-support{
  table-layout: fixed;
  width: 75%;
  .row-header{
    text-align: left;
    font-weight: bold;
  }

  tr{
    &.last-row{
      td{
        border-bottom: 1px solid $color_grey-border-light;
      }
    }
  }

  p{
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 0;
  }

  th{
    border: 1px solid $color_grey-border-light;
    text-align: -webkit-center;
    padding: 0.25rem;
    font-style: italic;
  }

  td{
    border: 1px solid $color_grey-border-light;
    border-bottom: 1px solid $color_manual-border;
    text-align: center;
  }

  .material-icons{
    font-size: 12px;
  }

  img{
    &.img-fluid{
      max-width: 35%!important;
    }
  }
}

.module-text-section-item-child{
  &.active{
    background-color: $color_light-blue!important;//VAN DE GESELECTEERDE TREE ITEM GENAKT
    border-color: $color_light-blue!important;
  }
}

.manual{
  .user-profile-list-group-column{
    position: absolute;
    left: 2.5%;
    width: 20%;
  }
}

// DE BREEDTE VAN ALLE READER PAGINAS HETZELFDE MAKEN. MET EEN MAX WIDTH ZODAT ALLE PLAATJES OP 100 OF 50 KUNNEN.
.old-manual{
  background-color: $color_grey-body-background;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 2rem;
  margin-top: -20px;
  padding: 1rem;
  border-radius:3px;

  a{
    color: $color_secondary-blue!important;
    text-decoration: underline!important;
    cursor: pointer!important;
  }
}

.wave {
  background: url("../../assets/img/manual/wave.png");
  width: 100%;
  height: 143px;
  bottom: 0;
  left: 0;
  animation: wave 30s linear infinite;
}

@keyframes wave {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1360px;
  }
}
