.map-icon-picker-option-container{
  background-color: white;
  width: 35px;
  height: 30px;

  &:hover{
    background-color: #ececec;
  }
}
.map-icon-picker-selected-option-container{
  background-color: white;
  width: 35px;
  height: 30px;
}

/* Google maps zoom controls */
.gm-control-active img {
  filter:invert(38%) sepia(0%) saturate(464%) hue-rotate(287deg) brightness(94%) contrast(87%) !important;
}
.gm-control-active:hover img {
  filter:invert(75%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%) !important;
}
