
@font-face {
    font-family: 'Raleway';
    src: url(Raleway/Raleway-200.eot);
    src: url(Raleway/Raleway-200.eot?#iefix) format('embedded-opentype'),
         url(Raleway/Raleway-200.woff) format('woff'),
         url(Raleway/Raleway-200.ttf) format('truetype'),
         url(Raleway/Raleway-200.svg#Raleway) format('svg');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url(Raleway/Raleway-400.eot);
    src: url(Raleway/Raleway-400.eot?#iefix) format('embedded-opentype'),
         url(Raleway/Raleway-400.woff) format('woff'),
         url(Raleway/Raleway-400.ttf) format('truetype'),
         url(Raleway/Raleway-400.svg#Raleway) format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url(Raleway/Raleway-700.eot);
    src: url(Raleway/Raleway-700.eot?#iefix) format('embedded-opentype'),
         url(Raleway/Raleway-700.woff) format('woff'),
         url(Raleway/Raleway-700.ttf) format('truetype'),
         url(Raleway/Raleway-700.svg#Raleway) format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url(Raleway/Raleway-200-Italic.eot);
    src: url(Raleway/Raleway-200-Italic.eot?#iefix) format('embedded-opentype'),
         url(Raleway/Raleway-200-Italic.woff) format('woff'),
         url(Raleway/Raleway-200-Italic.ttf) format('truetype'),
         url(Raleway/Raleway-200-Italic.svg#Raleway) format('svg');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: url(Raleway/Raleway-400-Italic.eot);
    src: url(Raleway/Raleway-400-Italic.eot?#iefix) format('embedded-opentype'),
         url(Raleway/Raleway-400-Italic.woff) format('woff'),
         url(Raleway/Raleway-400-Italic.ttf) format('truetype'),
         url(Raleway/Raleway-400-Italic.svg#Raleway) format('svg');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: url(Raleway/Raleway-700-Italic.eot);
    src: url(Raleway/Raleway-700-Italic.eot?#iefix) format('embedded-opentype'),
         url(Raleway/Raleway-700-Italic.woff) format('woff'),
         url(Raleway/Raleway-700-Italic.ttf) format('truetype'),
         url(Raleway/Raleway-700-Italic.svg#Raleway) format('svg');
    font-weight: 700;
    font-style: italic;
}


@font-face {
    font-family: 'Roboto';
    src: url(Roboto/Roboto-100-Lighter.eot);
    src: url(Roboto/Roboto-100-Lighter.eot?#iefix) format('embedded-opentype'),
         url(Roboto/Roboto-100-Lighter.woff) format('woff'),
         url(Roboto/Roboto-100-Lighter.ttf) format('truetype'),
         url(Roboto/Roboto-100-Lighter.svg#Roboto) format('svg');
    font-weight: 100;
    font-style: lighter;
}
@font-face {
    font-family: 'Roboto';
    src: url(Roboto/Roboto-200.eot);
    src: url(Roboto/Roboto-200.eot?#iefix) format('embedded-opentype'),
         url(Roboto/Roboto-200.woff) format('woff'),
         url(Roboto/Roboto-200.ttf) format('truetype'),
         url(Roboto/Roboto-200.svg#Roboto) format('svg');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url(Roboto/Roboto-400.eot);
    src: url(Roboto/Roboto-400.eot?#iefix) format('embedded-opentype'),
         url(Roboto/Roboto-400.woff) format('woff'),
         url(Roboto/Roboto-400.ttf) format('truetype'),
         url(Roboto/Roboto-400.svg#Roboto) format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url(Roboto/Roboto-500.eot);
    src: url(Roboto/Roboto-500.eot?#iefix) format('embedded-opentype'),
         url(Roboto/Roboto-500.woff) format('woff'),
         url(Roboto/Roboto-500.ttf) format('truetype'),
         url(Roboto/Roboto-500.svg#Roboto) format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url(Roboto/Roboto-700.eot);
    src: url(Roboto/Roboto-700.eot?#iefix) format('embedded-opentype'),
         url(Roboto/Roboto-700.woff) format('woff'),
         url(Roboto/Roboto-700.ttf) format('truetype'),
         url(Roboto/Roboto-700.svg#Roboto) format('svg');
    font-weight: 700;
    font-style: normal;
}

/* oude icon font */
/*@font-face {*/
/*    font-family: 'Material_Icons';*/
/*    src: url(Material_Icons/Material_Icons-400.eot);*/
/*    src: url(Material_Icons/Material_Icons-400.eot?#iefix) format('embedded-opentype'),*/
/*         url(Material_Icons/Material_Icons-400.woff) format('woff'),*/
/*         url(Material_Icons/Material_Icons-400.ttf) format('truetype'),*/
/*         url(Material_Icons/Material_Icons-400.svg#Material_Icons) format('svg');*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/

@font-face {
    font-family: 'Material_Icons';
    font-style: normal;
    font-weight: 400;
    src:local('Material_Icons'),
    local('MaterialIcons-Regular'),
    url(Material_Icons/MaterialIcons-Regular.ttf) format('truetype'),
    url(Material_Icons/MaterialIconsOutlined-Regular.otf) format('opentype'),
    url(Material_Icons/MaterialIconsRound-Regular.otf) format('opentype'),
    url(Material_Icons/MaterialIconsSharp-Regular.otf) format('opentype'),
    url(Material_Icons/MaterialIconsTwoTone-Regular.otf) format('opentype');
}

