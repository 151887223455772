@import "variables";
@import "grid";
@import "type";
@import "buttons";
@import "images";
@import "forms";
@import "utilities/work-break";
@import "utilities/overflow";
@import "utilities/div-styles";
@import "mixins/unselectable-content";
//@import "luminizer/utilities";
@import "components/map";
@import "loading";
@import "footer";
@import "login";
@import "forbidden";
@import "icons";
@import "widgets";
@import "dashboard";
@import "map";
@import "menu";
@import "alert";
@import "dropdown";
@import "checkbox";
@import "password-forget";
@import "password-generator";
@import "reader";
@import "tree";
@import "device-management";
@import "tab-bar";
@import "map-table";
@import "table";
@import "streetview";
@import "list-groups";
@import "mobile";
@import "simple-tooltip";
@import "module";
@import "dimming";
@import "logs";
@import "analysis-report";
@import "tiles";
@import "commonUI";
@import "status-card";
@import "notifications";
@import "drawing-manager";
@import "print";
@import "../../app/wrapper/global-alert/alerts/global-popup-create-map-items/global-popup-create-map-items.component";
@import "../../app/shared/components/check-activities/check-activities.component";
@import "../../app/shared/components/check-activities/check-activity/activity-status-icon/activity-status-icon.component";
@import "../../app/shared/components/check-activities/check-activity/check-activity.component";
@import "../../app/shared/components/check-activities/progress-bar/progress-bar.component";
@import "../../app/shared/components/table/shared/baseTable/table-cell/table-cell.component";
@import "../../app/shared/components/table/shared/baseTable/table-cell/check-activity-cell-child/check-activity-cell-child.component";
@import "../../app/shared/components/table/tableColumnSelector/tableColumnSelector";
@import "../../app/shared/components/status/status-progress/status-progress.component";
@import "../../app/shared/components/status/status-button/status-button.component";
@import "../../app/shared/components/status/sub-statuses/sub-statuses.component";
@import "../../app/shared/components/file/file-selector/file-selector.component";
@import "../../app/shared/components/file/file-manager/file-manager.component";
@import "../../app/shared/components/file/file-manager/file-manager-folder/file-manager-folder.component";
@import "../../app/shared/components/map/map-item/map-item-importer/map-item-importer.component";
@import "../../app/shared/components/form/components/form-button/form-button.component";
@import "../../app/modules/calamity-control/calamity-control.component";
@import "../../app/modules/calamity-control/control-group/control-group.component";
@import "../../app/shared/components/map-icon-selector/map-icon-selector/map-icon-selector.component";
@import "../../app/shared/components/commonUI/dropdown/menu-dropdown/menu-dropdown.component";
@import "../../app/shared/components/form/components/form-timeline/timeline/timeline.component";
@import "ng-pick-datetime-ex";
@import '@angular/cdk/a11y-prebuilt.css';

@import "general-overrides";

html {
  position: relative;
  height: 100%;
}

body {
  height: 100%;
  background: $color_grey-body-background;
}

body.env-warn{
  border-top:20px solid #d40000;
}
  body.env-warn::before{
    content:"";
    position:absolute;
    color:#FFF;
    top:2px;
    right:5px;
    font-size:12px;
    width:100%;
    text-align:right;
  }
  body.env-warn div.contentDiv{
    margin-top:20px;
  }

body.env-local.lang-nl::before{
  content:"LOKAAL";
}
body.env-local.lang-en::before, body.env-local.lang-de::before{
  content:"LOCAL";
}
body.env-dev.lang-nl::before{
  content:"ONTWIKKEL";
}
body.env-dev.lang-en::before, body.env-dev.lang-de::before{
  content:"DEVELOPMENT";
}
body.env-test.lang-nl::before{
  content:"TEST";
}
body.env-test.lang-en::before, body.env-test.lang-de::before{
  content:"TESTING";
}
body.env-staging.lang-nl::before{
  content:"ACCEPTATIE";
}
body.env-staging.lang-en::before, body.env-staging.lang-de::before{
  content:"STAGING";
}

a {
  cursor:pointer;
}

p{
  @extend .light-font;
  margin-bottom:0rem;
}

h1{
  color: $color_text_darkgray;
  font-size: 2rem;
}

h2{
  color: $color_text_darkgray;
  font-size: 1.5rem;
}

h4{
  color: $color_text_darkgray;
}

.light-font{
  font-weight: 300;
  font-size: 14px;
}

.alert {
  border-radius: $border_radius_smallest;
  border: 0px;
}

//Google maps fix: tags - icon api zoom buttons
//Als je dit weglaat worden de zoom buttons fucked up zonder icoontjes (op een bepaalde versie van Google Maps)
//Misschien lost het probleem zichzelf op als google de api weer bijwerkt
//Meer: https://stackoverflow.com/questions/51833112/google-maps-api-not-showing-icons-for-zoom-buttons-streetview-or-fullscreen
//.gmaps * {box-sizing: content-box;}

.router-outlet {
  height: 100%;
}

.hidden{
  display: none;
}

.top-most{
  z-index: 99999;
}

.cursor-pointer{
  cursor: pointer;
}

.cursor-zoom-in{
  cursor: zoom-in;
}

.cursor-default{
  cursor: default !important;
}

.cursor-disabled{
  cursor: not-allowed;
}

.menu-logo {
  //margin-top: -0.3rem;
  vertical-align: top;
  max-width: 100%;

  @include media-breakpoint-up(xl) {
    width:100%;
    height:100%;
  }
}

.invisible{
  visibility: hidden;
}

.no-padding{
  padding:0;
}

.no-pointer-events{
  pointer-events: none;
}

//Nodig voor forms in popups zoals baseobject create form
//Zou kunnen dat dit ergens voor (visuele) problemen zorgt...
.tooltip {
  z-index:10000;
}

.has-white-space{
  white-space: pre-wrap;
}

input::-webkit-input-placeholder {
  font-weight: 200 !important;
}

input:-moz-placeholder { /* Firefox 18- */
  font-weight: 200 !important;
}

input::-moz-placeholder {  /* Firefox 19+ */
  font-weight: 200 !important;
}

input:-ms-input-placeholder {
  font-weight: 200 !important;
}

.unselectable-content{
  @include unselectable-content;
}

.break-long-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.super-highlight{
  outline: 10000px solid rgba(0, 0, 0, 0.55);
  z-index: 9999;
  //transition: outline-color 2s;
}

.overflow-scroll{
  overflow-y: scroll;
}

//This is a fix for the flashing googlechart-tooltips (especially pie charts), but it might be too aggressive.
//Use it as a fix for now, but remember it's here
//Only apply it to last child, or else the mouse input for the last item of the legend is disabled too
svg > g:last-child > g:last-child { pointer-events: none }


input{
  font-family:"Roboto", sans-serif;
  font-size:1rem;
  color:#292b2c;
}
