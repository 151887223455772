.lumi-button {
  @extend .btn, .btn-block, .align-items-center;

  display: inline-flex;
  justify-content: left;
  padding: 0.5rem;
  padding-left: 0;
  margin: 0.2rem;
  max-height: 3rem;

  span {
    padding-left: 0.5rem;
  }
}

.lumi-button-primary {
  @extend .lumi-button, .btn-primary, .waves-effect, .waves-light;

  background-color: $color_blue-darker;
}

.lumi-button-secondary {
  @extend .lumi-button;

  background-color: $color_secondary-blue;
  text-transform: none;
  &:hover,
  &:active,
  &:focus  {
    background-color: $color_secondary-blue-hover !important;
  }
}

.lumi-button-tertiary {
  @extend .lumi-button;

  color: unset !important;
  background-color: $color_lighter-grey;
  text-transform: none;

  &:disabled {
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)
    }
  }
}

.lumi-button-input {
  @extend .lumi-button;

  color: unset !important;
  background-color: transparent;
  text-transform: none;
  box-shadow: none!important;

  &:hover,
  &:active,
  &:focus {
    background-color: $color_light-grey;
  }
}

.lumi-button-icon {
  @extend .material-icons;

  padding: 0 !important;
  padding-left: 0.5rem !important;
}

.lumi-button-icon-primary {
  @extend .lumi-button-icon, .dynamic-icon-size;
}

.lumi-button-icon-secondary {
  @extend .lumi-button-icon, .dynamic-icon-size;
}

.lumi-button-icon-tertiary {
  @extend .lumi-button-icon, .md-dark, .dynamic-icon-size;
  color: $color_text_darkgray;
}

.lumi-button-icon-input {
  @extend .lumi-button-icon, .md-dark;
  font-size: 18px;
  color: $color_text_darkgray;
}

.lumi-button-icon-primary-small {
  @extend .lumi-button-icon;

  font-size: medium !important;
}

.lumi-button-icon-secondary-small {
  @extend .lumi-button-icon;

  font-size: medium !important;
}

.lumi-button-icon-tertiary-small {
  @extend .lumi-button-icon, .md-dark;

  font-size: medium !important;
  color: $color_text_darkgray;
}

.lumi-button-icon-input-small {
  @extend .lumi-button-icon, .md-dark;

  font-size: medium !important;
  color: $color_text_darkgray;
}
