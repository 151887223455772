.forbidden-icon{

  //float:right;
  //position: relative;
  /*width: 48px;*/
  //top: -5.25rem;  //TODO: deze waarde herleiden, niet zo invullen
  color: $brand-danger;
  //margin-right:-0.75rem
  //margin-top:-0.75rem;
  float:left;
}

.forbidden-form-container{
  margin-left: 2rem;
  margin-right: 2rem;
}
.forbidden-form {
  max-width: 35rem;
  //max-height: 40rem;
  padding: 1rem;
  margin: 0 auto;
  background-color: white;
  border-radius: $border_radius_large;

  /*font-weight: 300;
  font-size: 14px;*/

  @extend .light-font;
  @extend .z-depth-5;

  //box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.forbidden-content {
  //float: right;
  margin-left:1rem;
  display:inline-block;
}