.calamity-control-panel {
  @extend .white-bordered-z-depth-1;
  @media (max-width: $mobile-breakpoint-width) {
    width: 100%;
    margin: unset;
    min-width: unset;
  }
  position: relative;
  display: block;
  top: 55px;
  width: 50%;
  min-width: 768px;
  margin: auto;
  margin-top: 1rem;
  padding: 1rem;

  .ccp-header {
    display: flex;

    h3 {
      color: $color_blue-darker
    }

    .ccp-spinner-container {
      position: relative;
      height: 0;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .ccp-intro {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-overflow: clip;
    width: 100%;
  }

  .ccp-extra-info {
    font-size: small;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-overflow: clip;
    width: 100%;
  }

  .ccp-control-groups-container {
    @media (max-width: $mobile-breakpoint-width) {
      width: 100%;
      margin: unset;
      margin-bottom: 2rem;
    }
    display: block;
    width: 75%;
    margin: auto;
    margin-bottom: 2rem;

    hr {
      margin: 0;
    }
  }

  .ccp-map-container {
    @extend .card;

    .ccp-map-selector {
      margin: 1rem;
      margin-bottom: 0;
    }

    .ccp-map {
      @extend .widget-map-container;
      margin-bottom: 1rem;
    }
  }
}
