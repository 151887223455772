.progress-bar {
  height: 0.5rem;
  width: 100%;
  background-color: $color_light-grey;
  border-radius: 1rem;
  display: inline-flex;

  .pb-done {
    height: 100%;
    background-color: $color_status-done;
  }

  .pb-attention-done {
    height: 100%;
    background: orange;
  }

  .pb-attention-todo {
    height: 100%;
    background: repeating-linear-gradient(
                    -45deg,
                    orange,
                    orange 10px,
                    $color_light-grey 10px,
                    $color_light-grey 20px
    );
  }
}
