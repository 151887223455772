.streetview-panorama{
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.streetview-container{
  //margin-top:50%;
  width:100%;
  height: 50%;
  position: absolute;

  .btn:not(.btn-maximize){
    color: $color_streetsmart-green !important;
    border-radius: 0 !important;
    background-color: $color_streetsmart-darkest-grey !important;
    border: 1px solid $color_streetsmart-dark-green !important;
  }

  h4 button.btn{
    color:#333333 !important;
    background-color:#FFF !important;
    box-shadow:none !important;
    border:0 !important;
  }

  .cmt .cmt-navbar.navbar.navbar-default{
    background-color: $color_streetsmart-darkest-grey !important;
  }


  .cmt .fixed-panel .panel{
    //padding-top:30px;
  }

  .cmt .expandable-navbar .navbar-right .btn-group{
    display: flex!important;
  }

  .navbar{
    flex-direction: unset!important;
    display: flex!important;
    background-color: $color_streetsmart-darkest-grey !important;
    .btn{
      box-shadow:none !important;
    }
  }
  .cmtViewerPanel{
    max-height: 200px;
    overflow:auto;
  }
  .cmt{
    .expandable-navbar{
      display: flex!important;
    }
    .cmtNavBarPanel{
      position: relative!important;
      top:0!important;
      pointer-events: all;
    }
    .cmtImageInformationPanel{
      height: 100%;
    }
    .panel-body{
      height:100%;
      .fix-bootstrap-col{
        overflow-y: scroll;
    }
    }
  }

  .measurement-navbar{
    display: flex!important;
    padding-right:60px !important;
    .container-fluid{
      display: contents!important;
    }
    .nav{
      display: contents!important;
    }
    .navbar-brand{
      padding: 15px 0 0 5px!important;
    }
    .caret{
      display: none!important;
    }
    .btn{
      box-shadow: none !important;
    }
  }

  .floating-panel{
    position: absolute;
    overflow-y: scroll;
    height: 100%;
    bottom: 0;
    right: 0;
    width: 225px;
    &:before{
      content: 'Measure mode';
      margin-left: 25%;
      font-size: 18px;
      position: relative;
    }
  }

  .navbar-nav{
    flex-direction: unset!important;
    display: unset!important;
  }

  .nav{
    display: unset!important;
    flex-direction: unset!important;
  }

  .row{
    display: unset!important;
    flex-wrap: unset!important;
  }

  .cmt .window .timetravel .panorama{
    max-width: unset!important;
  }

  .cmt-navbar{
    .btn-group button{
      border:none!important;
    }
    .navbar-nav{
      button.btn{
        border:none!important;
        box-shadow: none!important;
        width: 35px!important;
        &:hover{
          border-bottom: 5px solid $color_blue-darker !important;
        }
      }
    }
  }
}

#cmt .bg-light{
  background-color: $color_streetsmart-darkest-grey !important;
}

#cmt .btn-group--overlay{
  padding-right:50px;
  .btn-close{
    display:none;
  }
}

.streetview-options-container {
  display:inline-block;
  color:$color_map-overlay-text-grey;
  cursor: pointer;
  @include unselectable-content;
}

.streetview-options-button{
  &.selected{
    color:$selection-blue;
  }
}
