.tile {
  cursor: pointer;
  margin: 0.5rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);;
  border-radius: 0.5rem;

  &:hover {
    box-shadow: 0px 2px 5px 2px rgb(0 0 0 / 30%);
  }

  .tile-top-part {
    position: relative;
    width: 100%;
    display: flex;

    .tile-status-icon-container {
      position: absolute;
      top: -0.5rem;
      left: 0.5rem;
      border-radius: $border_radius_small;
      padding: 0.5rem;
      line-height: 1;

      i {
        @extend .material-icons, .md-light;
        padding: 0;
        font-size: medium;
      }
    }

    .tile-title {
      position: absolute;
      width: 100%;
      top: 0.3rem;
      text-align: center;
      color: $color_grey-tile;
      font-weight: 300;
      font-size: 18px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .tile-menu-icon {
      @extend .material-icons, .md-dark;
      padding: 0;
      padding-right: 0.3rem;
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }

  .tile-bottom-part {
    display: flex;
    height: 10rem;

    p {
      overflow-y: scroll;
      width: 100%;
      margin-bottom: 1rem;
      margin-right: 1rem;
      align-self: center;
      max-height: 10rem;
    }

    .manual-image {
      align-self: center;

      img {
        max-height: 10rem;
      }
    }
  }
}

