.check-activity {
  width: 100%;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  background-color: $color_super-light-grey;
  border-radius: 2px;
  border-top-left-radius: 0.9rem;
  position: relative;

  .ca-header {
    display: flex;
    width: 100%;
    border-top-left-radius: 0.9rem;
    //background-color: #b6e2b6;
    background-color: $color_light-grey;

    .ca-header-icon {
      border-radius: 50%;
      //border: #b6e2b6 solid 3px;
      border: $color_light-grey solid 3px;
    }

    .ca-header-block {
      display: block;
      width: calc(100% - 3rem);

      .ca-header-loader {
        margin-left: 0.1rem;
        height: 4px;
        .form-attachment-loader {
          .ca-progress {
            @extend .progress;
            margin-bottom: 0;
          }
        }
      }

      .ca-header-data {
        display: flex;
        height: 2.1rem;
        align-items: center;
        padding-right: 0.5rem;

        .ca-header-name {
          display: flex;
          overflow: hidden;
          width: 100%;
          padding-left: 0.3rem;

          span {
            word-wrap: anywhere;
            font-size: 0.9rem;
            font-weight: bold;
            padding-right: 0.2rem;
          }
        }

        .ca-header-buttons {
          display: flex;
          margin-left: auto;
          margin-right: -0.3rem;

          .lumi-button-input {
            &:hover,
            &:active,
            &:focus {
              background-color: $color_super-light-grey !important;
            }
          }
        }
      }
    }
  }

  .ca-content {
    margin-left: 1rem;
    margin-right: 0.5rem;

    .ca-additional-info {
      margin-left: 5%;
      .key-value {
        font-size: x-small;
      }
    }

    .ca-input-row {
      margin-bottom: 2px;
      display: flex;
      font-size: small;
      &.upload {
        margin-bottom: 0px;
        margin-top: 0.25rem;
      }
      .ca-label-column {
        @extend .text-truncate;
        flex: 40%;
        padding-top: 0.5rem;
        &.upload {
          flex: 100%!important;
          align-self: center;
          padding-top: 0rem;
        }
      }

      .ca-value-column {
        flex: 60%;
        padding-left: 0.2rem;
        padding-right: 0.1rem;
        &.upload {
          flex: 0%!important;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .ca-icon-button {
    .lumi-button-input {
      &:hover,
      &:active,
      &:focus {
        background-color: $color_super-light-grey !important;
      }
    }
  }

  .ca-icon-button-flag-active {
    .lumi-button-input {
      background-color: orange !important;
      &:hover,
      &:active,
      &:focus {
        background-color: $color_super-light-grey !important;
      }
    }
  }

  .ca-icon-button-active {
    .lumi-button-input {
      background-color: $color_light-blue !important;
      &:hover,
      &:active,
      &:focus {
        background-color: $color_super-light-grey !important;
      }
    }
  }

  .ca-input-field {
    display: inline-flex;
    width: 100%;
    input {
      @extend input;
      background-color: white !important;
    }

    input.ng-invalid.ng-touched:not(:focus):not(.ng-pristine) {
      border-bottom: $color_error solid 2px;
    }
  }

  .ca-text-area {
    background-color: $color_super-light-grey;
    border-radius: 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    width: 100%;
    display: inline-flex;
    margin-bottom: 0.2rem;

    textarea {
      @extend textarea;
      height: 5rem;
      resize: none;
      border: 1px solid $color_grey-input-border;
      background-color: white;
    }
  }
}
