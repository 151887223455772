.overflow-auto-y {
  overflow-y: auto;
}

.overflow-hidden-x {
  overflow-x: hidden;
  -ms-overflow-x: hidden;
}

.overflow-hidden-y {
  overflow-y: hidden;
  -ms-overflow-y: hidden;
}

.vertical-scroll-container
{
  overflow-x: hidden;
  overflow-y: auto;
}