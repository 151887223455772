$login-animation-time:0.3s;
$login-filler-height:20%;

.login-background {
  @extend .loading-page
}

.login-container-right{
  background:rgba(255,255,255,.7);
  height:100%;
}

.login-container, .login-container-inner {
  height:100%;
}

.login-form {
  @extend .loading-form;
  animation-name: expand;
  animation-duration: $login-animation-time;
  border-radius:0;
  box-shadow:none;
  margin:0 18rem;
  background:none;
}

.login-form form {
  animation-name: showitems;
  animation-duration: $login-animation-time;
}

.login-form .input-group {
  margin-bottom: 0.7rem;
}

.login-title {
  text-align: center;
  padding-bottom: 2rem;
  margin:0;
  color: $color_text_lightgray;
}

.login-input {
  //max-width: 15rem;
  margin-bottom: 0.7rem;
}

.login-btn-container{
  margin: auto;
  width: 15rem;
  margin-top: 2rem;
}

.login-alert{
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.login-filler{
  height:$login-filler-height;
  animation-name: move;
  animation-duration: $login-animation-time;
}

.login-pass-forget{
  margin-top: 2rem;
  font-size: small;
  text-align: right;
  font-weight: 200;
  /*cursor: pointer;*/
/*  display: inline-block;
  float: right;*/
}

@keyframes expand {
  0%   {max-height: 2rem}
  100% {max-height: 40rem}
}

@keyframes move {
  0%   {height: $loading-filler-height}
  100% {height: $login-filler-height}
}

@keyframes showitems {
  0%    { visibility: hidden }
  99%   { visibility: hidden }
  100%  { visibility: visible }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.login-container-inner, .login-logo, .login-header{
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.login-logo, .login-header{
  animation: fadeInAnimation ease 4s;
}
.login-logo{
  position:absolute;
  top:0;
  left:0;
  padding:15px;
}
  .login-logo img{
    max-width:200px;
  }
.login-header{
  position:absolute;
  top:0;
  right:0;
  padding:15px 10px;
  border-bottom-left-radius: 10px;
  z-index:1;
}
  .login-header .item{
    position: relative;
    display: inline-block;
    padding:0 10px;

    &:not(:first-child){
      border-left: 1px solid rgba(255,255,255,.1);
    }
  }
  .login-header .item.language img{
    width:30px;
    height:auto;
  }
.login-header .dropdown-menu{
  margin-top:10px;
  margin-right:10px;
  padding:10px;
  border-radius:5px;
  font-size:0.875rem;
}
.login-header .dropdown-menu a{
  padding:5px 0;
}
.login-notify-outer{
  width:100%;
  padding:0 10px;
}
.login-notify{
  max-width:30rem;
  margin:0 auto;
}
  .login-notify .carousel-controls{
    margin-top:90px;
    position: absolute;
    width:100%;
    z-index:9;
  }
    .login-notify .carousel-controls a{
      position:absolute;
      opacity:0.5;

      &:hover{
        opacity:1;
      }
    }
      .login-notify .carousel-controls a.carousel-control-prev-custom{
        left:10px;
      }
      .login-notify .carousel-controls a.carousel-control-next-custom{
        right:10px;
      }
  .login-notify .carousel-indicators{
    top:0;
    padding-top:10px;
    margin-bottom:30px;
  }
  .login-notify .carousel-item{
    width:100%;
  }
    .login-notify .carousel-item.active{
      display:inline-block;
    }
    .login-notify .badge-container .badge{
      position: absolute;
      z-index:1;
      font-size:85%;
      font-weight:normal;
    }
    .login-notify .card{
      padding:10px;
      border-radius:6px;
      background-color:rgba(255,255,255,1);
    }
    .login-notify .card .card-image{
      text-align:center;
      height:200px;
    }
      .login-notify .card .card-image img{
        max-height:100%;
        width:100%;
      }
    .login-notify .card .card-title, .login-notify .card .card-text{
      color:#4b5257;
    }
    .login-notify .card .card-text{
      height:300px;
      overflow-y:auto;
    }
    .login-notify .card .card-title{
      margin-top:5px;
    }
      .login-notify .card .card-footer{
        color:#464a4c;
        font-size:1rem;
        padding-top:5px;
      }
.login-footer{
  position:absolute;
  right:0;
  bottom:0;
  padding:10px;
  font-size:12px;
  color:#464a4c;
}

@include media-breakpoint-only(xl){
  .login-form {
    margin:0 10rem;
  }
}
@include media-breakpoint-only(lg){
  .login-form {
    margin:0 8rem;
  }
}
@include media-breakpoint-only(md){
  .login-form {
    margin:0 4rem;
  }
}
@include media-breakpoint-down(sm){
  .login-container-inner{
    display:block !important;
    padding-top:20%;
  }
  .login-container-right{
    height:auto;
  }
  .login-form {
    margin:0 auto;
    border-radius:3px;
  }
  .login-notify .card .card-image{
    height:100px;
  }
    .login-notify .card .card-image img{
      width:auto;
    }
  .login-notify .card .card-text{
    height:120px;
  }
  .login-footer{
    position:absolute;
    right:0;
    bottom:0;
    background:#FFF;
    padding:10px;
    font-size:12px;
    color:#464a4c;

    &:after{
      top: 0;
      left:0;
      position: absolute;
      content: '';
      height:100%;
      width:10px;
      transform: skewX(-14deg);
      transform-origin: right top;
      background: inherit;
    }
  }
  @media (max-height:750px) {
    .login-title{
      display:none !important;
    }
  }
  @media (max-height:707px) {
    .login-footer{
      display:none !important;
    }
  }
}
@media (max-height:850px) {
  .login-notify .card .card-image{
    display:none !important;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237b7b7b' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237b7b7b' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  }
  .login-notify .carousel-controls{
    margin-left:10px;
    bottom:2px;
  }
  .login-notify .carousel-controls a{
    position:relative;
    left:0 !important;
    right:0 !important;
  }
  .login-notify .carousel-controls a span{
    width:20px;
    height:20px;
  }
  .login-notify .carousel-controls a.carousel-control-prev-custom{
    margin-right:10px;
  }
}

//Fix so Chrome does not show background color when autofill since setting a transparent color is not allowed
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s;
}




/*Xmas mode*/
.merry-xmas-card{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:999;
  background-color:rgba(0,0,0,.6);
  img{
    border-radius:10px;
    max-width:80%;
    max-height:80%;
  }
}
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000;
}

.snowflake,.snowflake .inner{animation-iteration-count:infinite;animation-play-state:running}
@keyframes snowflakes-fall{0%{transform:translateY(0)}100%{transform:translateY(110vh)}}
@keyframes snowflakes-shake{0%,100%{transform:translateX(0)}50%{transform:translateX(80px)}}
.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;user-select:none;cursor:default;animation-name:snowflakes-shake;animation-duration:3s;animation-timing-function:ease-in-out}
.snowflake .inner{animation-duration:5s;animation-name:snowflakes-fall;animation-timing-function:linear}


@for $i from 0 to 500 {
  .snowflake:nth-of-type(#{$i}) {
    left: percentage(random(100) / 100);
    animation-delay: random(30) / 10 + s;
    .inner{
      animation-delay: random(70) / 10 + s;
    }
  }
}
