.control-group-container {
  width: 100%;
  padding: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 0;
  margin-top: 0.5rem;

  .control-group {
    display: flex;
    height: 2.5rem;

    .cg-icon {
      flex: 5%;
      margin: auto;
    }

    .cg-name {
      @extend .text-truncate;
      cursor: pointer;
      flex: 65%;
      font-size: large;
      margin: auto;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }

    .cg-button-container {
      flex: 30%;
      display: contents;

      .cg-expand-more {
        cursor: pointer;
        margin: auto;
        margin-right: 1rem;
        margin-left: 0.5rem;
        padding: 0;
        height: 1.6rem;
        width: 1.6rem;
        min-width: 1.6rem;
        display: inline-flex !important;
        position: relative;

        i {
          @extend .material-icons, .md-dark;
          margin: auto;
          padding: 0;
          font-size: x-large;
          &:after {
            content: "expand_more";
          }
        }
      }

      .cg-expand-less {
        @extend .cg-expand-more;
        i {
          &:after {
            content: "expand_less";
          }
        }
      }

      .cg-switch {
        cursor: pointer;
        margin: auto;
        margin-right: 0;
        width: 64px;
        min-width: 64px;
        height: 32px;
        border: 4px solid $color_lighter-grey;
        border-radius: 32px;
        background-color: $color_lighter-grey;
        position: relative;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

        &:hover {
          box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 20px 0 rgb(0 0 0 / 12%);
          transition: box-shadow 0.12s linear;
        }

        input {
          display: none;
        }

        .cg-switch-slider {
          position: absolute;
          cursor: pointer;
          height: 24px;
          width: 24px;
          min-width: 24px;
          min-height: 24px;
          margin: auto;
          border-radius: 50%;
          background-color: $color_grey;
          -webkit-transition: .4s;
          transition: .4s;
        }
      }

      .cg-switch-active {
        @extend .cg-switch;
        background: lightgreen;
        -webkit-transition: 0.4s;
        transition-delay: .3s;

        .cg-switch-slider {
          -webkit-transform: translateX(32px);
          -ms-transform: translateX(32px);
          transform: translateX(32px);
        }
      }

      .cg-switch-disabled {
        @extend .cg-switch;
        pointer-events: none;
      }

      .cg-switch-disabled-active {
        @extend .cg-switch-active;
        pointer-events: none;
      }
    }
  }
  .control-group-info {
    position: relative;
    margin-top: 0.1rem;
    font-size: small;
    padding: 0.5rem;
    margin-left: 1rem;
  }
}
