.navbar {
    font-weight: 300;
    form {
        input {
            margin: 0;
            height: 1rem;
            margin-right: 5px;
            margin-left: 8px;
            margin-bottom: 1px;
        }
    }
    .navbar-brand {
        align-self: flex-start;
        overflow: visible;
    }
    .breadcrumb {
        margin: 0;
        background-color: inherit;
        font-weight: 300;
    }
    &.navbar-dark {
        .navbar-brand {
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
            cursor: pointer; 
        } 
        .breadcrumb, 
        .navbar-nav .nav-item {
            a {
                color: #fff;
                transition: .35s;
                &:hover {
                    color: rgba(255, 255, 255, .75);
                }
            }
            &.active {
                //background-color: rgba(255, 255, 255, 0.1);   // Origineel
                background-color: rgba(255, 255, 255, 0.15);  // Extra white
                //background-color: rgba(139, 190, 239, 0.3);     // #8bbeef 30%
                border-radius: 2px;
            }
            .dropdown-menu a {
                color: #212121;
                &:hover,
                &:focus,
                &:active {
                    color: #212121;
                }
            }
        }
        .nav-link {
            color: #fff;
        }
        .navbar-toggler {
            color: #fff;
        }
    }
    &.navbar-light {
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
        .breadcrumb,
        .nav-item {
            a {
                color: #212121;
                transition: .35s;
                &:hover {
                    transition: .35s;
                    color: #212121;
                }
            }
            &.active {
                background-color: rgba(158, 158, 158, 0.2);
            }
            .dropdown-menu a {
                color: #000;
                padding: 1rem;
            }
        }
        .navbar-toggler {
            color: #000;
        }
    }
    .dropdown-menu {
        a {
            font-size: 0.9375rem;
            font-weight: 300;
            padding: 10px;
        }
    }
    .navbar-toggler {
        border-width: 0;
    }
    .nav-flex-icons {
        flex-direction: row;
    }
    .container {
        @media (max-width: 991px) {
            width: 100%;
            .navbar-toggler-right {
                right: 0;
            }
        }
    }
    .dropdown-menu {
        position: absolute !important;
    }
    &.double-nav {
        flex-direction: row;
    }
}

// Input line color
.navbar-dark form {
    input[type=text] {
        border-bottom: 1px solid #fff;
    }
    .form-control {
        color: #fff;
        @include placeholder {
            color: rgba(255, 255, 255, .65) !important;
            font-weight: 300;
        }
    }
}

.navbar-light form {
    // Style Placeholders
    input[type=text] {
        border-bottom: 1px solid #1C2331;
    }
    .form-control {
        color: #1C2331;
        @include placeholder {
            color: #1C2331!important;
            font-weight: 300;
        }
    }
}
