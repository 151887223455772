.form-timeline {
  height: 280px;
  overflow: scroll;
}

.timeline {
  //margin-bottom: 2rem;
}

.timeline-date {
  width: 4rem;
  min-width: 4rem;
  font-size: 10px;
}

.timeline-status {
  font-size: 12px;
}

.timeline-icon-container {
  width: 2rem;
  min-width: 2rem;
  margin-right: 1rem;
  .timeline-icon {
    margin-left: auto;
    margin-right: auto;
    height: 2rem;
    width: 2rem;
    padding: 2px;
    border: #dddddd solid 4px;
    border-radius: 50%;
  }
}

.timeline-timediff {
  margin-left: 7rem;
  margin-top: 1.7rem;
  margin-bottom: -2.8rem;
  font-size: 10px;
  font-style: italic;
}

.timeline-bar {
  width: 5px;
  //height: 100%;
  border: 0;
  border-top: 3rem solid #dddddd;
  border-radius: 1rem;
  margin: 0.5rem 0;
  margin-left: 4.85rem;
}
